/*
 * Renders the admin view to control users
 */
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  isMobile,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { FiMail } from 'react-icons/fi';
import AdminEditUserModal from '../components/AdminEditUserModal';
import DeletePopover from '../components/DeletePopover';
import ErrorStat from '../components/ErrorStat';
import IsUserVerified from '../components/IsUserVerified';
import IsOptionChecked from '../components/IsOptionChecked';
import PageHeader from '../components/PageHeader';
import GlobalFilteringTables from '../components/inputs/GlobalFilteringTables';
import {
  fetchAllCompanies,
  selectAdminCompanyError,
  selectAdminCompanyStatus,
  selectAllCompanies,
} from '../slices/adminCompanySlice';
import {
  deleteUser,
  fetchAllUsers,
  selectAdminUserError,
  selectAdminUserStatus,
  selectAllUsers,
} from '../slices/adminUserSlice';
import { selectUserId } from '../slices/userSlice';
import API from '../utils/api';

const AdminUserOverview = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const allUsers = useSelector(selectAllUsers);
  const allCompanies = useSelector(selectAllCompanies);
  const loggedInUserId = useSelector(selectUserId);
  const allUsersError = useSelector(selectAdminUserError);
  const allUsersStatus = useSelector(selectAdminUserStatus);
  const allCompaniesError = useSelector(selectAdminCompanyError);
  const allCompaniesStatus = useSelector(selectAdminCompanyStatus);

  const [sendMailLoading, setSendMailLoading] = useState(false);

  const toast = useToast();

  /**
   * Fetches data
   */
  const fetchData = () => {
    dispatch(fetchAllCompanies())
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchAllUsers());
      })
      .catch(() => {
        toast({
          title: t('errors.adminUserOverview.fetchDataFailed.title'),
          description: t('errors.adminUserOverview.fetchDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = React.useMemo(() => allUsers);

  /**
   * Deletes a selected user
   */
  const deleteUserCheck = (user) => {
    // Checks if the logged in user tries to delete himself
    if (user.id === loggedInUserId) {
      toast({
        title: t('errors.adminUserOverview.deleteYourselfNotPossible.title'),
        description: t('errors.adminUserOverview.deleteYourselfNotPossible.description'),
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
    } else {
      dispatch(deleteUser(user))
        .then(unwrapResult)
        .then(() => {
          toast({
            title: t('adminUserOverview.successDeleteUser.title'),
            description: t('adminUserOverview.successDeleteUser.description'),
            status: 'success',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
          dispatch(fetchAllUsers());
        })
        .catch(() => {
          toast({
            title: t('errors.adminUserOverview.deleteUserFailed.title'),
            description: t('errors.adminUserOverview.deleteUserFailed.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        });
    }
  };

  /**
   * Displays the verification status of an user:
   * @returns Icon for the corresponding of an user
   */
  const userVerification = (user) => {
    return <IsUserVerified user={user} />;
  };

  const checkedOption = (option) => {
    return <IsOptionChecked checkedOption={option} />;
  };

  const resendVerification = async (userId) => {
    setSendMailLoading(true);
    API.put('api/resend-verification', { id: userId }, { withCredentials: true })
      .then(() => {
        setSendMailLoading(false);
        toast({
          title: t('adminUserOverview.resendVerificationMailSuccess.title'),
          description: t('adminUserOverview.resendVerificationMailSuccess.description'),
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch(() => {
        setSendMailLoading(false);
        toast({
          title: t('adminUserOverview.resendVerificationMailError.title'),
          description: t('adminUserOverview.resendVerificationMailError.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Displays icons for editing or deleting an user
   * @returns Icon for editing or deleting an user
   */
  const userActionList = (user) => {
    return (
      <HStack spacing="1">
        <AdminEditUserModal user={user} />
        <DeletePopover
          headerText={t('adminUserOverview.deletePopver.headerText')}
          confirmationText={t('adminUserOverview.deletePopver.confirmationText')}
          confirmationFunction={() => deleteUserCheck(user)}
        />
        {user.isEmailVerified === false ? (
          <IconButton
            color="horvath.black"
            icon={sendMailLoading ? <Spinner fontSize="1.25rem" /> : <FiMail fontSize="1.25rem" />}
            variant="ghost"
            aria-label="Delete member"
            onClick={() => resendVerification(user.id)}
          />
        ) : null}
      </HStack>
    );
  };

  /**
   * Gets the name of an company by the corresponding companyId
   * @returns Name of a company
   */
  const getCompanyNameById = (companyId) => {
    const foundCompany = allCompanies.find((company) => company.id === companyId);
    if (typeof foundCompany === 'undefined') {
      return '-';
    }
    return foundCompany.name;
  };

  /**
   * Sorts rows by verification icons of the users
   */
  const compareIsUserVerified = (rowA, rowB, id) => {
    const valueCellRowA = rowA.values[id].props.user.isEmailVerified;
    const valueCellRowB = rowB.values[id].props.user.isEmailVerified;
    if (valueCellRowA === true && valueCellRowB === false) return 1;
    if (valueCellRowA === false && valueCellRowB === true) return -1;
    return 0;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('adminUserOverview.tableHeader.firstName'),
        accessor: 'firstName',
      },
      {
        Header: t('adminUserOverview.tableHeader.lastName'),
        accessor: 'lastName',
      },
      {
        Header: t('adminUserOverview.tableHeader.role'),
        accessor: 'role',
      },
      {
        Header: t('adminUserOverview.tableHeader.email'),
        accessor: 'email',
      },
      {
        Header: t('adminUserOverview.tableHeader.company'),
        accessor: 'companyId',
        Cell: ({ value }) => {
          const id = parseInt(value, 10);
          const companyName = getCompanyNameById(id);
          return companyName;
        },
      },
      {
        Header: t('adminUserOverview.tableHeader.department'),
        accessor: 'department',
      },
      {
        Header: t('adminUserOverview.tableHeader.verified'),
        accessor: (user) => userVerification(user),
        sortType: compareIsUserVerified,
      },
      {
        Header: t('adminUserOverview.tableHeader.lastLogin'),
        accessor: 'lastLoginDate',
      },
      {
        Header: t('adminUserOverview.tableHeader.subscriptionType'),
        accessor: 'subscriptionType',
      },
      {
        Header: ' ',
        accessor: (user) => userActionList(user),
        disableSortBy: true,
      },
      {
        Header: t('adminUserOverview.tableHeader.extendedCompanyName'),
        accessor: 'extendedCompanyName',
      },
      {
        Header: t('adminUserOverview.tableHeader.companyAddress'),
        accessor: 'companyAddress',
      },
      {
        Header: t('adminUserOverview.tableHeader.zipCode'),
        accessor: 'zipCode',
      },
      {
        Header: t('adminUserOverview.tableHeader.city'),
        accessor: 'city',
      },
      {
        Header: t('adminUserOverview.tableHeader.country'),
        accessor: 'country',
      },
      {
        Header: t('adminUserOverview.tableHeader.internalPurchaseOrder'),
        accessor: 'internalPurchaseOrder',
      },
      {
        Header: t('adminUserOverview.tableHeader.allowExclusiveContent'),
        accessor: (user) => checkedOption(user.allowExclusiveContent),
      },
      {
        Header: t('adminUserOverview.tableHeader.allowClevelContent'),
        accessor: (user) => checkedOption(user.allowClevelContent),
      },
      {
        Header: t('adminUserOverview.tableHeader.allowNews'),
        accessor: (user) => checkedOption(user.allowNews),
      },
    ],
    [allCompanies, allUsers, i18n.language],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
        pageIndex: 0,
        sortBy: [
          {
            id: 'lastName',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  /**
   * Renders the user table
   */
  const renderUserTable = () => {
    return (
      <>
        <Box width="30%">
          <GlobalFilteringTables
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholderInputSearch={t('adminUserOverview.searchBar.placeholder')}
          />
        </Box>
        <Box spacing={1} mt={2} pt={3}>
          <Table {...getTableProps()} variant="striped" size="sm">
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                      <chakra.span pl="4">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Box
          px={{
            base: '4',
            md: '6',
          }}
          pb="5"
        >
          <HStack spacing="3" justify="space-between">
            {!isMobile && (
              <Text color="muted" fontSize="sm">
                {t('global.page')} {pageIndex + 1} {t('global.of')} {pageOptions.length}
              </Text>
            )}
            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{
                base: 'full',
                md: 'auto',
              }}
              variant="secondary"
            >
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {t('global.previousPageButton')}
              </Button>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                {t('global.nextPageButton')}
              </Button>
            </ButtonGroup>
          </HStack>
        </Box>
      </>
    );
  };

  return (
    <>
      <PageHeader title={t('adminUserOverview.pageHeader')} />
      <br />
      {allUsersStatus === 'loading' ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}
      {allUsersStatus === 'failed' ? (
        <ErrorStat
          errorMessage={allUsersError}
          onRefresh={fetchData}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      ) : null}
      {allCompaniesStatus === 'failed' ? (
        <ErrorStat
          errorMessage={allCompaniesError}
          onRefresh={fetchData}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      ) : null}
      {allUsersStatus === 'succeeded' ? renderUserTable() : null}
    </>
  );
};

export default AdminUserOverview;
