/*
 * Renders the dialog component
 */
import {
  Box,
  Center,
  Grid,
  GridItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './inputs/Button';

/**
 * Displays a modal with specified react components as children
 * @returns A model with specifiable title, body and secondary action
 */
const Dialog = (props) => {
  const {
    title,
    secondaryActionName,
    secondaryAction,
    isOpen,
    onClose,
    type,
    secondaryActionDisabled,
    popoverSaveButtonOnClose,
    popoverSaveButtonIsOpen,
    popoverSaveButtonCloseOnBlur,
    popoverSaveButtonReturnFocusOnClose,
    popoverSaveButtonContent,
    children,
    isPromise,
    isPromiseResolved,
    size = 'md',
  } = props;
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent sx={{ borderRadius: '0px' }}>
        <ModalHeader
          color={type === 'warning' ? 'white' : null}
          bg={type === 'warning' ? 'horvath.red' : null}
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color={type === 'warning' ? 'white' : null} />
        {children}
        <ModalFooter>
          {secondaryActionName ? (
            <Center>
              <Grid templateColumns="repeat(10, 1fr)">
                <GridItem colStart={2}>
                  <Button
                    text={t('global.closeButton')}
                    iconType="close"
                    action={onClose}
                    style={{ marginRight: '5px' }}
                  />
                </GridItem>
                <GridItem colStart={10}>
                  <Popover
                    returnFocusOnClose={popoverSaveButtonReturnFocusOnClose}
                    isOpen={
                      typeof popoverSaveButtonIsOpen === 'boolean' ? popoverSaveButtonIsOpen : false
                    }
                    onClose={popoverSaveButtonOnClose}
                    placement="top"
                    closeOnBlur={popoverSaveButtonCloseOnBlur}
                  >
                    <PopoverTrigger>
                      <Box role="button">
                        <Button
                          text={secondaryActionName}
                          color="primary"
                          action={secondaryAction}
                          disabled={secondaryActionDisabled}
                          isLoading={isPromise && !isPromiseResolved}
                        />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent>{popoverSaveButtonContent}</PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>
            </Center>
          ) : (
            <Button
              text={t('global.closeButton')}
              iconType="close"
              action={onClose}
              style={{ marginRight: '5px' }}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Dialog;
