/*
 * Renders the button component
 */
import * as React from 'react';
import '../../../css/button.scss';
import { Spinner } from '@chakra-ui/react';

/**
 * Displays a button in horváth ci with an icon
 * @returns A button with an icon
 */
const Button = (props) => {
  const { text, color, action, iconType, style, testId, disabled, isLoading } = props;
  const renderIcon = () => {
    switch (iconType) {
      case 'edit': {
        return (
          <svg
            height="16"
            viewBox="0 -1 401.52289 401"
            width="27"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" />
            <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" />
          </svg>
        );
      }
      case 'close': {
        return (
          <svg
            id="Capa_1"
            enableBackground="new 0 0 320.591 320.591"
            height="16"
            viewBox="0 0 320.591 320.591"
            width="27"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <g id="close_1_">
                <path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" />
                <path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" />
              </g>
            </g>
          </svg>
        );
      }
      default: {
        return (
          <svg height="16" viewBox="0 0 27 16" width="27" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m57.9863666 25.7049089h-22.9863666v-1.7826367h22.6135477l-4.4012433-4.4012433v-2.5210289l8 8-8 8v-2.5210289z"
              fill="#000"
              transform="translate(-35 -17)"
            />
          </svg>
        );
      }
    }
  };
  return color === 'primary' ? (
    <button
      type="button"
      className={
        disabled
          ? 'component-button component-button--primary disabled'
          : 'component-button component-button--primary color-blue-dark'
      }
      onClick={action}
      style={style}
      disabled={disabled}
      data-testid={testId}
    >
      <div className="icon">{isLoading ? <Spinner size="md" color="white" /> : renderIcon()}</div>
      <div className="input">{text}</div>
    </button>
  ) : (
    <button
      type="button"
      className={
        disabled
          ? 'component-button component-button--secondary disabled'
          : 'component-button component-button--secondary {{modifier-component}}'
      }
      onClick={action}
      style={style}
      disabled={disabled}
      data-testid={testId}
    >
      <div className="icon">{isLoading ? <Spinner size="md" /> : renderIcon()}</div>
      <div className="input">{text}</div>
    </button>
  );
};

export default Button;
