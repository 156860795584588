/**
 * This component renders the log in view containing two input fields which
 * require the email address and the password of the user
 */
import {
  Box,
  Center,
  Circle,
  Checkbox,
  Container,
  Divider,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  HStack,
  useDisclosure,
  useToast,
  Heading,
  Link,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';
import ErrorModal from '../components/ErrorModal';
import InfoModal from '../components/InfoModal';
import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import api from '../utils/api';
import validateEmail from '../utils/validation';

import Step from '../components/stepper/Step';
import useStep from '../components/stepper/useStep';
import UserSubscriptionSelection from '../components/UserSubscriptionSelection';
import getCountrySelectOptions from '../translations/countries';

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();

  const [salutation, setSalutation] = React.useState(null);
  const [salutationError, setSalutationError] = React.useState(false);
  const [academicTitle, setAcademicTitle] = React.useState(null);
  const [firstName, setFirstName] = React.useState('');
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [lastNameError, setLastNameError] = React.useState(false);
  const [position, setPosition] = React.useState('');
  const [department, setDepartment] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [repeatEmail, setRepeatEmail] = React.useState('');
  const [repeatEmailError, setRepeatEmailError] = React.useState(false);
  const [extendedCompanyNameError, setExtendedCompanyNameError] = React.useState(false);
  const [companyAddressError, setCompanyAddressError] = React.useState(false);
  const [zipCodeError, setZipCodeError] = React.useState(false);
  const [cityError, setCityError] = React.useState(false);
  const [countryError, setCountryError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [repeatPasswordError, setRepeatPasswordError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [subscriptionType, setSubscriptionType] = React.useState('FREE');
  const [extendedCompanyName, setExtendedCompanyName] = React.useState('');
  const [companyAddress, setCompanyAddress] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState(null);
  const [doesInternalPurchaseOrderExist, setDoesInternalPurchaseOrderExist] = React.useState('NO');
  const [doesInternalPurchaseOrderExistError, setDoesInternalPurchaseOrderExistError] =
    React.useState(false);
  const [internalPurchaseOrder, setInternalPurchaseOrder] = React.useState('');
  const [internalPurchaseOrderError, setInternalPurchaseOrderError] = React.useState(false);
  const [dataProtection, setDataprotection] = React.useState(false);
  const [allowExclusiveContent, setAllowExclusiveContent] = React.useState(false);
  const [allowClevelContent, setAllowClevelContent] = React.useState(false);
  const [allowNews, setAllowNews] = React.useState(false);

  // const [generalError, setGeneralError] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const [registerCallFinished, setRegisterCallFinished] = React.useState(false);

  const salutationOptions = [
    {
      name: t('global.appelationOptions.mr'),
      value: 'HERR',
    },
    {
      name: t('global.appelationOptions.mrs'),
      value: 'FRAU',
    },
  ];

  const internalPurchaseOptions = [
    {
      name: t('registration.payment.extendedBillingInformation.yes'),
      value: 'YES',
    },
    {
      name: t('registration.payment.extendedBillingInformation.no'),
      value: 'NO',
    },
  ];

  const countryOptions = getCountrySelectOptions(t);

  const academicTitleOptions = [
    {
      name: '-',
      value: '-',
    },
    {
      name: t('global.academicTitleOptions.bachelor'),
      value: 'BSC',
    },
    {
      name: t('global.academicTitleOptions.master'),
      value: 'MS',
    },
    {
      name: t('global.academicTitleOptions.diplom'),
      value: 'DIPLOM',
    },
    {
      name: t('global.academicTitleOptions.doctor'),
      value: 'DR',
    },
    {
      name: t('global.academicTitleOptions.professor'),
      value: 'PROF',
    },
  ];

  // States for opening the info modal
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();

  // States for opening the error modal
  const {
    isOpen: isErrorModalOpen,
    onOpen: onErrorModalOpen,
    onClose: onErrorModalClose,
  } = useDisclosure();

  /**
   * Closes info modal and stay at registration view
   */
  const handleErrorModalClose = () => {
    onErrorModalClose();
    history.push('/login');
  };

  /**
   * Closes info modal and reroutes the user to login area
   */
  const handleInfoModalClose = () => {
    onInfoModalClose();
    history.push('/login');
  };

  const [currentStep, { setStep }] = useStep({ maxStep: 3, initialStep: 0 });

  const verifyUserDataInput = () => {
    if (salutation === null) {
      setSalutationError(true);
      setErrorMsg(t('errors.registration.salutation'));
      return false;
    }
    setSalutationError(false);
    setErrorMsg('');

    if (firstName === '') {
      setFirstNameError(true);
      setErrorMsg(t('errors.registration.firstName'));
      return false;
    }
    setFirstNameError(false);
    setErrorMsg('');

    if (lastName === '') {
      setLastNameError(true);
      setErrorMsg(t('errors.registration.lastName'));
      return false;
    }
    setLastNameError(false);
    setErrorMsg('');

    if (!validateEmail(email)) {
      setEmailError(true);
      setErrorMsg(t('errors.registration.invalidEmail'));
      return false;
    }
    setEmailError(false);
    setErrorMsg('');

    if (email !== repeatEmail) {
      setRepeatEmailError(true);
      setErrorMsg(t('errors.registration.emailsNotMatch'));
      return false;
    }
    setRepeatPasswordError(false);
    setErrorMsg('');

    if (password.length < 8) {
      setPasswordError(true);
      setErrorMsg(t('errors.registration.invalidPassword'));
      return false;
    }
    setPasswordError(false);
    setErrorMsg('');

    if (password !== repeatPassword) {
      setRepeatPasswordError(true);
      setErrorMsg(t('errors.registration.passwordsNotMatch'));
      return false;
    }
    setRepeatPasswordError(false);
    setErrorMsg('');
    return true;
  };

  const verifyCompanyDataInput = () => {
    if (extendedCompanyName === '') {
      setExtendedCompanyNameError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setExtendedCompanyNameError(false);
    setErrorMsg('');

    if (companyAddress === '') {
      setCompanyAddressError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setCompanyAddressError(false);
    setErrorMsg('');

    if (zipCode === '') {
      setZipCodeError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setZipCodeError(false);
    setErrorMsg('');

    if (city === '') {
      setCityError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setCityError(false);
    setErrorMsg('');

    if (country === '') {
      setCountryError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setCountryError(false);
    setErrorMsg('');

    if (doesInternalPurchaseOrderExist === '') {
      setDoesInternalPurchaseOrderExistError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setDoesInternalPurchaseOrderExistError(false);
    setErrorMsg('');

    if (
      doesInternalPurchaseOrderExist === 'YES' &&
      (internalPurchaseOrder === null || internalPurchaseOrder === '')
    ) {
      setInternalPurchaseOrderError(true);
      setErrorMsg(t('errors.registration.fieldCanNotBeEmpty'));
      return false;
    }
    setInternalPurchaseOrderError(false);
    setErrorMsg('');

    return true;
  };

  /**
   * Tries to login the user with the given credentials
   */
  // eslint-disable-next-line no-unused-vars
  const register = () => {
    onInfoModalOpen();
    api
      .post(
        '/auth/register',
        {
          email,
          password,
          salutation: salutation.toUpperCase(),
          academicTitle: academicTitle === '-' ? null : academicTitle,
          firstName,
          lastName,
          position,
          department,
          subscriptionType,
          extendedCompanyName,
          companyAddress,
          zipCode,
          city,
          country,
          internalPurchaseOrder,
          allowExclusiveContent,
          allowClevelContent,
          allowNews,
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          onInfoModalClose();
          setStep(3);
        }
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 409') {
          toast({
            title: t('errors.registration.409.title'),
            description: '',
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        } else {
          onErrorModalOpen();
        }
        onInfoModalClose();
      });
  };

  const registerUserWithoutSubscription = () => {
    if (verifyUserDataInput()) {
      register();
    }
  };

  const renderUserDataInputForm = () => {
    return (
      <Stack marginTop={2} direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
        <Stack spacing={2} backgroundColor="white">
          <Center>
            <Stack w={[400, 400, 800]} spacing={2} px="1rem" boxShadow="md">
              <FormControl>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.salutation')}
                    formType="selectNameValue"
                    value={salutation}
                    onValueChange={setSalutation}
                    testName="Anrede"
                    options={salutationOptions}
                    invalid={salutationError && salutation === null}
                  />
                  <FormProperty
                    label={t('registration.academicTitle')}
                    formType="selectNameValue"
                    value={academicTitle}
                    onValueChange={setAcademicTitle}
                    testName="Akad. Titel"
                    options={academicTitleOptions}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.firstName')}
                    formType="input"
                    value={firstName}
                    onValueChange={setFirstName}
                    required
                    testName="Vorname"
                    invalid={firstNameError && firstName === ''}
                  />
                  <FormProperty
                    label={t('registration.lastName')}
                    formType="input"
                    value={lastName}
                    onValueChange={setLastName}
                    testName="Nachname"
                    invalid={lastNameError && lastName === ''}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.position')}
                    formType="input"
                    value={position}
                    onValueChange={setPosition}
                    testName="Position"
                  />
                  <FormProperty
                    label={t('registration.department')}
                    formType="input"
                    value={department}
                    onValueChange={setDepartment}
                    testName="Abteilung"
                  />
                </SimpleGrid>
                <Divider />
                <Box>
                  <Text fontSize="md" padding={4} pl={7} pr={7} pb={0}>
                    {t('registration.headingRegisterWithEmail')}
                  </Text>
                </Box>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.email')}
                    formType="input"
                    value={email}
                    onValueChange={setEmail}
                    testName="Email"
                    invalid={emailError && !validateEmail(email)}
                  />
                  <FormProperty
                    label={t('registration.repeatEmail')}
                    formType="input"
                    value={repeatEmail}
                    onValueChange={setRepeatEmail}
                    testName="Email wiederholen"
                    invalid={repeatEmailError && email !== repeatEmail}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.password')}
                    formType="password-input"
                    value={password}
                    onValueChange={setPassword}
                    testName="Passwort"
                    invalid={passwordError && password.length < 8}
                  />
                  <FormProperty
                    label={t('registration.repeatPassword')}
                    formType="password-input"
                    value={repeatPassword}
                    onValueChange={setRepeatPassword}
                    testName="Passwort wiederholen"
                    invalid={repeatPasswordError && password !== repeatPassword}
                  />
                </SimpleGrid>
              </FormControl>
              <Center color="red">{errorMsg}</Center>
              <SimpleGrid columns={1} spacing={4}>
                <Center>
                  <Box w="90%">
                    <Checkbox
                      isChecked={dataProtection}
                      defaultChecked={dataProtection}
                      onChange={(e) => setDataprotection(e.target.checked)}
                      alignItems="flexStart"
                    >
                      <Text lineHeight="1">
                        {`${t('registration.dataProtectionText')} `}
                        <Link
                          color="horvath.blue-light"
                          href="https://www.horvath-partners.com/de/datenschutz/"
                        >
                          {t('registration.dataProtection')}
                        </Link>
                      </Text>
                    </Checkbox>
                  </Box>
                </Center>

                <Button
                  text={t('registration.companyAlreadyRegistered')}
                  action={() => {
                    if (verifyUserDataInput()) {
                      registerUserWithoutSubscription();
                    }
                  }}
                  disabled={dataProtection === false}
                  testId="Registrierung Button"
                />
                <Button
                  text={t('registration.companyNotRegistered')}
                  color="primary"
                  action={() => {
                    if (verifyUserDataInput()) {
                      setStep(1);
                    }
                  }}
                  disabled={dataProtection === false}
                  testId="Registrierung Button"
                />
              </SimpleGrid>
            </Stack>
          </Center>
        </Stack>
      </Stack>
    );
  };

  const renderUserSubscriptionSelection = () => {
    return (
      <UserSubscriptionSelection
        subscriptionType={subscriptionType}
        setSubscriptionType={setSubscriptionType}
        submitButton={
          <Button
            text={t('registration.payment.paymentSubmission')}
            color="primary"
            action={() => {
              setStep(2);
            }}
            testId="Registrierung Button"
          />
        }
      />
    );
  };

  const renderPaymentInformation = () => {
    return (
      <Stack marginTop={8}>
        <Stack spacing={4} backgroundColor="white">
          <Center>
            <Stack w={[400, 400, 800]} spacing={3} p="1rem" boxShadow="md">
              <FormControl>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.payment.companyNameLaw')}
                    formType="input"
                    value={extendedCompanyName}
                    onValueChange={setExtendedCompanyName}
                    required
                    invalid={extendedCompanyNameError && extendedCompanyName === ''}
                  />
                  <FormProperty
                    label={t('registration.payment.streetNamePostbox')}
                    formType="input"
                    value={companyAddress}
                    onValueChange={setCompanyAddress}
                    invalid={companyAddressError && companyAddress === ''}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.payment.zipCode')}
                    formType="input"
                    value={zipCode}
                    onValueChange={setZipCode}
                    required
                    invalid={zipCodeError && (zipCode === '' || zipCode.length > 20)}
                  />
                  <FormProperty
                    label={t('registration.payment.city')}
                    formType="input"
                    value={city}
                    onValueChange={setCity}
                    invalid={cityError && city === ''}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px" width="50%">
                  <FormProperty
                    label={t('registration.payment.country')}
                    formType="selectNameValue"
                    value={country}
                    onValueChange={setCountry}
                    testName="Anrede"
                    options={countryOptions}
                    invalid={countryError && country === null}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px" width="50%">
                  <FormProperty
                    label={t('registration.payment.extendedBillingInformation.text')}
                    formType="selectNameValue"
                    value={doesInternalPurchaseOrderExist}
                    onValueChange={(value) => {
                      setDoesInternalPurchaseOrderExist(value);
                      setInternalPurchaseOrder(null);
                    }}
                    options={internalPurchaseOptions}
                    invalid={doesInternalPurchaseOrderExistError}
                  />
                </SimpleGrid>
                {doesInternalPurchaseOrderExist === 'YES' ? (
                  <SimpleGrid minChildWidth="200px" width="50%">
                    <FormProperty
                      label={t('registration.payment.internalPurchaseOrderText')}
                      formType="input"
                      value={internalPurchaseOrder}
                      onValueChange={setInternalPurchaseOrder}
                      invalid={internalPurchaseOrderError}
                    />
                  </SimpleGrid>
                ) : null}
                <Divider />
                <Box>
                  <Text fontSize="md" padding={4} pl={7} pr={7} pb={0}>
                    {t('registration.payment.offersText')}
                  </Text>
                  <Center>
                    <Box w="90%" mt={2}>
                      <Checkbox
                        isChecked={allowExclusiveContent}
                        defaultChecked={allowExclusiveContent}
                        onChange={(e) => setAllowExclusiveContent(e.target.checked)}
                        alignItems="flexStart"
                        sx={{ minWidth: '100%', marginBottom: '0.5rem' }}
                      >
                        {t('registration.payment.exclusiveContent')}
                      </Checkbox>
                      <Checkbox
                        isChecked={allowClevelContent}
                        defaultChecked={allowClevelContent}
                        onChange={(e) => setAllowClevelContent(e.target.checked)}
                        alignItems="flexStart"
                        sx={{ minWidth: '100%', marginBottom: '0.5rem' }}
                      >
                        {t('registration.payment.cLevelContent')}
                      </Checkbox>
                      <Checkbox
                        isChecked={allowNews}
                        defaultChecked={allowNews}
                        onChange={(e) => setAllowNews(e.target.checked)}
                        alignItems="flexStart"
                        sx={{ minWidth: '100%', marginBottom: '0.5rem' }}
                      >
                        {t('registration.payment.news')}
                      </Checkbox>
                    </Box>
                  </Center>
                </Box>
              </FormControl>
              <Center color="red">{errorMsg}</Center>
              <Center mt={2} mb={5}>
                <Button
                  text={
                    /* Renders the button to order for free or paid depending on the chosen subsciption type */
                    subscriptionType === 'FREE'
                      ? t('registration.subscriptionCards.orderButtonFree')
                      : t('registration.subscriptionCards.orderButtonPaid')
                  }
                  color="primary"
                  action={() => {
                    if (verifyCompanyDataInput()) {
                      register();
                      setStep(3);
                    }
                  }}
                />
              </Center>
            </Stack>
          </Center>
        </Stack>
      </Stack>
    );
  };

  const renderUserRegistrationSuccesss = () => {
    return (
      <Stack w="100%">
        <Center>
          <Box as="section">
            <Container py={{ base: '16', md: '24' }}>
              <Box
                bg="bg.surface"
                boxShadow="md"
                px={{ base: '6', md: '8' }}
                py="8"
                width="full"
                maxW="lg"
              >
                <Stack spacing={{ base: '10', md: '10' }} textAlign="center">
                  <Stack align="center">
                    <Circle size="20" bg="horvath.blue-dark" borderWidth="2px">
                      <Icon as={HiCheck} color="white" fontSize="50px" />
                    </Circle>
                    <Heading textStyle={{ base: 'lg', md: 'lg' }} mb="5" fontWeight="bold">
                      {t('registration.infoModal.title')}
                    </Heading>
                    <Text textStyle={{ base: 'lg', md: 'lg' }} mb="5">
                      {t('registration.infoModal.infoText1')}
                    </Text>
                    <Text textStyle={{ base: 'lg', md: 'lg' }} fontWeight="semibold">
                      {t('registration.infoModal.infoText2')}
                    </Text>
                  </Stack>
                  <Button
                    text={t('registration.infoModal.logIn')}
                    color="primary"
                    action={() => {
                      history.push('/login');
                    }}
                    testId="Registrierung Button"
                  />
                </Stack>
              </Box>
            </Container>
          </Box>
        </Center>
      </Stack>
    );
  };

  const steps = [
    {
      title: t('registration.personalInformation'),
      content: renderUserDataInputForm(),
    },
    {
      title: t('registration.subscription'),
      content: renderUserSubscriptionSelection(),
    },
    {
      title: t('registration.payment.paymentInformation'),
      content: renderPaymentInformation(),
    },
    {
      title: t('registration.success'),
      content: renderUserRegistrationSuccesss(),
    },
  ];

  return (
    <div>
      <HStack mt={12} spacing="0">
        {steps.map((step, id) => (
          <Step
            key={step.title}
            onClick={() => (currentStep > id ? setStep(id) : null)}
            title={step.title}
            isActive={currentStep === id}
            isCompleted={currentStep > id}
            isFirstStep={id === 0}
            isLastStep={steps.length === id + 1}
          />
        ))}
      </HStack>
      <Box>
        {currentStep === 0 ? renderUserDataInputForm() : null}
        {currentStep === 1 ? renderUserSubscriptionSelection() : null}
        {currentStep === 2 ? renderPaymentInformation() : null}
        {currentStep === 3 ? renderUserRegistrationSuccesss() : null}
      </Box>
      <InfoModal
        title={t('registration.infoModal.title')}
        infoText={t('registration.infoModal.infoText')}
        isOpen={isInfoModalOpen}
        onClose={handleInfoModalClose}
        isPromise
        onClosePromise={onInfoModalClose}
        promiseTitle={t('registration.infoModal.promiseTitle')}
        promiseText={t('registration.infoModal.promiseText')}
        isPromiseResolved={registerCallFinished}
      />
      <ErrorModal
        title={t('errors.registration.errorModal.title')}
        infoText={t('errors.registration.errorModal.infoText')}
        isOpen={isErrorModalOpen}
        onClose={handleErrorModalClose}
      />
    </div>
  );
};

export default Register;
