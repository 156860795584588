import * as React from 'react';
import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import StepCircle from './StepCircle';

const Step = (props) => {
  const { isActive, isCompleted, isLastStep, isFirstStep, title, content, ...stackProps } = props;

  return (
    <Box w="100%">
      <Stack spacing="4" direction="column" flex="1" {...stackProps}>
        <Stack spacing="0" align="center" direction={{ base: 'column', md: 'row' }}>
          <Divider
            borderWidth="1px"
            borderColor={
              // eslint-disable-next-line no-nested-ternary
              isFirstStep ? 'transparent' : isCompleted || isActive ? 'accent' : 'inherit'
            }
          />
          <StepCircle isActive={isActive} isCompleted={isCompleted} />
          <Divider
            borderWidth="1px"
            // eslint-disable-next-line no-nested-ternary
            borderColor={isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'}
          />
        </Stack>
        <Stack spacing="0.5" align={{ base: 'start', md: 'center' }}>
          <Text color="fg.emphasized" fontWeight="medium">
            {title}
          </Text>
        </Stack>
      </Stack>
      {/* {isActive ? (
        <Box w="80%" position="absolute" l="0">
          {content}
        </Box>
      ) : null} */}
    </Box>
  );
};

export default Step;
