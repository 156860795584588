/*
 * Renders modal component to change the role and company from a user
 */
import {
  Button,
  IconButton,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  PopoverHeader,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCompanies, selectAllCompanies } from '../slices/adminCompanySlice';
import { editRoleOfCertainUser, editUser, fetchAllUsers } from '../slices/adminUserSlice';
import { selectUser, selectUserId } from '../slices/userSlice';
import Dialog from './Dialog';
import ErrorModal from './ErrorModal';
import FormControl from './inputs/FormControl';
import FormProperty from './inputs/FormProperty';

const AdminEditUserModal = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [salutation, setSalutation] = useState(user.salutation);
  const [academicTitle, setAcademicTitle] = useState(user.academicTitle);
  const [position, setPosition] = useState(user.position);
  const [department, setDepartment] = useState(user.department);
  const [isEmailVerified, setIsEmailVerified] = useState(user.isEmailVerified);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [showAdminWarning, setShowAdminWarning] = useState(false);

  const loggedInUserId = useSelector(selectUserId);
  const loggedInUserRole = useSelector(selectUser).role;

  const allCompanies = useSelector(selectAllCompanies);
  const {
    isOpen: isAdminWarningOpen,
    onOpen: onAdminWarningOpen,
    onClose: onAdminWarningClose,
  } = useDisclosure();
  const {
    isOpen: isNotVerifiedWarningOpen,
    onOpen: onNotVerifiedWarningOpen,
    onClose: onNotVerifiedWarningClose,
  } = useDisclosure();

  const toast = useToast();

  // Temporary user to display the current values in input fields
  const tempUser = { ...user };

  useEffect(() => {
    setRole(tempUser.role);
    setCompanyId(tempUser.companyId);
    dispatch(fetchAllCompanies());
  }, [dispatch, user]);

  const roleOptions = [
    { name: t('global.roleOptions.admin'), value: 'ADMIN' },
    { name: t('global.roleOptions.user'), value: 'USER' },
    { name: t('global.roleOptions.contact'), value: 'CONTACT' },
    { name: t('global.roleOptions.inactive'), value: 'INACTIVE' },
  ];

  /**
   * Computes the names of all companies by companyId
   * @returns The names of all companies
   */
  const getAllCompanyNames = () => {
    const allCompanyNames = [];
    allCompanies.forEach((company) => {
      const companyNameValuePair = {};
      if (allCompanyNames.length < 1) {
        companyNameValuePair.name = company.name;
        companyNameValuePair.value = company.id;
        allCompanyNames.push(companyNameValuePair);
      } else {
        const result = allCompanyNames.find(({ value }) => value === company.id);
        if (result === undefined) {
          companyNameValuePair.name = company.name;
          companyNameValuePair.value = company.id;
          allCompanyNames.push(companyNameValuePair);
        }
      }
    });
    return allCompanyNames;
  };

  /**
   * Resets all the states of an user
   */
  const clearStates = () => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setSalutation(user.salutation);
    setAcademicTitle(user.academicTitle);
    setPosition(user.position);
    setDepartment(user.department);
    setIsEmailVerified(user.isEmailVerified);
    setEmail(user.email);
    setRole(user.role);
    setCompanyId(user.companyId);
  };

  useEffect(() => {
    clearStates();
  }, [user]);

  const closePopover = () => {
    clearStates();
    onAdminWarningClose();
  };

  /**
   * Saves the user data
   */
  const saveChangeUserData = () => {
    const userData = {
      id: user.id,
      firstName,
      lastName,
      salutation,
      academicTitle,
      position,
      department,
      email,
      companyId,
      role,
      isEmailVerified,
    };
    const userDataWithNewRole = {
      ...user,
      role,
    };

    // Checks if the user tries to revoke the admin role from himself
    if (
      loggedInUserId === userDataWithNewRole.id &&
      loggedInUserRole === 'ADMIN' &&
      userDataWithNewRole.role !== 'ADMIN'
    ) {
      toast({
        title: t('errors.adminEditUserModal.removeAdminRoleFailed.title'),
        description: t('errors.adminEditUserModal.removeAdminRoleFailed.description'),
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
    } else {
      dispatch(editUser(userData))
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchAllUsers())
            .then(unwrapResult)
            .then(() => {
              dispatch(editRoleOfCertainUser(userDataWithNewRole))
                .then(unwrapResult)
                .then(() => {
                  dispatch(fetchAllUsers())
                    .then(unwrapResult)
                    .then(() => {
                      closePopover();
                      toast({
                        title: t('adminEditUserModal.successSaveEditUserData.title'),
                        description: t('adminEditUserModal.successSaveEditUserData.description'),
                        status: 'success',
                        duration: 4000,
                        position: 'top-right',
                        isClosable: true,
                      });
                    });
                })
                .catch(() => {
                  toast({
                    title: t('errors.adminEditUserModal.editRoleFailed.title'),
                    description: t('errors.adminEditUserModal.editRoleFailed.description'),
                    status: 'error',
                    duration: 4000,
                    position: 'top-right',
                    isClosable: true,
                  });
                });
            });
        })
        .catch(() => {
          toast({
            title: t('errors.adminEditUserModal.saveEditUserDataFailed.title'),
            description: t('errors.adminEditUserModal.saveEditUserDataFailed.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        });
    }
  };

  /**
   * Checks if user role is admin or not and executes the corresponding functionality
   */
  const checkRoleAndSave = () => {
    if (!isEmailVerified) {
      onNotVerifiedWarningOpen();
      return;
    }
    if (role === 'ADMIN') {
      setShowAdminWarning(true);
    } else {
      saveChangeUserData();
    }
  };

  /**
   * Displays the Popover Content when chosing the admin role in the dropdown
   * @returns Displays Popover to the user
   */
  const userPopoverContent = () => {
    return (
      <>
        <PopoverHeader fontWeight="semibold">
          {t('adminEditUserModal.popover.header')}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Text color="horvath.red">{t('adminEditUserModal.popover.body')}</Text>
        </PopoverBody>
        <PopoverFooter>
          <Button
            colorScheme="blue"
            onClick={() => {
              saveChangeUserData();
              setShowAdminWarning(false);
            }}
          >
            {t('global.okButton')}
          </Button>
        </PopoverFooter>
      </>
    );
  };

  return (
    <>
      <IconButton
        color="horvath.black"
        icon={<FiEdit fontSize="1.25rem" />}
        variant="ghost"
        aria-label="Delete member"
        onClick={onAdminWarningOpen}
      />
      <Dialog
        title={t('adminEditUserModal.dialog.title')}
        secondaryActionName={t('adminEditUserModal.dialog.saveButton')}
        secondaryAction={checkRoleAndSave}
        isOpen={isAdminWarningOpen}
        onClose={closePopover}
        popoverSaveButtonOnClose={() => {
          setShowAdminWarning(false);
        }}
        popoverSaveButtonIsOpen={showAdminWarning}
        popoverSaveButtonCloseOnBlur
        popoverSaveButtonReturnFocusOnClose={false}
        popoverSaveButtonContent={userPopoverContent()}
      >
        <FormControl>
          <FormProperty
            label={t('adminEditUserModal.dialog.labelRole')}
            formType="selectNameValue"
            value={tempUser.role}
            onValueChange={setRole}
            colorEvenItems
            testName="Rolle"
            options={roleOptions}
          />
        </FormControl>
        <FormControl>
          <FormProperty
            label={t('adminEditUserModal.dialog.labelCompany')}
            formType="selectNameValue"
            value={tempUser.companyId}
            onValueChange={setCompanyId}
            colorEvenItems
            testName="Unternehmen"
            options={getAllCompanyNames()}
          />
        </FormControl>
      </Dialog>
      <ErrorModal
        isOpen={isNotVerifiedWarningOpen}
        onClose={onNotVerifiedWarningClose}
        title={t('errors.adminEditUserModal.errorModal.title')}
        type="error"
        infoText={t('errors.adminEditUserModal.errorModal.infoText')}
      />
    </>
  );
};

export default AdminEditUserModal;
