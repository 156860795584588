/*
 * Render Modal to create new Company
 */

import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlinePlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { createCompany, fetchAllCompanies } from '../slices/adminCompanySlice';
import { fetchIndustryLevels, selectAllIndustryLevels } from '../slices/industryLevelsSlice';
import Dialog from './Dialog';
import FormControl from './inputs/FormControl';
import FormProperty from './inputs/FormProperty';
import getCountrySelectOptions from '../translations/countries';

const borderStyleInput = {
  color: 'white',
  border: '1px',
  backgroundColor: '#1B3849',
  borderColor: 'blue.500',
  borderRadius: '7',
  borderWidth: '2px',
};

const CreateCustomerModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState('');
  const [companyNameAbbreviation, setCompanyNameAbbreviation] = useState('');
  const [industryLevel1, setIndustryLevel1] = useState('-');
  const [industryLevel2, setIndustryLevel2] = useState('-');
  const [industryLevel3, setIndustryLevel3] = useState('-');
  const [countryCode, setCountryCode] = useState('-');
  const [subscription, setSubscription] = useState('-');
  const industryLevels = useSelector(selectAllIndustryLevels);
  const subscriptionOptions = [
    { name: t('global.subscriptionOptions.free'), value: 'FREE' },
    { name: t('global.subscriptionOptions.smart'), value: 'SMART' },
    { name: t('global.subscriptionOptions.premium'), value: 'PREMIUM' },
  ];

  const countryCodeOptions = getCountrySelectOptions(t);

  // States for opening the user dialog to create a new company
  const {
    isOpen: isCreateCompanyOpen,
    onOpen: onOpenCreateCompany,
    onClose: onCloseCreateCompany,
  } = useDisclosure();

  /**
   * Callback function that dispatches an async thunk to retrieve industry level data
   */
  const getIndustryLevels = () => {
    // Only fetch industry levels if the status of the reducer is 'idle'

    dispatch(fetchIndustryLevels());
  };

  React.useEffect(() => getIndustryLevels(), []);

  /**
   * Gets the names of the industry level 1
   * @returns Array of the names of the industry level 1
   */
  const getIndustryLevel1NamesAndValues = () => {
    const namesAndValues = [];
    industryLevels.forEach((level) => {
      const element = {};
      element.name = level.level1[0].name;
      element.value = level.level1[0].value;
      namesAndValues.push(element);
    });
    return namesAndValues;
  };

  /**
   * Gets the names of the industry level 2 according to the selected industry level 1
   * @returns Array of the names of the industry level 2
   */
  const getIndustryLevel2NamesAndValues = () => {
    const namesAndValues = [];
    industryLevels
      .filter((level) => level.level1[0].value === industryLevel1)
      .forEach((level) => {
        level.level2.forEach((level2Item) => {
          const element = {};
          element.name = level2Item.name;
          element.value = level2Item.value;
          namesAndValues.push(element);
        });
      });
    return namesAndValues;
  };

  /**
   * Gets the names of the industry level 3 according to the selected industry level 1
   * @returns Array of the names of the industry level 3
   */
  const getIndustryLevel3NamesAndValues = () => {
    const namesAndValues = [];
    industryLevels
      .filter((level) => level.level1[0].value === industryLevel1)
      .forEach((level) => {
        level.level3.forEach((level3Item) => {
          const element = {};
          element.name = level3Item.name;
          element.value = level3Item.value;
          namesAndValues.push(element);
        });
      });
    return namesAndValues;
  };

  /*
   * Reset states with company data to prevent adding redundant companies
   */
  const resetStatesOfCompanyData = () => {
    setCompanyName('');
    setCompanyNameAbbreviation('');
    setIndustryLevel1('-');
    setIndustryLevel2('-');
    setIndustryLevel3('-');
    setCountryCode('-');
    setSubscription('-');
  };

  /**
   * Checks the necessary company inputs and returns true if everything check out
   * @returns true if the inputs are valid, false otherwise
   */
  const checkCompanyInputs = () => {
    if (
      companyName !== '' &&
      companyNameAbbreviation !== '' &&
      industryLevel1 !== '-' &&
      industryLevel2 !== '-' &&
      industryLevel3 !== '-' &&
      countryCode !== '-' &&
      subscription !== '-'
    ) {
      return true;
    }
    return false;
  };

  /**
   * Updates the indsutry level 1 state and sets the states of
   * industry level 2 and insdustry level 3 to "-"
   * @param {*} value The new value of the state
   */
  const updateIndustryLevel1 = (value) => {
    setIndustryLevel1(value);
    setIndustryLevel2('-');
    setIndustryLevel3('-');
  };

  /**
   * Updates the indsutry level 2 state and sets the state of
   * insdustry level 3 to "-"
   * @param {*} value The new value of the state
   */
  const updateIndustryLevel2 = (value) => {
    setIndustryLevel2(value);
    setIndustryLevel3('-');
  };

  /**
   * Saves the company created by the user input
   */
  const saveCreateCompanyData = () => {
    if (checkCompanyInputs()) {
      const company = {
        shortName: companyNameAbbreviation,
        name: companyName,
        industryLevel1,
        industryLevel2,
        industryLevel3,
        countryCode,
        subscription,
      };
      dispatch(createCompany(company))
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchAllCompanies());
          resetStatesOfCompanyData();
        });
      onCloseCreateCompany();
    }
  };

  return (
    <>
      <Box minWidth="40px">
        <Button _hover={{ bg: '#1B3849' }} sx={borderStyleInput} onClick={onOpenCreateCompany}>
          <Box pr="10px">
            <HiOutlinePlus />
          </Box>
          <Text>{t('components.addCompanyModal.addButton')}</Text>
        </Button>
      </Box>
      <Dialog
        title={t('components.addCompanyModal.dialog.title')}
        secondaryActionName={t('components.addCompanyModal.dialog.save')}
        secondaryAction={saveCreateCompanyData}
        secondaryActionDisabled={!checkCompanyInputs()}
        isOpen={isCreateCompanyOpen}
        onClose={onCloseCreateCompany}
      >
        <FormControl>
          <FormProperty
            label={t('components.addCompanyModal.dialog.completeName')}
            formType="input"
            invalid={companyName === ''}
            value={companyName}
            onValueChange={setCompanyName}
            colorEvenItems
            testName="Vollständiger Name"
          />
          <FormProperty
            label={t('components.addCompanyModal.dialog.shortName')}
            formType="input"
            invalid={companyNameAbbreviation === ''}
            value={companyNameAbbreviation}
            onValueChange={setCompanyNameAbbreviation}
            colorEvenItems
            testName="Kurzname"
          />
          <FormProperty
            label={t('components.addCompanyModal.dialog.country')}
            formType="selectNameValue"
            value={countryCode}
            onValueChange={setCountryCode}
            invalid={countryCode === '-'}
            colorEvenItems
            testName="Land"
            options={countryCodeOptions}
          />
          <FormProperty
            label={t('components.addCompanyModal.dialog.industryLvl1')}
            formType="selectNameValue"
            value={industryLevel1}
            onValueChange={updateIndustryLevel1}
            invalid={industryLevel1 === '-'}
            colorEvenItems
            testName="Industrie Level 1"
            options={getIndustryLevel1NamesAndValues()}
          />
          <FormProperty
            label={t('components.addCompanyModal.dialog.industryLvl2')}
            formType="selectNameValue"
            invalid={industryLevel2 === '-'}
            value={industryLevel2}
            onValueChange={updateIndustryLevel2}
            colorEvenItems
            testName="Industrie Level 2"
            options={getIndustryLevel2NamesAndValues()}
          />
          <FormProperty
            label={t('components.addCompanyModal.dialog.industryLvl3')}
            formType="selectNameValue"
            invalid={industryLevel3 === '-'}
            value={industryLevel3}
            onValueChange={setIndustryLevel3}
            colorEvenItems
            testName="Industrie Level 3"
            options={getIndustryLevel3NamesAndValues()}
          />
          <FormProperty
            label={t('components.addCompanyModal.dialog.subscription')}
            formType="selectNameValue"
            invalid={subscription === '-'}
            value={subscription}
            onValueChange={setSubscription}
            colorEvenItems
            testName="Subscription"
            options={subscriptionOptions}
          />
        </FormControl>
      </Dialog>
    </>
  );
};

export default CreateCustomerModal;
