/**
 * German translations
 */
const translations = {
  global: {
    costs: 'Gesamkosten in Euro',
    costsStat: 'Kosten',
    fte: 'FTE',
    totalRevenue: 'Gesamtumsatz in Euro',
    numberEmployees: 'Anzahl der Mitarbeiter',
    page: 'Seite',
    of: 'von',
    previousPageButton: 'vorherige',
    nextPageButton: 'nächste',
    okButton: 'OK',
    confirmButton: 'Bestätigen',
    closeButton: 'Schließen',
    cancelButton: 'Abbrechen',
    academicTitleOptions: {
      bachelor: 'Bachelor',
      master: 'Master',
      diplom: 'Diplom',
      doctor: 'Doktor',
      professor: 'Professor',
    },
    appelationOptions: {
      mr: 'Herr',
      mrs: 'Frau',
    },
    multipleChoiceOptions: {
      yes: 'Ja',
      no: 'Nein',
    },
    roleOptions: {
      admin: 'ADMIN',
      user: 'USER',
      contact: 'CONTACT',
      inactive: 'INACTIVE',
    },
    subscriptionOptions: {
      free: 'Free',
      smart: 'Smart',
      premium: 'Premium',
      flex: 'Flex',
    },
    countryCodeOptions: {
      de: 'Deutschland',
      at: 'Österreich',
      ch: 'Schweiz',
      hun: 'Ungarn',
      oth: 'Anderes Land',
    },
    quartileChartDescription: {
      firstQuartile: '1. Quartil',
      median: 'Median',
      thirdQuartile: '3. Quartil',
    },
  },
  login: {
    title: 'Anmeldung',
    email: 'Email Adresse',
    password: 'Passwort',
    registerButton: 'Registrierung',
    loginButton: 'Login',
    resetPassword: 'Passwort zurücksetzen',
    userNotVerifiedInfo:
      'Sie sind noch nicht verifiziert. Bitte überprüfen Sie Ihre E-Mails nach einer Verifizierungs-Mail. Falls Sie keine E-Mail erhalten haben, klicken Sie auf den untenstehenden Button.',
    resendVerificationMail: 'Verifikations-Mail erneut senden',
    resendVerificationMailModal: {
      title: 'Verifikations-Mail erneut senden',
      email: 'Email Adresse',
      resendVerificationMailButton: 'Senden',
    },
    resendVerificationMailSuccess: {
      title: 'Verifikations-Mail wurde erneut gesendet',
      description: 'Sie erhalten in Kürze eine E-Mail von uns.',
    },
  },
  notFound: {
    content: 'Seite nicht gefunden',
  },
  registration: {
    personalInformation: 'Persönliche Daten',
    subscription: 'Mitgliedschaft',
    success: 'Erfolg',
    heading: 'Registrierung',
    salutation: 'Anrede',
    academicTitle: 'Akad. Titel',
    firstName: 'Vorname',
    lastName: 'Nachname',
    position: 'Position',
    department: 'Einheit / Abteilung',
    headingRegisterWithEmail:
      'Bitte registrieren sie sich mit der Emailadresse Ihres Unternehmens, nur so können Sie zugeordnet werden.',
    email: 'Email Adresse',
    repeatEmail: 'Email Adresse wiederholen',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    dataProtectionText:
      'Ich willige in die Speicherung und Verarbeitung meiner personenbezogenen Daten zu den ausgewählten Zwecken ein und stimme zu, dass Horváth die von mir mitgeteilten persönlichen Daten erhebt, speichert, verarbeitet und nutzt. Eine über die genannten Zwecke hinausgehende Datenverarbeitung findet nur statt, wenn ein berechtigtes Interesse im Sinne der DSGVO vorliegt oder die Datenverarbeitung aufgrund gesetzlicher Regelungen vorgeschrieben ist. Ich kann meine Einwilligung zur Verarbeitung meiner Daten jederzeit widerrufen (per E-Mail an mcs@horvath-partners.com). Einzelheiten finden Sie in unserer',
    dataProtection: 'Datenschutzerklärung',
    registrationButton: 'Registrieren',
    infoModal: {
      title: 'Registrierung erfolgreich',
      infoText1:
        'Sie erhalten eine Verifikations-Mail und nach Überprüfung Ihrer Daten wird Ihr Konto freigeschaltet.',
      infoText2: 'Dies kann bis zu 2 Arbeitstage dauern.',
      logIn: 'Zurück zum Login',
      promiseTitle: 'Nutzer wird registriert...',
      promiseText: 'Bitte warten Sie kurz, bis der Registrierungsprozess abgeschlossen ist...',
    },
    companyAlreadyRegistered: 'Mein Unternehmen ist bereits beim CFO-Panel registriert',
    companyNotRegistered: 'Mein Unternehmen ist noch nicht beim CFO-Panel registriert',
    subscriptionCards: {
      free: 'Kostenlos',
      onRequest: 'Auf Anfrage',
      networkSubTitle: 'Netzwerk',
      knowledgeSubTitle: 'Wissen',
      benchmarkingSubTitle: 'Benchmarking',
      chooseSubscription: 'Mitgliedschaft wählen',
      wholePanel: 'Gesamtpanel Ressourcen-Benchmarking via WebApp',
      cfoMeetings: 'Kostenfreie Teilnahme an der CFO-Studie und CFO-Panel Meetings',
      cfoMeetingData: 'Erhalt CFO-Panel Panel-Meeting Unterlagen',
      digitalMaturity: 'Teilnahme am Digital Maturity Assessment',
      aiDataConference: 'Kostenfreie Teilnahme AI & Data Konferenz',
      invitation: 'Automatische Einladung zu CFO-Studie und CFO-Panel Meetings',
      peerGroup: 'Peergroup Ressourcen-Benchmarking (inkl. umfänglichen PPT Bericht',
      cfoStudyResults: 'Erhalt vollumfänglicher CFO-Studienbericht',
      workshop: 'Halbtägiger Ergebnisanalyse-Workshop',
      participationText: '* bei Teilnahme am CFO-Panel Meeting',
      orderButtonFree: 'Als kostenfreies Mitglied registrieren',
      orderButtonPaid: 'Kostenpflichtig bestellen',
      orderButtonFlex: 'Flex-Mitgliedschaft anfragen',
      orderInfoFree:
        'Hiermit beantrage ich eine kostenlose Mitgliedschaft im Horváth CFO-Panel. Ein Wechsel in andere Mitgliedschaften ist jederzeit möglich.',
      orderInfoPay:
        'Hiermit beantrage ich eine kostenpflichtige Mitgliedschaft im Horváth CFO-Panel. Die Mitgliedschaft dauert ein Jahr und wird danach automatisch verlängert. Die Kündigung ist jeweils vor Ablauf des Jahres per E-Mail an cfo-panel@horvath-partners.com möglich.',
      orderInfoFlex:
        'Hiermit frage ich eine Flex-Mitgliedschaft im Horváth CFO-Panel an. In diesem Schritt erfolgt noch kein Abo-Abschluss sondern lediglich eine Anfrage der Flex-Mitgliedschaft. Wir setzen uns umgehend mit Ihnen in Verbindung und lassen ein Angebot zukommen.',
    },
    payment: {
      paymentInformation: 'Rechnungsinformationen',
      paymentSubmission: 'Rechnungsinformationen übermitteln',
      companyNameLaw: 'Firmenname inkl. Rechtsform',
      streetNamePostbox: 'Straße/Nr oder Postfach',
      zipCode: 'PLZ',
      city: 'Ort',
      country: 'Land',
      extendedBillingInformation: {
        text: 'Erhalten wir von Ihnen für die Rechnung noch einen internen Bestellauftrag (z.B. Bestellnummer)?',
        yes: 'Ja',
        no: 'Nein',
      },
      internalPurchaseOrderText: 'Interner Bestellauftrag',
      offersText:
        'Wir würden uns sehr freuen, Sie auch über weitere Angebote von Horváth informieren zu dürfen.',
      exclusiveContent:
        'Ich willige ein, dass mich Horváth über weitere exklusive Inhalte, interessante Einladungen, attraktive Angebote und Vorteile eines professionellen Kompetenznetzwerks auf dem Laufenden hält.',
      cLevelContent:
        'Ich willige ein, dass mich Horváth regelmäßig zu exklusiven C-Level-Inhalten rund um Transformation und Unternehmenssteuerung informiert (Horváth CxO News).',
      news: 'Ich willige ein, dass mich Horváth regelmäßig zu aktuellen Themen, Trends und Terminen zum Thema People (Change, HR Consulting, Training) informiert (E-News "Denkanstöße").',
    },
  },
  verifyUser: {
    success: {
      heading: 'Erfolgreich verifiziert!',
      contentHeading: 'Herzlich Willkommen beim CFO-Panel',
      contentBody1: 'Ihre Registrierung wurde erfolgreich verifiziert',
      contentBody2:
        'Sie können sich sobald Ihr Konto freigeschaltet worden ist mit Ihrer Email-Adresse und dem von Ihnen gewählten Passwort einloggen.',
      loginButton: 'Hier geht es zum Login',
    },
    noValidToken: {
      heading: 'Verifizierung fehlgeschlagen!',
      content: 'Ihre Registrierung konnte nicht abgeschlossen werden.',
    },
    expiredToken: {
      heading: 'Verifizierung fehlgeschlagen!',
      contentBody1:
        'Ihre Registrierung konnte nicht abgeschlossen werden, da Ihr Token abgelaufen ist.',
      contentBody2: 'Bitte verwenden Sie den folgenden Link, um einen neuen Token anzufordern.',
      resendTokenButton: 'Neuen Token anfordern',
      infoModal: {
        title: 'Neue Verifikations-Mail versendet',
        infoText: 'Sie erhalten eine neue Verifikations-Mail.',
      },
    },
  },
  resetPassword: {
    successResetPassword: {
      title: 'Kennwort erfolgreich zurückgesetzt!',
      description: '',
    },
    inputPasswordHeading: 'Passwort eingeben',
    inputPasswordBody: 'Bitte geben Sie ihr neues Passwort ein',
    newPassword: 'Neues Passwort',
    repeatNewPassword: 'Neues Password wiederholen',
    submitButton: 'Absenden',
    noValidToken: {
      heading: 'Verifizierung fehlgeschlagen!',
      body: 'Sie besitzen keinen gültigen Token.',
    },
    expiredToken: {
      heading: 'Verifizierung fehlgeschlagen!',
      body1:
        'Das Zurücksetzen ihres Passworts kann nicht durchgeführt werden, da ihr Token abgelaufen ist.',
      body2: 'Bitte verwenden Sie den folgenden Link, um einen neuen Token anzufordern.',
      requestNewTokenButton: 'Neuen Token anfordern',
    },
  },
  verifyEmail: {
    heading: 'Verifizierung der E-Mail',
    body: 'Geben Sie bitte die E-Mail-Adresse Ihres Unternehmens für das Zurücksetzen Ihres Passworts ein. Sie erhalten daraufhin eine E-Mail von uns und können über den darin enthaltenen Link Ihr Passwort zurücksetzen.',
    email: 'E-Mail Adresse ',
    submitButton: 'Absenden',
    success: {
      title: 'E-Mail wurde verifiziert',
      description: 'Sie erhalten in Kürze eine E-Mail von uns.',
    },
  },
  landingPage: {
    pictureTop: 'Benchmarking',
    pictureMiddleLeft: {
      first: 'Virtuelles CFO-Panel',
      second: 'Meeting',
    },
    pictureMiddleRight: 'Horváth Events',
    pictureBottomLeft: {
      first: 'Digital Maturity',
      second: 'Assessment',
    },
    pictureBottomRight: 'CFO-Studie',
  },
  benchmarkings: {
    title: 'Benchmarkings',
    createBenchmarkingButton: 'Neues Benchmarking erstellen',
    labelControlling: 'Controlling',
    labelFinance: 'Finance',
    success: {
      title: 'Benchmarking erfolgreich erstellt',
      description: '',
    },
  },
  benchmarkingOverview: {
    successSaveChanges: {
      title: 'Änderungen erfolgreich gespeichert',
      description: '',
    },
    successSubmitBenchmarking: {
      title: 'Benchmarking erfolgreich eingereicht',
      description: '',
    },
    pageHeader: 'Benchmarking-Übersicht',
    overviewBenchmarkings1: 'Übersicht der Benchmarkings für das Jahr  ',
    overviewBenchmarkings2:
      'Es besteht die Möglichkeit ein Benchmarking zum Bereich Controlling und Finance durchzuführen.',
    inputEmployees: 'Bitte geben Sie die Anzahl der Mitarbeiter an.',
    inputRevenue: 'Bitte geben Sie den Gesamtumsatz an.',
    saveButton: 'Speichern',
    processtitle: 'Prozessübersicht',
    labelControlling: 'Controlling',
    labelFinance: 'Finance',
    submitBenchmarking: 'Benchmarking einreichen',
    evaluationBenchmarking: 'Auswertung Benchmarkings',
    warningSubmitBenchmarking:
      'Die Auswertung wird erst freigeschaltet, wenn das Benchmarking eingereicht wurde.',
    questionSubmitBenchmarking: 'Möchten Sie dieses Benchmarking wirklich einreichen?',
    submitButton: 'Einreichen',
    warningAfterSubmit1: 'Wenn Sie dieses Benchmarking einreichen, können Sie',
    warningAfterSubmit2: 'keine Änderungen',
    warningAfterSubmit3: 'mehr daran vornehmen!',
  },
  user: {
    changeEmail: {
      title: 'Email-Adresse geändert!',
      description: 'Sie können sich nun erneut mit ihrer neuen Email-Adresse einloggen.',
    },
    saveChanges: {
      title: 'Änderungen gespeichert',
      description: '',
    },
    changePassword: {
      title: 'Kennwort erfolgreich geändert!',
      description: '',
    },
    userCard: {
      title: 'Benutzer',
      editButton: 'Bearbeiten',
      changePasswordButton: 'Passwort ändern',
      salutation: 'Anrede',
      academicTitle: 'Akad. Titel',
      firstName: 'Vorname',
      lastName: 'Nachname',
      position: 'Position',
      department: 'Abteilung',
      email: 'E-Mail',
      subscription: 'Mitgliedschaft',
      changeSubscription: 'Mitgliedschaft ändern',
      dialog: {
        editUser: 'Benutzer bearbeiten',
        save: 'Speichern',
        changePassword: 'Kennwort ändern',
        confirm: 'Bestätigen',
        oldPassword: 'Altes Passwort',
        newPassword: 'Neues Passwort',
        repeatNewPassword: 'Neues Passwort wiederholen',
      },
      pageHeader: 'Benutzereinstellungen',
      subHeading: 'Übersicht über das Konto des angemeldeten Benutzers.',
    },
    companyCard: {
      title: 'Unternehmen',
      completeName: 'Vollständiger Name',
      shortName: 'Kurzname',
      country: 'Land',
      industryLvl1: 'Industrie Level 1',
      industryLvl2: 'Industrie Level 2',
      industryLvl3: 'Industrie Level 3',
    },
    changeSubscription: {
      dialog: {
        title: 'Mitgliedschaft ändern',
        submit: 'Bestellen',
      },
      successChangeSubscription: {
        title: 'Mitgliedschaft erfolgreich geändert',
        description: '',
      },
    },
  },
  adminSettings: {
    userButton: 'Nutzer',
    companyButton: 'Unternehmen',
  },
  adminUserOverview: {
    pageHeader: 'Übersicht aller Nutzer',
    successDeleteUser: {
      title: 'Nutzer erfolgreich gelöscht',
      description: '',
    },
    deletePopver: {
      headerText: 'Nutzer löschen',
      confirmationText: 'Möchten Sie den Nutzer wirklich löschen?',
    },
    tableHeader: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      role: 'Rolle',
      email: 'Email',
      company: 'Unternehmen',
      department: 'Einheit / Abteilung',
      verified: 'Verifiziert',
      lastLogin: 'Letzter Login',
      subscriptionType: 'Gewünschtes Abo',
      extendedCompanyName: 'Voller Firmenname',
      companyAddress: 'Firmenanschrift',
      zipCode: 'PLZ',
      city: 'Stadt',
      country: 'Land',
      internalPurchaseOrder: 'Interner Bestellauftrag',
      allowExclusiveContent: 'Exklusiver Inhalt',
      allowClevelContent: 'C-Level Inhalte',
      allowNews: 'News',
    },
    searchBar: {
      placeholder: 'Nutzer suchen...',
    },
    resendVerificationMailSuccess: {
      title: 'Verifikations Email versendet',
      description: 'Der gewählte Nutzer erhält in Kürze eine Email.',
    },
    resendVerificationMailError: {
      title: 'Verifikations Email konnte nicht gesendet werden',
      description: 'Beim Senden der Verifikations Email ist ein Fehler aufgetreten.',
    },
  },
  adminEditUserModal: {
    successSaveEditUserData: {
      title: 'Änderungen gespeichert',
      description: '',
    },
    popover: {
      header: 'Bestätigung',
      body: 'Wollen Sie wirklich die Rolle Admin vergeben?',
    },
    dialog: {
      title: 'Nutzer bearbeiten',
      saveButton: 'Speichern',
      labelRole: 'Rolle',
      labelCompany: 'Unternehmen',
    },
  },
  adminEditCompanyModal: {
    successSaveEditCompanyData: {
      title: 'Änderungen gespeichert',
      description: '',
    },
    popover: {
      header: 'Bestätigung',
      body: 'Wollen Sie wirklich die subscription ändern?',
    },
    dialog: {
      title: 'Unternehmen bearbeiten',
      saveButton: 'Save',
      labelCompleteName: 'Vollständiger Name',
      labelShortName: 'Kurzname',
      labelCountry: 'Land',
      labelIndustryLvl1: 'Industrie Level 1',
      labelIndustryLvl2: 'Industrie Level 2',
      labelIndustryLvl3: 'Industrie Level 3',
      labelSubscription: 'Subscription',
    },
  },
  adminCompanyOverview: {
    pageHeader: 'Übersicht aller Unternehmen',
    successDeleteCompany: {
      title: 'Unternehmen erfolgreich gelöscht',
      description: '',
    },
    deletePopver: {
      headerText: 'Unternehmen löschen',
      confirmationText: 'Möchten Sie dieses Unternehmen wirklich löschen?',
    },
    tableHeader: {
      completeName: 'Vollständiger Name',
      shortName: 'Kurzname',
      industryLvl1: 'Industrie Level 1',
      industryLvl2: 'Industrie Level 2',
      industryLvl3: 'Industrie Level 3',
      country: 'Land',
      subscription: 'Subscription',
    },
    searchBar: {
      placeholder: 'Unternehmen suchen...',
    },
  },
  components: {
    errorStat: {
      errorHeading: 'Ein Fehler ist aufgetreten.',
      updateText: 'Aktualisieren',
    },
    addCompanyModal: {
      addButton: 'Unternehmen hinzufügen',
      dialog: {
        title: 'Unternehmen hinzufügen',
        save: 'Speichern',
        completeName: 'Vollständiger Name',
        shortName: 'Kurzname',
        industryLvl1: 'Industrie Level 1',
        industryLvl2: 'Industrie Level 2',
        industryLvl3: 'Industrie Level 3',
        country: 'Land',
        subscription: 'Subscription',
      },
    },
  },
  version: 'Version',
  countries: {
    AF: 'Afghanistan',
    AL: 'Albanien',
    DZ: 'Algerien',
    AS: 'Amerikanisch-Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktika',
    AG: 'Antigua und Barbuda',
    AR: 'Argentinien',
    AM: 'Armenien',
    AW: 'Aruba',
    AU: 'Australien',
    AT: 'Österreich',
    AZ: 'Aserbaidschan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesch',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivien',
    BA: 'Bosnien und Herzegowina',
    BW: 'Botswana',
    BR: 'Brasilien',
    BN: 'Brunei Darussalam',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Kambodscha',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kap Verde',
    KY: 'Kaimaninseln',
    CF: 'Zentralafrikanische Republik',
    TD: 'Tschad',
    CL: 'Chile',
    CN: 'China',
    CO: 'Kolumbien',
    KM: 'Komoren',
    CG: 'Kongo',
    CD: 'Demokratische Republik Kongo',
    CK: 'Cookinseln',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Kroatien',
    CU: 'Kuba',
    CY: 'Zypern',
    CZ: 'Tschechische Republik',
    DK: 'Dänemark',
    DJ: 'Dschibuti',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    EC: 'Ecuador',
    EG: 'Ägypten',
    SV: 'El Salvador',
    GQ: 'Äquatorialguinea',
    ER: 'Eritrea',
    EE: 'Estland',
    ET: 'Äthiopien',
    FJ: 'Fidschi',
    FI: 'Finnland',
    FR: 'Frankreich',
    GA: 'Gabun',
    GM: 'Gambia',
    GE: 'Georgien',
    DE: 'Deutschland',
    GH: 'Ghana',
    GR: 'Griechenland',
    GD: 'Grenada',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hongkong',
    HUN: 'Ungarn',
    IS: 'Island',
    IN: 'Indien',
    ID: 'Indonesien',
    IR: 'Iran',
    IQ: 'Irak',
    IE: 'Irland',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaika',
    JP: 'Japan',
    JO: 'Jordanien',
    KZ: 'Kasachstan',
    KE: 'Kenia',
    KI: 'Kiribati',
    KP: 'Nordkorea',
    KR: 'Südkorea',
    KW: 'Kuwait',
    KG: 'Kirgisistan',
    LA: 'Laos',
    LV: 'Lettland',
    LB: 'Libanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MO: 'Macao',
    MK: 'Nordmazedonien',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Malediven',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshallinseln',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    MX: 'Mexiko',
    FM: 'Mikronesien',
    MD: 'Moldawien',
    MC: 'Monaco',
    MN: 'Mongolei',
    ME: 'Montenegro',
    MA: 'Marokko',
    MZ: 'Mosambik',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Niederlande',
    NZ: 'Neuseeland',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NO: 'Norwegen',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palästina',
    PA: 'Panama',
    PG: 'Papua-Neuguinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippinen',
    PL: 'Polen',
    PT: 'Portugal',
    QA: 'Katar',
    RO: 'Rumänien',
    RU: 'Russland',
    RW: 'Ruanda',
    KN: 'St. Kitts und Nevis',
    LC: 'St. Lucia',
    VC: 'St. Vincent und die Grenadinen',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé und Príncipe',
    SA: 'Saudi-Arabien',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellen',
    SL: 'Sierra Leone',
    SG: 'Singapur',
    SK: 'Slowakei',
    SI: 'Slowenien',
    SB: 'Salomonen',
    SO: 'Somalia',
    ZA: 'Südafrika',
    SS: 'Südsudan',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SZ: 'Eswatini',
    SE: 'Schweden',
    CH: 'Schweiz',
    SY: 'Syrien',
    TW: 'Taiwan',
    TJ: 'Tadschikistan',
    TZ: 'Tansania',
    TH: 'Thailand',
    TL: 'Osttimor',
    TG: 'Togo',
    TO: 'Tonga',
    TT: 'Trinidad und Tobago',
    TN: 'Tunesien',
    TR: 'Türkei',
    TM: 'Turkmenistan',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'Vereinigte Arabische Emirate',
    GB: 'Vereinigtes Königreich',
    US: 'Vereinigte Staaten',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    YE: 'Jemen',
    ZM: 'Sambia',
    ZW: 'Simbabwe',
    OTH: 'Anderes Land',
  },
  errors: {
    app: {
      403: {
        title: 'Keine Berechtigung',
        description:
          'Sie haben nicht die notwendige Berechtigung, um auf die entsprechende Seite zuzugreifen',
      },
    },
    login: {
      401: { title: 'Anmeldung fehlgeschlagen', description: 'Zugangsdaten sind inkorrekt' },
      403: {
        title: 'Account muss freigeschalten werden',
        description:
          'Ihr Account muss manuell geprüft und freigeschalten werden. Dies kann bis zu 2 Arbeitstage dauern.',
      },
      resendVerificationMail: {
        title: 'Senden der Verifikations-Mail fehlgeschlagen',
        description: 'Bitte überprüfen Sie Ihre eingegebene E-Mail-Adresse.',
      },
    },
    logout: {
      logoutFailed: {
        title: 'Ein Fehler ist aufgetreten',
        description: '',
      },
    },
    registration: {
      409: {
        title: 'Diese Email-Adresse wird bereits verwendet.',
        description: '',
      },
      salutation: 'Bitte geben Sie eine Anrede an.',
      firstName: 'Bitte geben Sie einen Vornamen an.',
      lastName: 'Bitte geben Sie einen Nachnamen an.',
      invalidEmail: 'Bitte geben Sie eine gültige Email Adresse ein.',
      emailsNotMatch: 'Die eingegebenen Email Adressen stimmen nicht überein.',
      invalidPassword: 'Bitte geben Sie ein Passwort mit min. 8 Zeichen ein.',
      passwordsNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein.',
      fieldCanNotBeEmpty: 'Dieses Feld muss ausgefüllt sein',
      errorModal: {
        title: 'Es ist ein Fehler aufgetreten',
        infoText: 'Ihr Anfrage konnte nicht bearbeitet werden.',
      },
    },
    benchmarkings: {
      createBenchmarkingFailed: {
        title: 'Erstellung fehlgeschlagen',
        description: '',
      },
    },
    benchmarkingOverview: {
      fetchBenchmarkingDataFailed: {
        title: 'Benchmarking-Daten konnten nicht geladen werden',
        description: '',
      },
      fetchCompanyDataFailed: {
        title: 'Unternehmensdaten konnten nicht geladen werden',
        description: '',
      },
      saveBenchmarkingFailed: {
        title: 'Speichern fehlgeschlagen',
        description: '',
      },
      errorSaveInputsEmpty: {
        title: 'Speichern fehlgeschlagen',
        description:
          'Für das Abspeichern müssen die beiden Eingabefelder "Gesamtumsatz in Euro" und "Anzahl der Mitarbeiter" ausgefüllt werden.',
      },
      submitBenchmarkingFailed: {
        title: 'Einreichung fehlgeschlagen',
        description: '',
      },
      errorSubmitInputsEmpty: {
        title: 'Speichern fehlgeschlagen',
        description:
          'Für das Einreichen der Benchmarkings ist das Ausfüllen der beiden Eingabefelder "Gesamtumsatz" und "Anzahl der Mitarbeiter" notwendig.',
      },
    },
    user: {
      fetchUserDataFailed: {
        title: 'Nutzerdaten konnten nicht geladen werden',
        description: '',
      },
      fetchCompanyDataFailed: {
        title: 'Unternehmensdaten konnten nicht geladen werden',
        description: '',
      },
      logout: {
        title: 'Ein Fehler ist aufgetreten',
        description: '',
      },
      saveChangesFailed: {
        title: 'Speichern fehlgeschlagen',
        description: '',
      },
      changePasswordFailed: {
        title: 'Kennwort ändern fehlgeschlagen',
        description: '',
      },
      changePassword: {
        oldPasswordInvalid: 'Ihr aktuelles Passwort muss min. 8 Zeichen besitzen.',
        newPasswordInvalid: 'Bitte geben Sie ein Passwort mit min. 8 Zeichen ein.',
        passwordsNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein.',
        distinguishNewFromOld: 'Das neue Passwort sollte sich vom alten Passwort unterscheiden.',
      },
      changeSubscrptionFailed: {
        title: 'Mitgliedschaft ändern fehlgeschlagen',
        description: '',
      },
    },
    adminUserOverview: {
      fetchDataFailed: {
        title: 'Unternehmen konnten nicht geladen werden',
        description: '',
      },
      deleteYourselfNotPossible: {
        title: 'Nutzer können sich nicht selbst löschen!',
        description: '',
      },
      deleteUserFailed: {
        title: 'Löschen fehlgeschlagen',
        description: '',
      },
    },
    adminEditUserModal: {
      removeAdminRoleFailed: {
        title: 'Ein Admin kann sich nicht selbst die Admin-Rolle entziehen',
        description: '',
      },
      editRoleFailed: {
        title: 'Speichern fehlgeschlagen',
        description: '',
      },
      saveEditUserDataFailed: {
        title: 'Speichern fehlgeschlagen',
        description: '',
      },
      errorModal: {
        title: 'Nutzer ist noch nicht verifiziert!',
        infoText:
          'Der Nutzer hat seine E-Mailadresse noch nicht verifiziert. Daher kann noch keine Rollenänderung vorgenommen werden.',
      },
    },
    adminEditCompanyModal: {
      editCompanyFailed: {
        title: 'Speichern fehlgeschlagen',
        description: '',
      },
    },
    adminCompanyOverview: {
      deleteCompanyNoUserFailed: {
        title: 'Löschen fehlgeschlagen',
        description:
          'Das Unternehmen besitzt noch Mitarbeiter. Um das Unternehmen dauerhaft zu löschen müssen Sie zunächst alle Mitarbeiter des Unternehmens löschen.',
      },
      deleteCompanyFailed: {
        title: 'Löschen fehlgeschlagen',
        description: 'Ein Serverfehler ist aufgetreten.',
      },
    },
    verifyUser: {
      expiredToken: {
        errorModal: {
          title: 'Es ist ein Fehler aufgetreten',
          infoText: 'Ihr Anfrage konnte nicht bearbeitet werden.',
        },
      },
    },
    resetPassword: {
      errorModal: {
        title: 'Es ist ein Fehler aufgetreten!',
        description: '',
      },
      invalidPassword: 'Bitte geben Sie ein Passwort mit min. 8 Zeichen ein.',
      passwordsNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein.',
    },
    verifyEmail: {
      savePasswordsResetFailed: {
        title: 'Speichern fehlgeschlagen',
        description: 'Bitte überprüfen Sie Ihre Eingaben.',
      },
      noEmailExists: {
        title: 'E-Mail konnte nicht verifiziert werden',
        description: 'Es existiert kein Nutzer mit dieser Mailadresse',
      },
      invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    },
    benchmarking: {
      valueBetweenError: 'Der Wert muss zwischen {{min}} und {{max}} liegen.',
      valueBelowError: 'Der Wert muss unter {{max}} liegen.',
      sumOfKPIsError: 'Die Summer der KPIs müssen zusammen {{sum}} Prozent ergeben',
      notNegativeError: 'Der Wert darf nicht negativ sein.',
      valueBetweenDaysError: 'Der Wert muss zwischen {{min}} und {{max}} Tagen liegen.',
      valueBetweenPercentError: 'Der Wert muss zwischen {{min}} und {{max}} Prozent liegen.',
      valueBetweenEuroError: 'Der Wert muss zwischen {{min}} und {{max}} Euro liegen.',
      controlling: {
        fetchControllingDataFailed: {
          title: 'Controlling-Daten konnten nicht geladen werden',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Unternehmensdaten konnten nicht geladen werden',
          description: '',
        },
        saveDataFailed: {
          title: 'Speichern fehlgeschlagen',
          description: 'Bitte überprüfen Sie Ihre Eingaben.',
        },
      },
      finance: {
        fetchFinanceDataFailed: {
          title: 'Finance-Daten konnten nicht geladen werden',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Unternehmensdaten konnten nicht geladen werden',
          description: '',
        },
        saveDataFailedCheckInput: {
          title: 'Speichern fehlgeschlagen',
          description: 'Bitte überprüfen Sie Ihre Eingaben.',
        },
        saveDataFailed: {
          title: 'Speichern fehlgeschlagen',
          description: '',
        },
      },
    },
    evaluation: {
      finance: {
        fetchBenchmarkingDataFailed: {
          title: 'Benchmarking-Daten konnten nicht geladen werden',
          description: '',
        },
        fetchBenchmarkingEvaluationDataFailed: {
          title: 'Auswertung der Benchmarking-Daten konnten nicht geladen werden',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Unternehmensdaten konnten nicht geladen werden',
          description: '',
        },
      },
      controlling: {
        fetchBenchmarkingDataFailed: {
          title: 'Benchmarking-Daten konnten nicht geladen werden',
          description: '',
        },
        fetchBenchmarkingEvaluationDataFailed: {
          title: 'Auswertung der Benchmarking-Daten konnten nicht geladen werden',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Unternehmensdaten konnten nicht geladen werden',
          description: '',
        },
      },
    },
  },
  controlling: {
    benchmarking: {
      loading: 'Benchmarking-Daten werden geladen. Bitte haben Sie etwas Geduld.',
      info: 'Benchmarking zum Bereich Controlling des Jahres ',
      sliderOptions: {
        singleProcesses: 'Einzelprozesse',
        totalProcess: 'Gesamtprozess',
      },
      totalProcess: {
        title: 'Gesamtprozess',
        tooltip: `Gesamtheitliche Betrachtung des Controllings`,
      },
      singleProcesses: {
        title: 'Einzelprozesse',
        tooltip: 'Aufteilung des Controllings in die jeweiligen Einzelprozesse',
      },
      strategicPlanning: {
        title: 'Strategische Planung',
        tooltip: `Die strategische Planung legt den grundsätzlichen 
                  Orientierungsrahmen für zentrale Unternehmensentscheidungen fest. 
                  Sie definiert Ziele und Maßnahmen und trifft Festlegungen zu 
                  wesentlichen Themen. Dabei bewegt sie sich innerhalb der 
                  unternehmenspolitischen Festlegungen. Gegenstände der strategischen Planung 
                  sind u.a. Märkte, Produkte, Portfolio, Wettbewerb, Innovationen, Technologie, 
                  Kernkompetenzen, Ressourcen.`,
        planningHorizonMonths: {
          title: 'Planungshorizont der strategischen Planung (in Monaten)',
          tooltip: 'Zeitraum der strategischen Planung',
        },
        durationWeeks: {
          title: 'Dauer der strategischen Planung (in KW)',
          tooltip: 'Bitte geben Sie die Dauer Ihrer strategischen Planung an',
        },
        votingRounds: {
          title: 'Anzahl Abstimmungsrunden',
          tooltip: 'Bitte geben Sie wieviele Abstimmungsrunden Ihre strategische Planung bedarf',
        },
        numberParticipants: {
          title: 'Anzahl involvierter Akteure',
          tooltip:
            'Bitte geben Sie an wieviele Akteure in ihre strategische Planung involviert sind',
        },
      },
      operativePlanning: {
        title: 'Operative Planung',
        tooltip: `Die operative Planung schafft unter Berücksichtigung der 
                  strategischen Ziele ein Orientierungsgerüst für Aktivitäten und 
                  Entscheidungen in kurz- bis mittelfristigem Zeithorizont. Es geht um die 
                  Festlegung von Zielen und Maßnahmen, die Zuordnung von Ressourcen sowie die 
                  finanzielle Quantifizierung dessen für das Unternehmen als Ganzes sowie für 
                  seine einzelnen Einheiten. Gegenstände sind u.a. GuV, Bilanz, Cash-Flow, 
                  Umsatz, Kosten, Ergebnis, Investitionen, Projekte, Mengen, Kapazitäten, 
                  Mitarbeiter.`,
        operativePlanningWeeks: {
          title: 'Planungszeitraum (in Wochen)',
          tooltip:
            'Geben Sie bitte die durchschnittliche Dauer des Planungszeitraums für die operative Planung und deren einzelnen Phasen an.',
        },
        planningPreparation: {
          title: 'Planungsvorbereitung (in Wochen)',
          tooltip:
            'Geben Sie bitte die Dauer des Planungszeitraums für die operative Planung der Phase Planungsvorbereitung an.',
        },
        targetSetting: {
          title: 'Zielsetzung (in Wochen)',
          tooltip:
            'Geben Sie bitte die Dauer des Planungszeitraums für die operative Planung der Phase Zielsetzung / Target Setting an.',
        },
        decentralizedPlanning: {
          title: 'Dezentrale Planung (in Wochen)',
          tooltip:
            'Geben Sie bitte die Dauer des Planungszeitraums für die operative Planung der Phase dezentrale Planung an.',
        },
        consolidationCompletionOfPlanning: {
          title: 'Konsolidierung und Fertigstellung (in Wochen)',
          tooltip:
            'Geben Sie bitte die Dauer des Planungszeitraums für die operative Planung der Phase Konsolidierung und Fertigstellung der Planung an.',
        },
        planShiftApproval: {
          title: 'Planverabschiebung/-genehmigung (in Wochen)',
          tooltip:
            'Geben Sie bitte die Dauer des Planungszeitraums für die operative Planung der Phase Planverabschiedung/-genehmigung an.',
        },
        votingRounds: {
          title: 'Durchschnittliche Anzahl der Abstimmungsrunden',
          tooltip: 'Geben Sie die durchschnittliche Anzahl der Abstimmungsrunden an.',
        },
        planningAlignment: {
          title: 'Ausrichtung der Planung',
          tooltip: 'Wie ist Ihre operative Planung ausgerichtet?',
          options: {
            topDown: 'Top-Down',
            bottomUp: 'Bottom-Up',
            mixed: 'Gemischt',
          },
        },
        planningHorizonMonths: {
          title: 'Planungshorizont der operativen Planung (in Monaten)',
          tooltip: 'Geben Sie den Planungshorizont in Monaten an.',
        },
        typeOperativePlanning: {
          title: 'Art der operativen Planung',
          tooltip: 'Welche Art der operativen Planung setzen Sie ein?',
          options: {
            mediumTermPlanning: 'Mittelfristplanung',
            annualPlanning: 'Jahresplanung/ Budget',
            annualPlanningSeasonalization: 'Jahresplanung/ Budget mit Saisonalisierung',
            annualPlanningRollingForecasts:
              'Jahresplanung mit unterjährigen Forecasts auf das Geschäftsjahresende',
            annualPlanningYtef:
              'Jahresplanung mit rollierenden Forecasts mit stets gleichbleibendem Horizont',
            rollingPlanning:
              'Rollierende Planung mit quartalsweisen Budgetaktualisierungen mit stets gleichbleibendem Horizont',
          },
        },
      },
      forecast: {
        title: 'Forecast',
        tooltip: `Im Rahmen des Forecasts erfolgt eine Einschätzung der zukünftigen 
                  wirtschaftlichen Entwicklung, der Auswirkung auf Ziele, Pläne und Budgets unter 
                  Berücksichtigung von Gegensteuerungsmaßnahmen und Ressourcenanpassungen. Der 
                  Forecast geht damit über eine einfache Prognose hinaus. Gegenstand sind 
                  finanzielle und nichtfinanzielle Informationen sowie Simulations- und 
                  Szenariobetrachtungen. Der Forecast kann sowohl regelmäßig (Standard Forecast) 
                  als auch unregelmäßig (Ad-hoc Forecast) für das Unternehmen als Ganzes oder für 
                  einzelne Themen, Einheiten oder Projekte erstellt werden.`,
        typeForecast: {
          title: 'Art des Forecasts',
          tooltip: 'Welche Art von Forecast setzen Sie in Ihrem Unternehmen ein?',
          options: {
            yearEndForecast: 'Year-End-Forecast',
            rolling: 'Rollierend',
            partRolling: 'Teil-Rollierend',
          },
        },
        levelDetailQuarter: {
          title: 'Detailierungsgrad Quartals-Forecast',
          tooltip: 'Wie detailliert ist in Ihrem Unternehmen der zentrale Forecast?',
        },
        levelDetailMonth: {
          title: 'Detailierungsgrad Monats-Forecast',
          tooltip: 'Wie detailliert ist in Ihrem Unternehmen der zentrale Forecast?',
        },
        levelDetailOthers: {
          title: 'Detailierungsgrad Sonstige',
          tooltip: 'Wie detailliert ist in Ihrem Unternehmen der zentrale Forecast?',
        },
        levelDetailWeek: {
          title: 'Detailierungsgrad Wochen-Forecast',
          tooltip: 'Wie detailliert ist in Ihrem Unternehmen der zentrale Forecast?',
        },
        accuracy: {
          title: 'Genauigkeit/ Verlässlichkeit des Forecasts (in %)',
          tooltip: 'Wie exakt ist Ihre Forecast-Prognose? (Angabe in %)',
        },
        forecastHorizonMonths: {
          title: 'Prognosehorizont (in Monaten)',
          tooltip: 'Bitte geben Sie die zeitliche Reichweite des Forecasts in Monaten an',
        },
      },
      cpra: {
        title: 'Kosten-, Leistungs- und Ergebnisrechnung',
        tooltip: `Inhalte sind zum einen Kosten, Leistungen und Ergebnisse mit Blick 
                  auf Produkte oder Dienstleistungen bzw. auf aggregierter Ebene Produktgruppen 
                  oder ergebnisverantwortliche Einheiten, wie z.B. Geschäftsbereiche. Gegenstand 
                  der Kosten-, Leistungs-und Ergebnisrechnung ist die Erfassung, Verteilung, 
                  Zuordnung, Auswertung und Kontrolle von Kosten, Leistungen und Ergebnissen, die 
                  beim betrieblichen Güterverzehr bzw. der Gütererstellung anfallen.`,
        varianceCtrAcc: {
          title: 'Ergebnisabweichung Controlling vs. Accounting (in %)',
          tooltip:
            'Wie groß ist der Unterschied im Unternehmensergebnis (z.B. EBIT) zwischen der Managementsicht im Controlling und der Buchhaltungssicht?',
        },
        forecastQuality: {
          title: 'Prognosequalität (in %)',
          tooltip:
            'Wie groß ist der Unterschied zwischen dem unterjährigen Forecast und dem tatsächlichen Jahreswerte für Nettoumsatz und Ergebnis (z.B. EBIT)',
        },
        planQuality: {
          title: 'Planqualität (in %)',
          tooltip:
            'Wie groß ist der Unterschied zwischen der Jahresplanung und dem tatsächlichen Jahreswerte für Nettoumsatz und Ergebnis (z.B. EBIT)',
        },
        deviation: {
          title: 'Abweichungen Kosten der Leistungserbringung (in %)',
          tooltip:
            'Höhe der Abweichungen zwischen Plankosten der Leistungserbringung (z.B. Standardherstellkosten der Produkte) und den Istkosten',
        },
      },
      mgmtReporting: {
        title: 'Management Reporting',
        tooltip: `Es werden i.d.R. finanzielle und nicht-finanzielle Informationen 
                  in den Dimensionen Ist, Ist Vorjahr, Plan, Soll und Forecast in Form von 
                  regelmäßigen Standardberichten sowie ad-hoc-Berichten zur Verfügung gestellt. 
                  Basierend auf identifizierten und erklärten Abweichungen und 
                  Zielerreichungsprognosen (Kommentierungen) werden konkrete Vorschläge zur 
                  Gegensteuerung mit dem Management erarbeitet und abgestimmt. Gegenstände sind 
                  u.a. GuV, Bilanz, Cashflow, Umsatz, Kosten, Ergebnis, Investitionen, Projekte, 
                  Mengen, Kapazitäten und Mitarbeiter, bezogen auf die Management-Einheiten im 
                  Unternehmen.`,
        amountReportsExecutiveBoard: {
          title: 'Anzahl der Berichte (Vorstand)',
          tooltip: 'Auswertung wird je FTE im Management Reporting normalisiert',
        },
        amountReportsSupervisoryBoard: {
          title: 'Anzahl der Berichte (Aufsichtsrat)',
          tooltip: 'Auswertung wird je FTE im Management Reporting normalisiert',
        },
        compositionOfReports: {
          title: 'Zusammensetzung der Berichte',
          tooltip: `Die Summe der zwei folgenden KPIs muss 100% ergeben. Es kann ebenso in jedes
                    Eingabefeld eine 0 eingetragen werden. Dies ist gleichzusetzen mit 'keine Angabe'`,
        },
        adhocReports: {
          title: 'Ad-hoc-Berichte (Anteil in %)',
          tooltip: "Wie viel Prozent der Berichte entsprechen 'ad-hoc-Berichten'?",
        },
        standardizedReports: {
          title: 'Standardisierte Berichte (Anteil in %)',
          tooltip: "Wie viel Prozent der Berichte entsprechen 'standardisierten Berichten'?",
        },
        kpisAmount: {
          title: 'Anzahl KPIs',
          tooltip: 'Wie viele KPIs setzen Sie zur Steuerung auf den verschiedenen Ebenen ein?',
        },
        reportingDimensions: {
          title: 'Dimensionen des Berichtswesens',
          tooltip:
            'Wie verteilen Sie die KPIs auf die folgenden Dimensionen: Finanziell, nicht finanziell, extern?',
          options: {
            finance: 'Finanziell',
            financeNot: 'Nicht finanziell',
            extern: 'Extern',
          },
        },
        automationPotentialDataManagement: {
          title: 'Automatisierungspotential für Reportingprozesse: Datenmanagement (in %)',
          tooltip:
            'Wie hoch schätzen Sie das Effizienz-/ Automatsierungspotenzial für den geanannten Reportingprozess, ausgehend vom Status quo, ein?',
        },
        automationPotentialReportGeneration: {
          title: 'Automatisierungspotential für Reportingprozesse: Berichtserstellung (in %)',
          tooltip:
            'Wie hoch schätzen Sie das Effizienz-/ Automatsierungspotenzial für den geanannten Reportingprozess, ausgehend vom Status quo, ein?',
        },
        automationPotentialAnalysis: {
          title: 'Automatisierungspotential für Reportingprozesse: Auswertungen/Analysen (in %)',
          tooltip:
            'Wie hoch schätzen Sie das Effizienz-/ Automatsierungspotenzial für den geanannten Reportingprozess, ausgehend vom Status quo, ein?',
        },
        automationPotentialDecisionSupport: {
          title:
            'Automatisierungspotential für Reportingprozesse: Entscheidungsunterstützung (in %)',
          tooltip:
            'Wie hoch schätzen Sie das Effizienz-/ Automatsierungspotenzial für den geanannten Reportingprozess, ausgehend vom Status quo, ein?',
        },
        integrationAiMl: {
          title: 'Integration von KI und Machine Learning in die Reportlandschaft',
          tooltip:
            'Inwieweit sind Artificial Intelligence und/ oder Machine Learning fester Bestandteil der Reportinglandschaft? ',
          options: {
            full: 'Voll integriert',
            selective: 'Seletkive Integration ausgewählter Use Cases',
            exclusive: 'Ausschließlich Use Case getrieben',
          },
        },
        integrationComment: {
          title: 'Integration von Kommentierungen im aktuellen Berichtswesen',
          tooltip:
            'Beschreiben Sie die Güte bzw. den Grad der Integration von Kommentierungen im aktuellen Berichtswesen.',
          options: {
            manualComplete: 'Rein manuell (offline) Kommentierung',
            manual: 'Manuelle Integration in das Berichtswesen',
            semiAutomatedFrontend: 'Teilautomatsiert (front-end)',
            semiAutomatedBackend: 'Teilautomatsiert (backend-end)',
            automatedAi: 'Automatisierte AI erstellt/ unterstützt',
          },
        },
        integrationSustainability: {
          title: 'Integration von Sustainability Reporting',
          tooltip: 'Wie stark ist Sustainability Reporting in die Standardberichte integriert?',
          options: {
            notExist: 'Nicht/ kaum existent',
            separate: 'In separaten Berichten',
            oneSided:
              'Einseitig integriert (Sustainability Reporting in Standardberichten integriert)',
            full: 'Vollständig integriert (Abbildung wechselseitiger Beziehungen & Effekte zu Inhalten der Standardberichte)',
          },
        },
        formOfUsePush: {
          title: 'Nutzungsform bei den Nutzern: Push (bekommen Bericht) (in %)',
          tooltip: 'Welche Nutzungsfrom überwiegt bei den Nutzern (Standardnutzer)?',
        },
        formOfUseStandard: {
          title:
            'Nutzungsform bei den Nutzern: Navigieren durch ein Tool (Standardberichte) (in %)',
          tooltip: 'Welche Nutzungsfrom überwiegt bei den Nutzern (Standardnutzer)?',
        },
        formOfUseSelfService: {
          title:
            'Nutzungsform bei den Nutzern: Erzeugen sich selbst Berichte (Self-Service) (in %)',
          tooltip: 'Welche Nutzungsfrom überwiegt bei den Nutzern (Standardnutzer)?',
        },
        reportShares: {
          title: 'Anteil der Berichte in Papierform (in %)',
          tooltip: 'Wie viel % der Nutzer drucken Berichte aus?',
        },
        degreeDigitization: {
          title: 'Grad der Digitalisierung des Reportings (in %)',
          tooltip: 'Wie digital würden Sie das Reporting bewerten?',
        },
        reportingProcess: {
          title: 'Berichterstellungsprozess',
          tooltip: 'Wie wird die Berichtsmappe für das Vorstandsreporting erstellt?',
          options: {
            manual: 'Manuelles Zusammenführen unterschiedlicher Einzelberichte',
            automatedDifReports:
              'Automatisierte Erstellung in einem Reporting-System auf Basis verschiedener Reports/Datenquellen',
            automatedIntegratedDatamodell:
              'Automatisierte Erstellung in einem Reporting-System auf Basis eines integrierten Datenmodells',
          },
        },
        reportingClosing: {
          title: 'Closing (in Arbeitstagen nach Ultimo)',
          tooltip:
            'Wann findet der Meilenstein des Reportings statt (in Arbeitstagen nach Ultimo, z.B. 3 für drei Tage nach Ultimo)?',
        },
        reportingFlash: {
          title: 'Flashbericht (in Arbeitstagen nach Ultimo)',
          tooltip:
            'Wann findet der Meilenstein des Reportings statt (in Arbeitstagen nach Ultimo, z.B. 3 für drei Tage nach Ultimo)?',
        },
        reportingMonthlyUncommented: {
          title: 'Monatsbericht (unkommentiert, vorläufig) (in Arbeitstagen nach Ultimo)',
          tooltip:
            'Wann findet der Meilenstein des Reportings statt (in Arbeitstagen nach Ultimo, z.B. 3 für drei Tage nach Ultimo)?',
        },
        reportingMonthlyCommented: {
          title: 'Monatsbericht (kommentiert, final) (in Arbeitstagen nach Ultimo)',
          tooltip:
            'Wann findet der Meilenstein des Reportings statt (in Arbeitstagen nach Ultimo, z.B. 3 für drei Tage nach Ultimo)?',
        },
      },
      pic: {
        title: 'Projekt- und Investitionscontrolling',
        tooltip: ` Projekt- und Investitions-Controlling unterstützen bei Bewertung, 
                  Priorisierung und Auswahl, bei der Planung, der Durchführung und Steuerung sowie 
                  beim Abschluss von Projekten und Investitionsvorhaben. Dazu gehört auch die 
                  Kontrolle der Zielerreichung nach Abschluss der Projekte.`,
        investmentLimit: {
          title: 'Investitionsvolumengrenze (in EUR)',
          tooltip: 'Ab welchem Investitionsvolumen ist eine Genehmigung erforderlich?',
        },
        durationRequest: {
          title: 'Dauer eines Investitionsantrags (in Arbeitstagen)',
          tooltip: 'Wie lange dauert im Durchschnitt die Bearbeitung eines Investitionsantrags?',
        },
        statusProjectsSuccessful: {
          title: 'Anteil erfolgreich abgeschlossener Projekte (in %)',
          tooltip:
            'Bitte machen Sie Angaben zum Status aller Projekte aus dem letzten Geschäftsjahr',
        },
        statusProjectsAborted: {
          title:
            'Anteil fehlgeschlagener Projekte (Projekte, die ohne Abnahme des Projektziels abgebrochen wurden) (in %)',
          tooltip:
            'Bitte machen Sie Angaben zum Status aller Projekte aus dem letzten Geschäftsjahr',
        },
        statusProjectsStopped: {
          title: 'Anteil Projekte, die gestoppt (im Sinne von unterbrochen) wurden (in %)',
          tooltip:
            'Bitte machen Sie Angaben zum Status aller Projekte aus dem letzten Geschäftsjahr',
        },
        deviationProjectCosts: {
          title: 'Abweichungen Kosten',
          tooltip:
            'Bitte schätzen Sie die durchschnittliche Höhe der Abweichungen von Investitionsprojekten hinsichtlich der Dimension Kosten ab.',
        },
        deviationProjectTime: {
          title: 'Abweichungen Zeit',
          tooltip:
            'Bitte schätzen Sie die durchschnittliche Höhe der Abweichungen von Investitionsprojekten hinsichtlich der Dimension Zeit ab.',
        },
        deviationProjectPerformance: {
          title: 'Abweichungen Leistung',
          tooltip:
            'Bitte schätzen Sie die durchschnittliche Höhe der Abweichungen von Investitionsprojekten hinsichtlich der Dimension Leistung ab.',
        },
      },
      riskManagement: {
        title: 'Risikomanagement',
        tooltip: `Das Risikomanagement beinhaltet die Identifikation, Erfassung, 
                  Analyse, Bewertung und Kontrolle von Risiken sowie die Ableitung und Verfolgung 
                  geeigneter Risikoabwehrmaßnahmen. Als Rahmen werden dabei allgemeine 
                  risikopolitische Grundsätze vorgegeben sowie strategische Stoßrichtungen 
                  angewendet.`,
        typeRiskManagement: {
          title: 'Typ des Risikomanagements',
          tooltip:
            'Existiert in Ihrem Unternehmen ein formalisiertes/institutionalisiertes Risikomanagement(system)?',
          options: {
            formalized: 'Formalisiert',
            institutionalized: 'Institutionalisiert',
          },
        },
        responsibilityRiskManagement: {
          title: 'Organisatorische Verankerung des Risikomanagements',
          tooltip: 'Wer verantwortet das Risikomanagement?',
          options: {
            controlling: 'Controlling',
            staffUnit: 'Stabstelle Risikomanagement',
            corporateManagement: 'Unternehmensführung',
            intern: 'Interne Revision/Accounting/Treasury',
            decentralizedEmployees:
              'Dezentrale Mitarbeiter in den verschiedenen Geschäftseinheiten',
          },
        },
        frequenceRiskManagement: {
          title: 'Berichtsfrequenz des Risikomanagements',
          tooltip: 'Wie oft wird über Risiken an die jeweiligen Positionen berichtet?',
          options: {
            adhoc: 'Anlassbezogen/ad hoc',
            daily: 'Täglich',
            weekly: 'Wöchentlich',
            monthly: 'Monatlich',
            quarterly: 'Quartalsweise',
            halfYearly: 'Halbjährig',
            yearly: 'Jährlich',
            never: 'Nie',
          },
        },
        controllingRelevance: {
          title: 'Steuerungsrelevanz des Risikomanagements',
          tooltip:
            'In welchem Maß unterstützt Ihr Risikomanagement als aktives Steuerungsinstrument das Erreichen der Unternehmensziele?',
          options: {
            indepControlInstance: 'Unabhängige Kontrollinstanz',
            indepMediator: 'Unabhäniger Vermittler',
            realBusinessPartner: 'Echter Business Partner',
          },
        },
        measures: {
          title: 'Maßnahmen zur Risikosteuerung',
          tooltip:
            'Erfolgt eine Messung der Angemessenheit und Wirksamkeit von Maßnahmen zur Risikosteuerung?',
          options: {
            rotational:
              'Turnusmäßig im Rahmen des Risikoerfassungsprozesses durch die Risikoeigentümer bewertet',
            regularCentralRiskManagementFunc:
              'Regelmäßig durch die zentrale Risikomanagementfunktion bei den Maßnahmenverantwortlichen hinterfragt',
            regulateRevisionFunc: 'Regelmäßig durch die Revisionsfunktion überprüft',
            noActiveMonitoring: 'Kein aktives Monitoring von Gegenmaßnahmen',
          },
        },
        checkboxGoals: {
          title: 'Ziele des Risikomanagements',
          tooltip:
            'Welches Ziel verfolgt das Risikomanagement in Ihrem Unternehmen? Kreuzen Sie "Ja" an, wenn das jweilige Ziel verfolgt wird, ansonsten "Nein". Möchten Sie keine Angaben machen, kreuzen Sie weder "Ja" noch "Nein" an.',
          options: {
            goalFulfilledRequirement:
              'Erfüllung verpflichtender Anforderungen im Bereich der Risikoberichterstattung',
            goalImprovedControllingRisks: 'Verbesserte Steuerung wesentlicher Risiken',
            goalCompanyControlling:
              'Risikomanagement als aktives Element der Unternehmenssteuerung',
          },
        },
        checkboxIntegration: {
          title: 'Integration des Risikomanagements',
          tooltip:
            'Zu welchen unternehmerischen Steuerungsprozessen bestehen Schnittstellen aus dem Risikomanagement? Kreuzen Sie "Ja" an, wenn Schnittstellen bestehen, ansonsten "Nein". Möchten Sie keine Angaben machen, kreuzen Sie weder "Ja" noch "Nein" an.',
          options: {
            integrationStrategicPlanning: 'Strategische Planung',
            integrationOperativePlanning: 'Mittelfristige Planung/Operative Planung',
            integrationBudgeting: 'Budgetierung',
            integrationStrategicDecisionSupport: 'Strategische Entscheidungsunterstützung',
            integrationOperativeDecisionSupport: 'Operative Entscheidungsunterstützung',
            integrationNoInterfaces: 'Keine Schnittstellen',
          },
        },
        checkboxAnalysis: {
          title: 'Analyse der Risikotragfähigkeit',
          tooltip:
            'Wird die Risikotragfähigkeit des Unternehmens analysiert? Kreuzen Sie "Ja" an, wenn die jeweilige Analyse durchgeführt wird, ansonsten "Nein". Möchten Sie keine Angaben machen, kreuzen Sie weder "Ja" noch "Nein" an.',
          options: {
            analysisNoConcept: 'Kein Konzept zur Risikotragfähigkeit vorhanden',
            analysisEquityCoverage: `Gegenüberstellung der Eigenkapitalabdeckung und der quantitativen
                      Gesamtrisikoposition`,
            analysisLiquidityReserve: `Gegenüberstellung der Liquiditätsreserve und der quantitativen
                      Gesamtrisikoposition`,
            analysisOther:
              'Auswirkung auf sonstige Faktoren (z.B. Rating, Kreditverpflichtungen etc.)',
          },
        },
        checkboxSoftwareSupport: {
          title: 'Softwareunterstützung im Risikomanagement',
          tooltip:
            'Wird ihr Risikomanagement durch eine Softwarelösung unterstützt? Kreuzen Sie "Ja" an, wenn eine Softwarelösung für die jeweilige Tätigkeit verwendet wird, ansonsten "Nein". Möchten Sie keine Angaben machen, kreuzen Sie weder "Ja" noch "Nein" an.',
          options: {
            softwareRiskRecordingRating: 'Zur Risikoerfassung und Bewertung (Workflow)',
            softwareReporting: 'Zur Berichterstattung',
            softwareRiskDashboards: 'Zur adressatengerechten Aufbereitung in Risk-Dashboards',
            softwareSimulationModeling: 'Zur Simulation und Modellierung von Szenarien',
            softwareNoSoftwareSupport: 'Keine Softwareunterstützung im Risikomanagement',
          },
        },
        checkboxScenarioAnalysis: {
          title: 'Szenarioanalysen im Risikomanagement',
          tooltip:
            'Nutzen Sie Szenarioanalysen im Rahmen des Risikomanagements? Kreuzen Sie "Ja" an, wenn eine Szenarioanalyse verwendet wird, ansonsten "Nein". Möchten Sie keine Angaben machen, kreuzen Sie weder "Ja" noch "Nein" an.',
          options: {
            scenarioCurrentRiskDev: `Szenarioanalysen für aktuelle Risiko-Entwicklungen (Brexit, COVID, Embargos
                        etc.)`,
            scenarioIndividualSingleRisks: 'Szenarioanalyse von individuellen Einzelrisiken',
            scenarioTopRisks: 'Szenarioanalyse für die Top-Risiken',
            scenarioCompleteRiskPortfolio: 'Szenarioanalyse für das komplette Risikoportfolio',
            scenarioStrategicDecisionOptions:
              'Szenarioanalysen zur Bewertung strategischer Entscheidungs-Optionen',
          },
        },
      },
      ctrlCorporate: {
        title: 'Konzerncontrolling',
        tooltip: 'Konzerncontrolling',
        leadershipClaim: {
          title: 'Führungsanspruch der Konzernzentrale',
          tooltip:
            'Bitte geben Sie an, welchen überwiegenden Führungsanspruch Ihre Konzernzentrale gegenüber den Konzerneinheiten verfolgt: ',
          options: {
            financialLeadership: 'Finanzieller Führungsanspruch',
            strategicLeadership: 'Strategischer Führungsanspruch',
            operationalLeadership: 'Operativer Führungsanspruch',
            controllingRelevanceKeyFigure: 'Durchgängige Kennzahlenlogik',
            keyFigureLogicAllHierarchyLevels: 'Steuerungsrelevanten Kennzahlen',
            keyFigureCompanyLevel: 'Kennzahldefinition auf Konzernebene',
          },
        },
      },
      researchDevelopment: {
        title: 'F&E Controlling',
        tooltip: `F&E-Controlling umfasst die wesentlichen Controlling-Prozesse, 
                  jeweils angepasst auf die spezifischen Anforderungen und Rahmenbedingungen von 
                  Forschung und Entwicklung. Es beinhaltet den betriebswirtschaftlichen Prozess 
                  der Zielfestlegung, Planung und Steuerung von Forschungs-und Entwicklungsaktivitäten.`,
        percentageShare: {
          title: 'Prozentualer Anteil von F&E am Jahresumsatz (in %)',
          tooltip: 'Anteil des Netto-F&E-Budgets am Jahresumsatz in Prozent',
        },
        resourcesEffort: {
          title: 'Ressourcenaufwand für F&E-Projekte (in EUR)',
          tooltip: 'Wie hoch ist der durchschnittliche Ressourcenaufwand für ein F&E-Projekt?',
        },
        compositionPortfolio: {
          title: 'Zusammensetzung des Portfolios',
          tooltip: `Die Summe der drei folgenden KPIs muss 100% ergeben. Es kann ebenso in jedes
                    Eingabefeld eine 0 eingetragen werden. Dies bedeutet es existieren keine
                    Projekte für das jeweilige Portfolio`,
        },
        compositionPortfolioBasicResearch: {
          title: 'Projekte zur Grundlagenforschung (Vor-Markt-Entwicklung) (in %)',
          tooltip: 'Projekte zur Grundlagenforschung (Vor-Markt-Entwicklung)',
        },
        compositionPortfolioNewProductDev: {
          title: 'Projekte zur Neuproduktentwicklung (in %)',
          tooltip: 'Projekte zur Neuproduktentwicklung',
        },
        compositionPortfolioFurtherDev: {
          title: 'Projekte zur Pflege und Weiterentwicklung von Produkten (in %)',
          tooltip: 'Projekte zur Pflege und Weiterentwicklung von Produkten',
        },
      },
      production: {
        title: 'Produktionscontrolling',
        tooltip: `Das Produktionscontrolling ist der Teil des Controllings, der die 
                  Planung, Kontrolle sowie die Steuerung und Informationsversorgung einzelner 
                  Unternehmensfunktionen in der Produktion zum Inhalt hat.`,
        checkboxKeyData: {
          title: 'Kennzahlen in Produktion und produktionsnahen Bereichen',
          tooltip:
            'Welche Kennzahlen verwenden Sie zur Beurteilung Ihrer Produktion bzw. in den produktionsnahen Bereichen? Kreuzen Sie "Ja" an, wenn die jeweilige Kennzahl verwendet wird, ansonsten "Nein". Möchten Sie keine Angaben machen, kreuzen Sie weder "Ja" noch "Nein" an.',
          options: {
            keyDataDeliveryPunctuality: `Lieferpünktlichkeit (Anzahl termingerechter Lieferungen / Anzahl Lieferungen
                      gesamt)`,
            keyDataIdleTimeMachine: 'Dauer der Brach- / Leerzeit pro Maschine',
            keyDataPlantUtilizationRate: `Anlagenabnutzungsgrad (Summe der AfA (Abschreibungen) auf Anlagen /
                      Anschaffungskosten)`,
            keyDataUtilizationRate: 'Auslastungsgrad der Maschinen und Arbeitsplätze',
            keyDataManufacturingQuality:
              'Fertigungsqualität (Retourmenge / gesamte Fertigungsmenge)',
            keyDataPostProcessingRate:
              'Nachbearbeitungsquote (fehlerhafte Stücke / Summe der gefertigten Erzeugnisse)',
            keyDataScrapRate: 'Ausschussquote (Ausschussmenge / gesamt Produktionsmenge)',
            keyDataDamageFrequency: 'Schadenshäufigkeit (Anzahl Reklamationen / Absatz)',
            keyDataStockBearingManufactory: 'Bestand im Lager und in der Fertigung',
            keyDataSetupTimes: 'Rüstzeiten und Häufigkeit',
            keyDataEmployment: 'Beschäftigung (Ist-Beschäftigung / Plan-Beschäftigung)',
          },
        },
      },
      sales: {
        title: 'Vertriebscontrolling',
        tooltip: `Das Vertriebscontrolling ist der Teil des Controllings, der die 
                  Planung, Kontrolle sowie die Steuerung und Informationsversorgung einzelner 
                  Unternehmensfunktionen im Vetrieb zum Inhalt hat.`,
        percentageShare: {
          title: 'Anteil der Vertriebskosten am Jahresumsatz (in %)',
          tooltip:
            'Bitte geben Sie den Anteil der Vertriebskosten am Umsatz Ihres Unternehmens an.',
        },
      },
      itControlling: {
        title: 'IT-Controlling',
        tooltip: 'IT-Controlling',
        structureIt: {
          title: 'Organisationsstruktur des IT-Bereichs',
          tooltip: 'Bitte geben Sie an, wie ihr IT-Bereich organisatorisch aufgestellt ist?',
          options: {
            costCenter: 'Cost-Center',
            profitCenter: 'Profit-Center',
            serviceCenter: 'Service-Center',
            others: 'Andere',
          },
        },
      },
      participation: {
        title: 'Beteiligungscontrolling',
        tooltip: `Das Beteiligungscontrolling übernimmt die Koordination, Unterstützung und Steuerung der Beteiligungsgesellschaften im Hinblick auf die bestmögliche Erreichung der Gesamtunternehmensziele sowie der Zielvorgaben für einzelne Gesellschafte.`,
      },
      ctrlBusinessConsultingManagement: {
        title: 'Betriebswirtschaftliche Beratung und Führung',
        tooltip:
          'Controller sorgen als Dienstleister mit Ordnungsfunktion dafür, dass die Controlling-Hauptprozesse im Unternehmen zur Anwendung kommen, und gestalten diese im Rahmen der Zielfindung, Planung und Steuerung. Sie unterstützen das Management mit zweckmäßigen Instrumenten und entscheidungsrelevanten Informationen, zeigen die betriebswirtschaftlichen Auswirkungen von Handlungsalternativen auf und schaffen dabei bereichsübergreifend Transparenz bezüglich Strategie, Ergebnis, Finanzen und Prozessen. Damit stehen sie dem Management als „Sparringspartner“  bzw. „kaufmännisches Gewissen“ zur Seite.',
      },
      ctrlFurtherDevelopmentControlling: {
        title: 'Weiterentwicklung des Controllings',
        tooltip:
          'Ziel dieses Hauptprozesses ist es, die im Unternehmen verwendeten Controlling-Prozesse, -Instrumente und -Systeme kontinuierlich weiterzuentwickeln. Insbesondere ist zu prüfen, bei welchen Prozessen Anpassungsbedarf besteht, welche u.U. ganz entfallen können und welche Felder grundsätzlich entwickelt werden müssen. Durch die Gestaltung neuer und die Weiterentwicklung bestehender Controlling-Prozesse, -Strukturen, -Instrumente und -Systeme sollen schließlich die Effektivität und Effizienz des Controllings gesteigert werden.',
      },
      statusProjectOptions: {
        bad: 'Schlechter als geplant',
        standard: 'Planmäßig',
        good: 'Besser als geplant',
        tooltip: `Gesamtheitliche Betrachtung des Finance`,
      },
    },
    evaluation: {
      loading: 'Benchmarking-Daten werden geladen. Bitte haben Sie etwas Geduld.',
      noDataExists: 'Es wurden keine Daten eingereicht.',
      legendText: {
        fte: 'FTE (auf 1000 normiert)',
        costs: 'Anteil der Kosten am Gesamtumsatz (in %)',
      },
      pageHeader: 'Benchmarking Controlling',
      evaluationYear: 'Auswertung des Benchmarkings zum Bereich Controlling des Jahres',
    },
  },
  finance: {
    benchmarking: {
      loading: 'Benchmarking-Daten werden geladen. Bitte haben Sie etwas Geduld.',
      info: 'Benchmarking zum Bereich Controlling des Jahres ',
      sliderOptions: {
        singleProcesses: 'Einzelprozesse',
        totalProcess: 'Gesamtprozess',
      },
      totalProcess: {
        title: 'Gesamtprozess',
        tooltip: 'Aufteilung von Finance in die jeweiligen Prozesse',
      },
      singleProcesses: {
        title: 'Einzelprozesse',
        tooltip: 'Aufteilung des Finance in die jeweiligen Einzelprozesse',
      },
      debtorsProcess: {
        title: 'Debitorenprozesse',
        tooltip: 'Debitorenprozesse',
        debtorsDurationDays: {
          title: 'Dauer eines Debitorenprozesses (in AT)',
          tooltip: 'Wie lange dauert die Abwicklung eines Debitorenprozesses?',
        },
        debtorsPercentageShare: {
          title:
            'Anteil der durch Shared-Service-Center abgewickelten Transaktionsaktivitäten (in %)',
          tooltip:
            'Wie hoch ist der Anteil der durch Shared-Service-Center abgewickelten Transaktionsaktivitäten?',
        },
        debtorsPercentageShareCustomer: {
          title: 'Anteil der EDI angebundenen Kunden (in %)',
          tooltip: 'Wie hoch ist der Anteil der durch EDI angebundenen Kunden?',
        },
        debtorsNumberBill: {
          title: 'Anzahl der Kundenrechnungen pro Jahr',
          tooltip: 'Auswertung wird je FTE in der Debitorenbuchhaltung normalisiert',
        },
        debtorsPercentageShareIncorrectBills: {
          title: 'Anteil der fehlerhaften Rechnungen (in %)',
          tooltip: 'Wie hoch ist der Anteil der fehlerhaften Rechnungen?',
        },
        debtorsNumberBillPosition: {
          title: 'Anzahl der Rechnungspositionen',
          tooltip: 'Auswertung wird je FTE in der Debitorenbuchhaltung normalisiert',
        },
        debtorsTimeSpanUntilBill: {
          title:
            'Anzahl der Arbeitstage vom Ende der Leistungserstellung bis zur Erstellung der Rechnung',
          tooltip:
            'Wie groß ist die Zeitspanne vom Ende der Leistungserstellung bis zur Erstellung der Rechnung?',
        },
        debtorsPercentageShareElecBills: {
          title: 'Anteil der elektronisch versandten Rechnungen (in %)',
          tooltip: 'Wie hoch der Anteil an elektronisch versandten Rechnungen?',
        },
        debtorsPercentageShareBillsReminder: {
          title: 'Anteil der versandten Rechnungen mit anschließendem Mahnverfahren (in %)',
          tooltip:
            'Wie hoch ist der Anteil der versandten Rechnungen mit anschließendem Mahnverfahren?',
        },
        debtorsIncomingPayments: {
          title: 'Anzahl der Zahlungseingänge pro Jahr',
          tooltip: 'Auswertung wird je FTE in der Debitorenbuchhaltung normalisiert',
        },
        debtorsTimeSpanUntilClosingBusinessCase: {
          title: 'Anzahl der Tage zwischen Zahlungseingang und Abschluss des Geschäftsfalles',
          tooltip:
            'Wie lange ist die Zeitspanne zwischen Zahlungseingang und Abschluss des Geschäftsfalles?',
        },
        debtorsIncorrectPaymentAssignment: {
          title: 'Anteil fehlerhafter elektronischer Zahlungszuordnungen (in %)',
          tooltip: 'Wie hoch ist der Anteil fehlerhafter elektronischer Zahlungszuordnungen?',
        },
        debtorsReceivablesTurnoverTime: {
          title: 'Höhe der durchschnittlichen Forderungsumschlagsdauer (in AT)',
          tooltip: 'Wie hoch ist die durchschnittliche Forderungsumschlagsdauer?',
        },
        debtorsOutsourcedReceivables: {
          title: 'Anteil der ausgelagerten Forderungen (in %)',
          tooltip: 'Wie hoch ist der Anteil an Forderungen, die an dritte ausgelagert werden?',
        },
      },
      creditorsProcess: {
        title: 'Kreditorenprozesse',
        tooltip: 'Kreditorenprozesse',
        creditorsDurationProcessingCreditors: {
          title: 'Anzahl der Arbeitstage zur Abwicklung eines Kreditorenprozesses',
          tooltip: 'Wie lange dauert die Abwicklung eines Kreditorenprozesses?',
        },
        creditorsIncorrectOrders: {
          title: 'Anteil der fehlerhaften Bestellungen (in %)',
          tooltip: 'Wie hoch ist der Anteil der fehlerhaften Bestellungen?',
        },
        creditorsSuppliersEdi: {
          title: 'Anteil der EDI-angebundenen Lieferanten (in %)',
          tooltip: 'Wie hoch ist der Anteil der EDI-angebundenen Lieferanten?',
        },
        creditorsNumberOrders: {
          title: 'Anzahl der Bestellungen pro Jahr',
          tooltip: 'Auswertung wird je FTE in der Kreditorenbuchhaltung normalisiert',
        },
        creditorsNumberOrderPositions: {
          title: 'Anzahl der Rechnungspositionen',
          tooltip: 'Auswertung wird je FTE in der Kreditorenbuchhaltung normalisiert',
        },
        creditorsAutomatedOrderEntry: {
          title: 'Anteil der Produkte mit automatischer Bestellerfassung (in %)',
          tooltip: 'Wie hoch ist der Anteil der Produkte mit automatischer Bestellerfassung?',
        },
        creditorsElectrSentOrders: {
          title: 'Anteil der elektronisch versandten Bestellungen (in %)',
          tooltip: 'Wie hoch ist der Anteil der elektronisch versandten Bestellungen?',
        },
        creditorsNumberBills: {
          title: 'Anzahl der Rechnungen pro Jahr',
          tooltip: 'Auswertung wird je FTE in der Kreditorenbuchhaltung normalisiert',
        },
        creditorsDifferenceBillsOrders: {
          title: 'Anteil der Rechnungen/Bestellungen, die nicht übereinstimmen (in %)',
          tooltip: 'Wie hoch ist der Anteil der Rechnungen/Bestellungen, die nicht übereinstimmen',
        },
        creditorsBillWithoutOrder: {
          title: 'Anteil der Rechnungen, für die keine Bestellung vorliegt (in %)',
          tooltip: 'Wie hoch ist der Anteil der Rechnungen, für die keine Bestellung vorliegt?',
        },
        creditorsIncorrectPaymentsCreditors: {
          title: 'Anteil der fehlerhaften Zahlungen Kreditoren (in %)',
          tooltip: 'Wie hoch ist der Anteil der fehlerhaften Zahlungen Kreditoren?',
        },
        creditorsTimeSpanBillincomingPayment: {
          title: 'Anzahl der Arbeitstage zwischen Rechnungseingang und Zahlung',
          tooltip: 'Wie groß ist die Zeitspanne zwischen Rechnungseingang und Zahlung?',
        },
      },
      finChargesTravel: {
        title: 'Entgelt- und Reisekostenabrechnung',
        tooltip: 'Entgelt- und Reisekostenabrechnung',
        finChargesTravelPayroll: {
          title: 'Anzahl der Entgeltabrechnungen',
          tooltip: 'Anzahl der Entgeltabrechnungen',
        },
        finChargesTravelEmployees: {
          title: 'Anzahl der Mitarbeiter, für die Entgeltabrechnungen erstellt werden',
          tooltip: 'Anzahl der Mitarbeiter, für die Entgeltabrechnungen erstellt werden ',
        },
        finChargesTravelIncorrectPayrolls: {
          title: 'Anteil der Entgeltabrechnungen, bei denen Fehler entdeckt wurden (in %)',
          tooltip: 'Bei wievielen Entgeltabrechnungen wurden Fehler entdeckt?',
        },
        finChargesTravelTravelExpenseReport: {
          title: 'Anzahl der Reisekostenabrechnungen',
          tooltip: 'Anzahl der Reisekostenabrechnungen',
        },
        finChargesTravelProcessingDays: {
          title: 'Anzahl der Arbeitstage zur Abwicklung einer Reisekostenabrechnung',
          tooltip: 'Wie lange dauert die Abwicklung einer Reisekostenabrechnung in AT?',
        },
      },
      assetMgtProcess: {
        title: 'Anlagebuchhaltung',
        tooltip: 'Anlagebuchhaltung',
        assetMgtAssetsMasterData: {
          title: 'Anzahl der Anlagen mit Stammdaten',
          tooltip: 'Anzahl der Anlagen mit Stammdaten',
        },
        assetsMgtAssetsBookedProcessed: {
          title: 'Anzahl der verarbeiteten / gebuchten Anlagepositionen pro Jahr',
          tooltip: 'Anzahl der verarbeiteten / gebuchten Anlagepositionen pro Jahr',
        },
      },
      consolidationProcess: {
        title: 'Hauptbuchhaltung / Konsolidierung',
        tooltip: 'Hauptbuchhaltung / Konsolidierung',
        consolJournalEntries: {
          title: 'Anteil der automatisierten Journaleintragungen (in %)',
          tooltip: 'Wie viele Journaleintragungen geschehen automatisch?',
        },
        consolDaysMonthlyClosing: {
          title: 'Anzahl der Arbeitstage bis zum Monatsabschluss',
          tooltip: 'Wie viel Zeit wird für den Monatsabschluss benötigt?',
        },
        consolDaysMonthlyClosingReleaseExternReporting: {
          title:
            'Anzahl der Arbeitstage vom Monatsabschluss bis zur Freigabe des externen Reportings',
          tooltip:
            'Wie viel Zeit vergeht vom Monatsabschluss bis zur Freigabe des externen Reportings?',
        },
        consolDaysQuarterlyReport: {
          title: 'Anzahl der Arbeitstage bis zum Quartalsabschluss',
          tooltip: 'Wie viel Zeit wird für den Quartalsabschluss benötigt?',
        },
        consolDaysQuarterlyReportReleaseExternReporting: {
          title:
            'Anzahl der Arbeitstage vom Quartalsabschluss bis zur Freigabe des externen Reportings',
          tooltip:
            'Wie viel Zeit vergeht vom Quartalsabschluss bis zur Freigabe des externen Reportings?',
        },
        consolDaysAnnualReport: {
          title: 'Anzahl der Arbeitstage bis zum Jahresabschluss',
          tooltip: 'Wie viel Zeit wird für den Jahresabschluss benötigt?',
        },
        consolDaysAnnualReportReleaseExternReporting: {
          title:
            'Anzahl der Arbeitstage vom Jahresabschluss bis zur Freigabe des externen Reportings',
          tooltip:
            'Wie viel Zeit vergeht vom Jahresabschluss bis zur Freigabe des externen Reportings?',
        },
        consolNumberConsolidatedLegalEntities: {
          title: 'Anzahl der zu konsolidierenden Legaleinheiten',
          tooltip: 'Wie viele Legaleinheiten müssen konsolidiert werden?',
        },
        consolFteConsolidation: {
          title: 'FTE in Konsolidierung',
          tooltip: 'Wie viele Mitarbeiter wickeln die Konsolidierung ab?',
        },
        consolCostConsolidation: {
          title: 'Kosten Konsolidierung (in EUR)',
          tooltip: 'Was sind die jährlichen Kosten für die Konsolidierung?',
        },
        consolCompletionDataReporting: {
          title: 'Abschluss der Datenmeldung bis (AT)',
          tooltip:
            'Bis zu welchem Arbeitstag (AT) muss die Datenmeldung Ihrer lokalen Gesellschaften abgeschlossen sein?',
        },
        consolAnnualCompletion: {
          title: 'Jahresabschluss - Ferstigstellung am (AT)',
          tooltip:
            'An welchem Arbeitstag (AT) muss der Jahresabschluss der Gruppe fertiggestellt sein',
        },
        consolQuarterlyCompletion: {
          title: 'Quartalsabschluss - Ferstigstellung am (AT)',
          tooltip:
            'An welchem Arbeitstag (AT) muss der Quartalsabschluss der Gruppe fertiggestellt sein',
        },
        consolMonthlyCompletion: {
          title: 'Monatsabschluss - Ferstigstellung am (AT)',
          tooltip:
            'An welchem Arbeitstag (AT) muss der Monatsabschluss der Gruppe fertiggestellt sein',
        },
        consolExtraLoadManualActivity: {
          title: 'Mehrbelastung durch manuelle Aktivitäten',
          tooltip: 'Mehrbelastung durch manuelle Aktivitäten',
          options: {
            veryHigh: 'Sehr hoch',
            significant: 'Deutlich',
            noticeable: 'Spürbar',
            none: 'Gar keine',
          },
        },
        consolQualityDataReporting: {
          title: 'Qualität der Datenmeldung lokaler Meldeeinheiten',
          tooltip: 'Qualität der Datenmeldung lokaler Meldeeinheiten',
          options: {
            veryPoor: 'Sehr schlecht',
            unsatisfactory: 'Unbefriedigend',
            ok: 'OK',
            veryGood: 'Sehr gut',
          },
        },
        consolQualityVotingProcess: {
          title: 'Qualität des IC-Abstimmungsprozesses',
          tooltip: 'Qualität des IC-Abstimmungsprozesses',
          options: {
            veryPoor: 'Sehr schlecht',
            unsatisfactory: 'Unbefriedigend',
            ok: 'OK',
            veryGood: 'Sehr gut',
          },
        },
        consolIntegrationLegalManagement: {
          title: 'Integration ihrer legalen und der Management-Sichten',
          tooltip: 'Integration ihrer legalen und der Management-Sichten',
          options: {
            none: 'Keine Integration',
            little: 'Wenig Integration',
            dataModel: 'Integriertes Datenmodell',
            fully: 'Vollständig integriert',
          },
        },
        consolGenerationConsolidatedNumbers: {
          title: 'Erzeugung konsolidierter Zahlen',
          tooltip: 'Erzeugung konsolidierter Zahlen',
          options: {
            one: 'An einer Stelle',
            two: 'An zwei Stelle',
            more: 'An mehr als zwei Stellen',
            noAnswer: 'Kann nicht beantwortet werden',
          },
        },
        consolChallengeGroupAccounting: {
          title: 'Größte Herausforderung im Group Accounting',
          tooltip: 'Größte Herausforderung im Group Accounting',
          options: {
            IFRS: 'IFRS Standards umsetzen',
            moreInfo: 'Mehr Informationen bereitstellen',
            euTax: 'EU-Taxonomie',
            closingDates: 'Vorgezogene Abschlusszeitpunkte',
            processAutomation: 'Prozessautomatisierung',
            newSkills: 'Neue Skills aufbauen',
          },
        },
      },
      finTaxProcess: {
        title: 'Steuern',
        tooltip: 'Steuern',
        finTaxReportingUnit: {
          title: 'Anzahl Meldeeinheiten',
          tooltip: 'Anzahl der vom Steuerbereich zu verantwortenden Meldeeinheiten im Konzern',
        },
        finTaxTaxDeclaration: {
          title: 'Anzahl Arbeitstage Steuererklärung',
          tooltip: 'Wie viele Arbeitstage werden zur Erstellung einer Steuererklärung benötigt?',
        },
        finTaxTaxDeclarationBalance: {
          title: 'Anzahl Arbeitstage E-Bilanz',
          tooltip: 'Wie viele Arbeitstage werden zur Erstellung einer E-Bilanz benötigt?',
        },
      },
      finTreasuryProcess: {
        title: 'Treasury',
        tooltip: 'Treasury',
        treasuryAvgExternPaymentTransactions: {
          title: 'Anzahl externer Zahlungs-Transaktionen',
          tooltip: 'Durchschnittliche Anzahl der externen Zahlungs-Transaktionen pro Monat',
        },
        treasuryAvgExternManualPayments: {
          title: 'Anzahl der externen manuellen Zahlungen (pro Monat)',
          tooltip: 'Durchschnittliche Anzahl der externen manuellen Zahlungen pro Monat',
        },
        treasuryAvgInternPayments: {
          title: 'Anzahl der internen Zahlungen (pro Monat)',
          tooltip: 'Durchschnittliche Anzahl der internen Zahlungen pro Monat',
        },
        treasuryEmployeesPaymentTransactions: {
          title: 'Anzahl Mitarbeiter im Zahlungsverkehr',
          tooltip: 'Anzahl der Mitarbeiter für die Abwicklung des Zahlungsverkehrs',
        },
        treasuryFinanceTransactionsConcluded: {
          title: 'Anzahl abgeschlossener Treasury-Finanzgeschäfte (pro Monat)',
          tooltip: 'Anzahl monatlich neu abgeschlossene Treasury-Finanzgeschäfte',
        },
        treasuryInventoryTreasuryFinanceTransactions: {
          title: 'Bestand Treasury-Finanzgeschäfte (pro Monat)',
          tooltip: 'Bestand Treasury-Finanzgeschäfte (durchschnittlich)',
        },
        treasuryAutomatedProcessingTreasuryTransactions: {
          title: 'Automatisierte Abwicklung der Treasury-Finanzgeschäfte (in %)',
          tooltip:
            'Wie viele der im Bestand befindlichen Treasury-Finanzgeschäfte werden vollautomatisiert abgewickelt (Zahlung, Accounting (Bewertung, Abgrenzung, Buchung), EMIR-Meldung)?',
        },
        treasuryEmployeesTreasuryFinanceTransactionsCompleted: {
          title: 'Anzahl Mitarbeiter für den Abschluss in Treasury-Finanzgeschäften',
          tooltip: 'Anzahl der Mitarbeiter für den Abschluss von Treasury-Finanzgeschäften',
        },
        treasuryEmployeesProcessingTreasuryFinanceTransactions: {
          title: 'Anzahl Mitarbeiter für die Abwicklung in Treasury-Finanzgeschäften',
          tooltip: 'Anzahl der Mitarbeiter für die Abwicklung von Treasury-Finanzgeschäften',
        },
        treasuryEfficiency: {
          title: 'Effizienz Kontoauszugsverarbeitung (in %)',
          tooltip:
            'Wie hoch ist die Quote der automatisch ausgezifferten offenen Posten infolge der verarbeitung elektronischer Kontoauszüge?',
        },
        treasuryLiquidityPlanningHorizonMonths: {
          title: 'Planungshorizont Liquiditätsplanung (in Monaten)',
          tooltip: 'Über welchen Zeitraum wird die Liquditätsposition unternehmensweit geplant?',
        },
        treasuryLiquidityPlanningFrequenceMonths: {
          title: 'Planungsfrequenz Liquiditätsplanung (in Monaten)',
          tooltip:
            'In welchen zeitlichen Abständen wird die Liquiditätsplanung unternehmensweit aktualisiert?',
        },
        treasuryLiquidityPlanningDurationDays: {
          title: 'Planungsdauer Liquiditätsplanung (in AT)',
          tooltip:
            'Wie viele Tage werden zur Erstellung der unternehmensweiten Liquiditätsplanung benötigt?',
        },
        treasuryNumberEmployeesLiquidityPlanning: {
          title: 'Anzahl Mitarbeiter für die Liquiditätsplanung',
          tooltip: 'Anzahl an Liquiditätsplanung beteiligter Mitarbeiter',
        },
        treasuryAccuracyLiquidityPlanning: {
          title: 'Genauigkeit Liquiditätsplanung (in %)',
          tooltip:
            'Wie hoch ist die durchschnittliche Abweichung (Plan/Ist) bei der Liquditätsplanung (Ist gegenüber Plan -3Monate)',
        },
      },
      finFurtherDevelopmentFinance: {
        title: 'Weiterentwicklung von Finance',
        tooltip:
          'Ziel dieses Hauptprozesses ist es, die im Unternehmen etablierten Finance-Prozesse, -Instrumente und -Systeme kontinuierlich unter Berücksichtigung unternehmenspolitischer respektive gesetzlich regulatorischer Anforderungen weiterzuentwickeln. Durch die Gestaltung neuer und die Weiterentwicklung bestehender Finance-Prozesse, -Strukturen, -Instrumente und -Systeme sollen schließlich die Effektivität und Effizienz des Finance gesteigert werden. Zur Sicherstellung einer optimal ausgerichteten Finance-Organisation sind eine hohe und fehlerfreie Prozessautomatisierung und -standardisierung fortlaufend sicherzustellen.',
      },
      saveButtonText: 'Benchmarking Zwischenspeichern',
      saveSuccessMessage: { title: 'Änderungen erfolgreich gespeichert', description: '' },
      changeInfoDialogText:
        'Eine Änderung an den FTE und den Gesamtkosten setzt alle Daten in den Einzelprozessen und die korespondierenden KPIs auf 0!',
    },
    evaluation: {
      loading: 'Benchmarking-Daten werden geladen. Bitte haben Sie etwas Geduld.',
      noDataExists: 'Es wurden keine Daten eingereicht.',
      legendText: {
        fte: 'FTE (auf 1000 normiert)',
        costs: 'Anteil der Kosten am Gesamtumsatz (in %)',
      },
      pageHeader: 'Benchmarking Finance',
      evaluationYear: 'Auswertung des Benchmarkings zum Bereich Finance des Jahres',
    },
  },
};

export default translations;
