/**
 * English translations
 */
const translations = {
  global: {
    costs: 'Total costs in Euros',
    costsStat: 'Costs',
    fte: 'FTE',
    totalRevenue: 'Total revenue in Euros',
    numberEmployees: 'Number of employees',
    page: 'page',
    of: 'of',
    previousPageButton: 'previous',
    nextPageButton: 'next',
    okButton: 'OK',
    confirmButton: 'Confirm',
    closeButton: 'Close',
    cancelButton: 'Cancel',
    academicTitleOptions: {
      bachelor: 'Bachelor',
      master: 'Master',
      diplom: 'Diplom',
      doctor: 'Doctor',
      professor: 'Professor',
    },
    appelationOptions: {
      mr: 'Mr',
      mrs: 'Ms',
    },
    multipleChoiceOptions: {
      yes: 'Yes',
      no: 'No',
    },
    roleOptions: {
      admin: 'ADMIN',
      user: 'USER',
      contact: 'CONTACT',
      inactive: 'INACTIVE',
    },
    subscriptionOptions: {
      free: 'Free',
      smart: 'Smart',
      premium: 'Premium',
      flex: 'Flex',
    },
    countryCodeOptions: {
      de: 'Germany',
      at: 'Austria',
      ch: 'Switzerland',
      hun: 'Hungary',
      oth: 'Other Country',
    },
    quartileChartDescription: {
      firstQuartile: '1. Quartile',
      median: 'Median',
      thirdQuartile: '3. Quartile',
    },
  },
  login: {
    title: 'Login',
    email: 'Email Address',
    password: 'Password',
    registerButton: 'Registration',
    loginButton: 'Login',
    resetPassword: 'Reset Password',
    userNotVerifiedInfo:
      'Your account is not yet verified. Please check your emails for a verification link. If you did not receive an email, please click on the button below.',
    resendVerificationMail: 'Resend verification email',
    resendVerificationMailModal: {
      title: 'Resend verification email',
      email: 'Email Address',
      resendVerificationMailButton: 'Resend',
    },
    resendVerificationMailSuccess: {
      title: 'Verification email sent',
      description: 'You will receive an email from us shortly.',
    },
  },
  notFound: {
    content: 'Page Not Found',
  },
  registration: {
    personalInformation: 'Personal Information',
    subscription: 'Membership',
    success: 'Success',
    heading: 'Registration',
    salutation: 'Salutation',
    academicTitle: 'Academic Title',
    firstName: 'First Name',
    lastName: 'Last Name',
    position: 'Position',
    department: 'Unit / Department',
    headingRegisterWithEmail:
      'Please register with the email address of your company, only then you can be assigned.',
    email: 'Email Address',
    repeatEmail: 'Repeat Email Address',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    registrationButton: 'Registration',
    dataProtectionText:
      'I consent to the storage and processing of my personal data for the selected purposes and agree that Horváth may collect, store, process and use the personal data I have provided. Data processing beyond the stated purposes will only take place if there is a legitimate interest within the meaning of the GDPR or if data processing is required by law. I can revoke my consent to the processing of my data at any time (by e-mail to mcs@horvath-partners.com). You can find details in our ',
    dataProtection: 'Privacy Policy',
    infoModal: {
      title: 'Registration successful',
      infoText1:
        'You will receive a verification email and after verification of your data your account will be activated.',
      infoText2: 'This can take up to 2 working days.',
      logIn: 'Back to login',
      promiseTitle: 'User is registered...',
      promiseText: 'Please wait a short time until the registration process is completed...',
      confirmButton: 'OK',
    },
    companyAlreadyRegistered: 'My company is already registered with the CFO-Panel',
    companyNotRegistered: 'My company is not yet registered with the CFO-Panel',
    subscriptionCards: {
      free: 'Free',
      onRequest: 'On Request',
      networkSubTitle: 'Network',
      knowledgeSubTitle: 'Knowledge',
      benchmarkingSubTitle: 'Benchmarking',
      chooseSubscription: 'Choose Membership',
      wholePanel: 'Full panel resource benchmarking via WebApp',
      cfoMeetings: 'Free participation in the CFO study and CFO panel meetings',
      cfoMeetingData: 'Receipt of CFO panel meeting documents',
      digitalMaturity: 'Participation in the Digital Maturity Assessment',
      aiDataConference: 'Free participation AI & Data Conference',
      invitation: 'Automatic invitation to CFO study and CFO panel meetings',
      peerGroup: 'Peer group resource benchmarking (incl. comprehensive PPT report)',
      cfoStudyResults: 'Receipt of full CFO study report',
      workshop: 'Half-day results analysis workshop',
      participationText: '* for participation in the CFO Panel Meeting',
      orderButtonFree: 'Register as a free member',
      orderButtonPaid: 'Order with costs',
      orderButtonFlex: 'Request Flex-Membership',
      orderInfoFree:
        'I hereby apply for a free membership in the Horváth CFO Panel. A change to other memberships is possible at any time.',
      orderInfoPay:
        'I hereby apply for a paid membership of the Horváth CFO Panel. Membership lasts for one year and is then automatically renewed. Cancellation is possible before the end of the year by e-mail to cfo-panel@horvath-partners.com.',
      orderInfoFlex:
        'I hereby request a Flex membership in the Horváth CFO Panel. This step does not constitute a subscription but merely a request for Flex membership. We will contact you immediately and send you an offer.',
    },
    payment: {
      paymentInformation: 'Payment Information',
      paymentSubmission: 'Submit Payment Information',
      companyNameLaw: 'Company name incl. legal form',
      streetNamePostbox: 'Street/No or Postbox',
      zipCode: 'ZIP Code',
      city: 'City',
      country: 'Country',
      extendedBillingInformation: {
        text: 'Do we receive an internal order from you for the invoice (e.g. order number)?',
        yes: 'Yes',
        no: 'No',
      },
      internalPurchaseOrderText: 'Internal Purchase Order',
      offersText: 'We would be very pleased to inform you about further offers from Horváth.',
      exclusiveContent:
        'I agree that Horváth will keep me informed about further exclusive content, interesting invitations, attractive offers and benefits of a professional competence network.',
      cLevelContent:
        'I agree to Horváth informing me regularly about exclusive C-level content relating to transformation and corporate management (Horváth CxO News).',
      news: 'I agree that Horváth will inform me regularly about current topics, trends and dates on the subject of people (change, HR consulting, training) (e-news “food for thought”).',
    },
  },
  verifyUser: {
    success: {
      heading: 'Successfully verified!',
      contentHeading: 'Welcome to the CFO Panel',
      contentBody1: 'Your registration was successfully verified',
      contentBody2:
        'As soon as your account has been activated, you can log in with your e-mail address and the password you have chosen.',
      loginButton: 'Click here to login',
    },
    noValidToken: {
      heading: 'Verification failed!',
      content: 'Your registration could not be completed.',
    },
    expiredToken: {
      heading: 'Verification failed!',
      contentBody1: 'Your registration could not be completed because your token has expired.',
      contentBody2: 'Please use the following link to request a new token.',
      resendTokenButton: 'Request a new token',
      infoModal: {
        title: 'New verification email sent',
        infoText: 'You will receive a new verification email.',
      },
    },
  },
  resetPassword: {
    successResetPassword: {
      title: 'Password successfully reset!',
      description: '',
    },
    inputPasswordHeading: 'Enter password',
    inputPasswordBody: 'Please enter your new password',
    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',
    submitButton: 'Submit',
    noValidToken: {
      heading: 'Verification failed!',
      body: 'You do not have a valid token.',
    },
    expiredToken: {
      heading: 'Verification failed!',
      body1: 'Resetting their password cannot be performed because their token has expired.',
      body2: 'Please use the following link to request a new token.',
      requestNewTokenButton: 'Request new token',
    },
  },
  verifyEmail: {
    heading: 'Email verification',
    body: `Please enter your company's email address for resetting your password. You will then receive an email from us and can reset your password via the link contained therein.`,
    email: 'E-mail address',
    submitButton: 'Submit',
    success: {
      title: 'Email was verified',
      description: 'You will receive an email from us shortly.',
    },
  },
  landingPage: {
    pictureTop: 'Benchmarking',
    pictureMiddleLeft: {
      first: 'Virtual CFO-Panel',
      second: 'Meeting',
    },
    pictureMiddleRight: 'Horváth Events',
    pictureBottomLeft: {
      first: 'Digital Maturity',
      second: 'Assessment',
    },
    pictureBottomRight: 'CFO-Study',
  },
  benchmarkings: {
    title: 'Benchmarkings',
    createBenchmarkingButton: 'Create new benchmarking',
    labelControlling: 'Controlling',
    labelFinance: 'Finance',
    success: {
      title: 'Benchmarking successfully created',
      description: '',
    },
  },
  benchmarkingOverview: {
    successSaveChanges: {
      title: 'Changes saved successfully',
      description: '',
    },
    successSubmitBenchmarking: {
      title: 'Benchmarking successfully submitted',
      description: '',
    },
    pageHeader: 'Benchmarking-Overview',
    overviewBenchmarkings1: 'Overview of benchmarks for the year',
    overviewBenchmarkings2:
      'There is the possibility of a benchmarking to the area of controlling and finance.',
    inputEmployees: 'Please indicate the number of employees.',
    inputRevenue: 'Please indicate the total turnover.',
    saveButton: 'Save',
    processtitle: 'Process Overview',
    labelControlling: 'Controlling',
    labelFinance: 'Finance',
    submitBenchmarking: 'Submit Benchmarking',
    evaluationBenchmarking: 'Benchmarking Evaluation',
    warningSubmitBenchmarking:
      'The evaluation will only be released once the benchmarking has been submitted.',
    questionSubmitBenchmarking: 'Do you really want to submit this benchmark?',
    submitButton: 'Submit',
    warningAfterSubmit1: 'When you submit this benchmark, you can not do',
    warningAfterSubmit2: 'any changes',
    warningAfterSubmit3: 'to it!',
  },
  user: {
    changeEmail: {
      title: 'Email address changed!',
      description: 'Please log in with your new email address.',
    },
    saveChanges: {
      title: 'Changes saved',
      description: '',
    },
    changePassword: {
      title: 'Password successfully changed!',
      description: '',
    },
    userCard: {
      title: 'User',
      editButton: 'Edit',
      changePasswordButton: 'Change Password',
      salutation: 'Salutation',
      academicTitle: 'Acad. Title',
      firstName: 'First Name',
      lastName: 'Last Name',
      position: 'Position',
      department: 'Department',
      email: 'E-Mail',
      subscription: 'Subscription',
      changeSubscription: 'Change Subscription',
      dialog: {
        editUser: 'Edit User',
        save: 'Save',
        changePassword: 'Change Password',
        confirm: 'Confirm',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        repeatNewPassword: 'Repeat new password',
      },
      pageHeader: 'User settings',
      subHeading: `Overview of the logged in user's account.`,
    },
    companyCard: {
      title: 'Company',
      completeName: 'Complete Name',
      shortName: 'Short Name',
      country: 'Country',
      industryLvl1: 'Industry level 1',
      industryLvl2: 'Industry level 2',
      industryLvl3: 'Industry level 3',
    },
    changeSubscription: {
      dialog: {
        title: 'Change Subscription',
        submit: 'Order',
      },
      successChangeSubscription: {
        title: 'Subscription successfully changed',
        description: '',
      },
    },
  },
  adminSettings: {
    userButton: 'User',
    companyButton: 'Company',
  },
  adminUserOverview: {
    pageHeader: 'Overview of all Users',
    successDeleteUser: {
      title: 'User successfully deleted',
      description: '',
    },
    deletePopver: {
      headerText: 'Delete user',
      confirmationText: 'Do you really want to delete the user?',
    },
    tableHeader: {
      firstName: 'First Name',
      lastName: 'Last Name',
      role: 'Role',
      email: 'Email',
      company: 'Company',
      department: 'Unit / Department',
      verified: 'Verified',
      lastLogin: 'Last Login',
      subscriptionType: 'Desired Subscription',
      extendedCompanyName: 'Full Company Name',
      companyAddress: 'Address',
      zipCode: 'ZIP',
      city: 'City',
      country: 'Country',
      internalPurchaseOrder: 'Internal Purchase Order',
      allowExclusiveContent: 'Exclusive Content',
      allowClevelContent: 'C-Level Content',
      allowNews: 'News',
    },
    searchBar: {
      placeholder: 'Search user...',
    },
    resendVerificationMailSuccess: {
      title: 'Verification email sent',
      description: 'The specified user will receive an email shortly.',
    },
    resendVerificationMailError: {
      title: 'Verification email could not be sent',
      description: 'An error occured while trying to send the verification email.',
    },
  },
  adminEditUserModal: {
    successSaveEditUserData: {
      title: 'Changes saved',
      description: '',
    },
    popover: {
      header: 'Confirmation',
      body: 'Do you really want to assign the Admin role?',
    },
    dialog: {
      title: 'Edit User',
      saveButton: 'Save',
      labelRole: 'Role',
      labelCompany: 'Company',
    },
  },
  adminEditCompanyModal: {
    successSaveEditCompanyData: {
      title: 'Changes saved',
      description: '',
    },
    popover: {
      header: 'Confirmation',
      body: 'Do you really want to change the subscription?',
    },
    dialog: {
      title: 'Edit Company',
      saveButton: 'Save',
      labelCompleteName: 'Complete Name',
      labelShortName: 'Short Name',
      labelCountry: 'Country',
      labelIndustryLvl1: 'Industry level 1',
      labelIndustryLvl2: 'Industry level 2',
      labelIndustryLvl3: 'Industry level 3',
      labelSubscription: 'Subscription',
    },
  },
  adminCompanyOverview: {
    pageHeader: 'Overview of all Companies',
    successDeleteCompany: {
      title: 'Company successfully deleted',
      description: '',
    },
    deletePopver: {
      headerText: 'Delete Company',
      confirmationText: 'Do you really want to delete the company?',
    },
    tableHeader: {
      completeName: 'Complete Name',
      shortName: 'Short Name',
      industryLvl1: 'Industry level 1',
      industryLvl2: 'Industry level 2',
      industryLvl3: 'Industry level 3',
      country: 'Country',
      subscription: 'Subscription',
    },
    searchBar: {
      placeholder: 'Search company...',
    },
  },
  components: {
    errorStat: {
      errorHeading: 'An error has occurred',
      updateText: 'Update',
    },
    addCompanyModal: {
      addButton: 'Add Company',
      dialog: {
        title: 'Add Company',
        save: 'Save',
        completeName: 'Complete Name',
        shortName: 'Short Name',
        industryLvl1: 'Industry level 1',
        industryLvl2: 'Industry level 2',
        industryLvl3: 'Industry level 3',
        country: 'Country',
        subscription: 'Subscription',
      },
    },
  },
  version: 'Version',
  countries: {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, The Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte D'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GR: 'Greece',
    GD: 'Grenada',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HUN: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: "Korea, Democratic People's Republic of",
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia, The Former Yugoslav Republic of',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MR: 'Mauritania',
    MU: 'Mauritius',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States of',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PL: 'Poland',
    PT: 'Portugal',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan, Province of China',
    TJ: 'Tajikistan',
    TZ: 'Tanzania, United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    OTH: 'Other Country',
  },
  errors: {
    app: {
      403: {
        title: 'Access denied',
        description: 'You do not have the necessary rights to access this page.',
      },
    },
    login: {
      401: { title: 'Login Failed', description: 'Access data is incorrect' },
      403: {
        title: 'Account needs to be activated',
        description:
          'Your account needs to be checked and activated by an administrator. This can take up to 2 working days.',
      },
      resendVerificationMail: {
        title: 'Resend verification email failed',
        description: 'Please check the email address you entered.',
      },
    },
    logout: {
      logoutFailed: {
        title: 'An error has occurred',
        description: '',
      },
    },
    registration: {
      409: {
        title: 'This email address is already in use.',
        description: '',
      },
      salutation: 'Please enter a salutation.',
      firstName: 'Please enter a last name.',
      lastName: 'Please enter a last name.',
      invalidEmail: 'Please enter a valid email address.',
      emailsNotMatch: 'The entered email addresses do not match.',
      invalidPassword: 'Please enter a password with min. 8 characters.',
      passwordsNotMatch: 'The entered passwords do not match.',
      fieldCanNotBeEmpty: 'This field can not be empty',
      errorModal: {
        title: 'An error has occurred',
        infoText: 'Your request could not be processed.',
      },
    },
    benchmarkings: {
      createBenchmarkingFailed: 'Creation failed',
      description: '',
    },
    benchmarkingOverview: {
      fetchBenchmarkingDataFailed: {
        title: 'Benchmarking data could not be loaded',
        description: '',
      },
      fetchCompanyDataFailed: {
        title: 'Company data could not be loaded',
        description: '',
      },
      saveBenchmarkingFailed: {
        title: 'Saving failed',
        description: '',
      },
      errorSaveInputsEmpty: {
        title: 'Saving failed',
        description:
          'For saving, the two input fields "Total revenue in Euro" and "Number of employees" must be filled in.',
      },
      submitBenchmarkingFailed: {
        title: 'Submission failed',
        description: '',
      },
      errorSubmitInputsEmpty: {
        title: 'Saving failed',
        description:
          'In order to submit the benchmarks, it is necessary to fill in the two input fields "Total revenue" and "Number of employees".',
      },
    },
    user: {
      fetchUserDataFailed: {
        title: 'User data could not be loaded',
        description: '',
      },
      fetchCompanyDataFailed: {
        title: 'Company data could not be loaded',
        description: '',
      },
      logout: {
        title: 'An error has occurred',
        description: '',
      },
      saveChangesFailed: {
        title: 'Saving failed',
        description: '',
      },
      changePasswordFailed: {
        title: 'Change password failed',
        description: '',
      },
      changePassword: {
        oldPasswordInvalid: 'Your current password must have at least 8 characters.',
        newPasswordInvalid: 'Please enter a password with min. 8 characters.',
        passwordsNotMatch: 'The passwords entered do not match.',
        distinguishNewFromOld: 'The new password should be different from the old password.',
      },
      changeSubscriptionFailed: {
        title: 'Change subscription failed',
        description: '',
      },
    },
    adminUserOverview: {
      fetchDataFailed: {
        title: 'Companies could not be loaded',
        description: '',
      },
      deleteYourselfNotPossible: {
        title: 'Users cannot delete themselves!',
        description: '',
      },
      deleteUserFailed: {
        title: 'Delete failed',
        description: '',
      },
    },
    adminEditUserModal: {
      removeAdminRoleFailed: {
        title: 'An admin cannot remove himself from the admin role',
        description: '',
      },
      editRoleFailed: {
        title: 'Saving failed',
        description: '',
      },
      saveEditUserDataFailed: {
        title: 'Saving failed',
        description: '',
      },
      errorModal: {
        title: 'User is not verified yet!',
        infoText:
          'The user has not yet verified their email address. Therefore, no role change can be made yet.',
      },
    },
    adminEditCompanyModal: {
      editCompanyFailed: {
        title: 'Saving failed',
        description: '',
      },
    },
    adminCompanyOverview: {
      deleteCompanyNoUserFailed: {
        title: 'Delete failed',
        description:
          'The company still has employees. To delete the company permanently, you must first delete all employees of the company.',
      },
      deleteCompanyFailed: {
        title: 'Delete failed',
        description: 'A server error has occurred.',
      },
    },
    verifyUser: {
      expiredToken: {
        errorModal: {
          title: 'An error has occurred',
          infoText: 'Your request could not be processed.',
        },
      },
    },
    resetPassword: {
      errorModal: {
        title: 'An error has occurred',
        description: '',
      },
      invalidPassword: 'Please enter a password with min. 8 characters.',
      passwordsNotMatch: 'The passwords entered do not match.',
    },
    verifyEmail: {
      savePasswordsResetFailed: {
        title: 'Saving failed',
        description: 'Please check your entries.',
      },
      noEmailExists: {
        title: 'Email could not be verified',
        description: 'There is no user with this mail address',
      },
      invalidEmail: 'Please enter a valid e-mail address.',
    },
    benchmarking: {
      valueBetweenError: 'The value must be between {{min}} and {{max}}.',
      valueBelowError: 'The value must be below {{max}}.',
      sumOfKPIsError: 'The sum of the KPIs must add up to {{sum}} percent.',
      notNegativeError: 'The value must not be negative.',
      valueBetweenDaysError: 'The value must be between {{min}} and {{max}} days.',
      valueBetweenPercentError: 'The value must be between {{min}} and {{max}} percent.',
      valueBetweenEuroError: 'The value must be between {{min}} and {{max}} Euro.',
      controlling: {
        fetchControllingDataFailed: {
          title: 'Controlling data could not be loaded',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Company data could not be loaded',
          description: '',
        },
        saveDataFailed: {
          title: 'Saving failed',
          description: 'Please check your entries.',
        },
      },
      finance: {
        fetchFinanceDataFailed: {
          title: 'Finance data could not be loaded',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Company data could not be loaded',
          description: '',
        },
        saveDataFailedCheckInput: {
          title: 'Saving failed',
          description: 'Please check your entries.',
        },
        saveDataFailed: {
          title: 'Saving failed',
          description: '',
        },
      },
    },
    evaluation: {
      finance: {
        fetchBenchmarkingDataFailed: {
          title: 'Benchmarking data could not be loaded',
          description: '',
        },
        fetchBenchmarkingEvaluationDataFailed: {
          title: 'Benchmarking evaluation data could not be loaded',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Company data could not be loaded',
          description: '',
        },
      },
      controlling: {
        fetchBenchmarkingDataFailed: {
          title: 'Benchmarking data could not be loaded',
          description: '',
        },
        fetchBenchmarkingEvaluationDataFailed: {
          title: 'Benchmarking evaluation data could not be loaded',
          description: '',
        },
        fetchCompanyDataFailed: {
          title: 'Company data could not be loaded',
          description: '',
        },
      },
    },
  },
  controlling: {
    benchmarking: {
      loading: 'Loading benchmarking data. Please wait for a moment.',
      info: 'Benchmarking for Controlling of the year ',
      sliderOptions: {
        singleProcesses: 'Single Processes',
        totalProcess: 'Total Process',
      },
      totalProcess: {
        title: 'Total Process',
        tooltip: 'Overall view of controlling',
      },
      singleProcesses: {
        title: 'Single Processes',
        tooltip: `The single processes are divided into the following sub-processes`,
      },
      strategicPlanning: {
        title: 'Strategic Planning',
        tooltip: `Strategic Planning establishes the basic framework for central corporate decisions.
                  It defines objectives and measures and establishes specifications on significant issues within the corporate policy requirements.
                  Objects of Strategic Planning are markets, products, portfolios, competition, innovation, technology, core competencies and resources.`,
        planningHorizonMonths: {
          title: 'Planning horizon of strategic planning (in months)',
          tooltip: 'Period of strategic planning',
        },
        durationWeeks: {
          title: 'Duration of strategic planning (in calendar weeks)',
          tooltip: 'Please specify the duration of your strategic planning?',
        },
        votingRounds: {
          title: 'Number of voting rounds',
          tooltip: 'Please indicate how many coordination rounds your strategic planning requires?',
        },
        numberParticipants: {
          title: 'Number of participants involved',
          tooltip: 'Please specify how many participants are involved in your strategic planning?',
        },
      },
      operativePlanning: {
        title: 'Operative Planning',
        tooltip: `Operative planning creates an orientation framework for activities and short- and medium-term decisions time horizon considering strategic objectives.
                  It is about defining objectives and activities, assignment of resources as well as financial quantification of the same for both the company as a whole and its business units.
                  Matters are among other things P&L, balance sheet, cash flow, revenues, cost, results, investments, projects, quantities, capacities, employees.`,
        operativePlanningWeeks: {
          title: 'Duration of Operational planning phases (in calendar weeks)',
          tooltip:
            'Please provide the average duration of the planning schedule for operational planning and its individual phases?',
        },
        planningPreparation: {
          title: 'Planning Preparation (in weeks)',
          tooltip:
            'Please provide the average duration of the planning schedule for operational planning and its individual phases?',
        },
        targetSetting: {
          title: 'Goal Setting/ Target Setting (in weeks)',
          tooltip:
            'Please provide the average duration of the planning schedule for operational planning and its individual phases?',
        },
        decentralizedPlanning: {
          title: 'Decentralized Planning (in weeks)',
          tooltip:
            'Please provide the average duration of the planning schedule for operational planning and its individual phases?',
        },
        consolidationCompletionOfPlanning: {
          title: 'Consolidation and finalization of planning (in weeks)',
          tooltip:
            'Please provide the average duration of the planning schedule for operational planning and its individual phases?',
        },
        planShiftApproval: {
          title: 'Plan adoption/approval (in weeks)',
          tooltip:
            'Please provide the average duration of the planning schedule for operational planning and its individual phases?',
        },
        votingRounds: {
          title: 'Average number of voting rounds',
          tooltip: 'Specify the average number of coordination rounds?',
        },
        planningAlignment: {
          title: 'Alignment of Operational planning',
          tooltip: `What is the focus of your operational planning?
                    Mixed: 1st step : Top-Down planning, 2nd step: Bottom-Up planning (counter-current method and counter-current method with several loops)`,
          options: {
            topDown: 'Predominantly Top-Down',
            bottomUp: 'Predominantly Bottom-Up',
            mixed: 'Mixed',
          },
        },
        planningHorizonMonths: {
          title: 'Planning horizon of Operational planning (in months)',
          tooltip: 'Period of operational planning',
        },
        typeOperativePlanning: {
          title: 'Type of Operational planning',
          tooltip: 'What type of planning do you use?',
          options: {
            mediumTermPlanning: 'Medium-term planning (3-5 years)',
            annualPlanning: 'Annual planning/ budget',
            annualPlanningSeasonalization: 'Annual planning/budget with seasonalization',
            annualPlanningRollingForecasts:
              'Annual planning with forecasts during the year to the end of the fiscal year (year-to-end forecast)',
            annualPlanningYtef:
              'Annual planning with rolling forecasts with a constant horizon (e.g. 5 quarters) ',
            rollingPlanning:
              'Rolling planning with quarterly budget updates with a constant horizon (e.g. 5 quarters)',
          },
        },
      },
      forecast: {
        title: 'Forecast',
        tooltip: `The Forecast includes the estimation of the future economic changes, the effect on targets, plans and budgets, taking into account counteracting measures and resource adjustments.
                  The Forecast is more than a simple prognosis. It covers financial and non-financial information, as well as simulation and scenario observations.
                  The Forecast can be generated regularly (Standard Forecast), as well as unregularly (Ad-hoc Forecast) for a company as a whole or for particular topics, units or projects.`,
        typeForecast: {
          title: 'Type of forecast',
          tooltip: 'What type of forecast do you use in your company?',
          options: {
            yearEndForecast: 'Year-End-Forecast',
            rolling: 'Rolling',
            partRolling: 'Partially rolling',
          },
        },
        levelDetailQuarter: {
          title: 'Level of detail Quarterly forecast',
          tooltip: 'How detailed is the central forecast in your company?',
        },
        levelDetailMonth: {
          title: 'Level of detail Monthly forecast',
          tooltip: 'How detailed is the central forecast in your company?',
        },
        levelDetailOthers: {
          title: 'Level of detail Other',
          tooltip: 'How detailed is the central forecast in your company?',
        },
        levelDetailWeek: {
          title: 'Level of detail Weekly forecast',
          tooltip: 'How detailed is the central forecast in your company?',
        },
        accuracy: {
          title: 'Accuracy/ reliability of the forecast (in %)',
          tooltip: 'How accurate is your forecast (indication in %)?',
        },
        forecastHorizonMonths: {
          title: 'Forecast horizon (in months)',
          tooltip: 'Please indicate the time range of the forecast in months.',
        },
      },
      cpra: {
        title: 'Cost and Profit Accounting, Fin. Reporting',
        tooltip: `The content covers cost, performance and profitability results in view of products and services, or on an aggregated level product segments, as well as profitability responsible units, like for instance company divisions.
                  Part of the cost, performance and profitability  analysis is the registration, distribution, allocation, evaluation and control of cost, performance and profitability that occur in the operational consumption or creation of goods.`,
        varianceCtrAcc: {
          title: 'Result variance Controlling vs. Accounting (in %)',
          tooltip:
            'How big is the difference in the company result (e.g. EBIT) between the management view in controlling and the accounting view?',
        },
        forecastQuality: {
          title: 'Forecast quality (in %)',
          tooltip:
            'How big is the difference between the forecast during the year and the actual annual values for net sales and earnings (e.g. EBIT)?',
        },
        planQuality: {
          title: 'Plan quality (in %)',
          tooltip:
            'How big is the difference between the annual forecast and the actual annual values for net sales and earnings (e.g. EBIT)?',
        },
        deviation: {
          title: 'Deviations of Costs of service provision (in %)',
          tooltip:
            'Amount of deviation between planned costs of service provision (e.g. standard production costs of products) and actual costs',
        },
      },
      mgmtReporting: {
        title: 'Management Reporting',
        tooltip: `In general, financial and non-financial information for the dimensions actual, actual prior year, projection, target and forecast value in the form of regular standard reports, as well as ad-hoc reports should be provided. Based on identified and explained deviations and the prognosis on achievement of objectives (comments), concrete suggestions of counteracting will be compiled and coordinated by the Management.
                  This covers for instance income statement, balance sheet, revenue, costs, performance, investments, projects, amounts, capacities, employees in regards to Management units of the company.`,
        amountReportsExecutiveBoard: {
          title: 'Number of reports (Executive Board)',
          tooltip: 'Evaluation is normalized per FTE in management reporting',
        },
        amountReportsSupervisoryBoard: {
          title: 'Number of reports (Supervisory Board)',
          tooltip: 'Evaluation is normalized per FTE in management reporting',
        },
        compositionOfReports: {
          title: 'Composition of reports',
          tooltip: `The sum of the following two KPIs must be 100%.
                    A 0 can also be entered in each input field. This is equivalent to 'no specification`,
        },
        adhocReports: {
          title: 'Ad hoc reports (% share)',
          tooltip:
            'What percentage of reports correspond to "ad hoc reports" or "standardized reports"?',
        },
        standardizedReports: {
          title: 'Standardized reports (% share)',
          tooltip:
            'What percentage of reports correspond to "ad hoc reports" or "standardized reports"?',
        },
        kpisAmount: {
          title: 'Number of KPIs',
          tooltip: 'How many KPIs do you use for controlling at the different levels?',
        },
        reportingDimensions: {
          title: 'Reporting dimensions',
          tooltip:
            'How do you distribute the KPIs across the following dimensions: Financial, non-financial, external?',
          options: {
            finance: 'Financial',
            financeNot: 'Non financial',
            extern: 'External',
          },
        },
        automationPotentialDataManagement: {
          title: 'Automation potential for data management in reporting processes (in %)',
          tooltip:
            'How high do you estimate the efficiency/automation potential for the reporting processes mentioned, based on the status quo?',
        },
        automationPotentialReportGeneration: {
          title: 'Automation potential for report generation in reporting processes (in %)',
          tooltip:
            'How high do you estimate the efficiency/automation potential for the reporting processes mentioned, based on the status quo?',
        },
        automationPotentialAnalysis: {
          title: 'Automation potential for analysis in reporting processes (in %)',
          tooltip:
            'How high do you estimate the efficiency/automation potential for the reporting processes mentioned, based on the status quo?',
        },
        automationPotentialDecisionSupport: {
          title: 'Automation potential for decision support in reporting processes (in %)',
          tooltip:
            'How high do you estimate the efficiency/automation potential for the reporting processes mentioned, based on the status quo?',
        },
        integrationAiMl: {
          title: 'Integration of AI and machine learning into the reporting landscape',
          tooltip:
            'To what extent is artificial intelligence and/or machine learning an integral part of the reporting landscape?',
          options: {
            full: 'Fully integrated',
            selective: 'Seletkive integration of selected use cases',
            exclusive: 'Exclusively use case driven',
          },
        },
        integrationComment: {
          title: 'Integration of commentaries in the current reporting system',
          tooltip:
            'Describe the quality or the degree of integration of comments in the current reporting system. ',
          options: {
            manualComplete: 'Purely manual (offline) Commenting',
            manual: 'Manual integration into reporting',
            semiAutomatedFrontend: 'Partially automated (front-end)',
            semiAutomatedBackend: 'Semi-automated (back-end)',
            automatedAi: 'Automated AI created/supported',
          },
        },
        integrationSustainability: {
          title: 'Integration of Sustainability Reporting',
          tooltip: 'To what extent is sustainability reporting integrated into standard reports?',
          options: {
            notExist: 'Non-existent / barely existent ',
            separate: 'In separate reports',
            oneSided:
              'Unilaterally integrated (sustainability reporting integrated in standard reports)',
            full: 'Fully integrated (mapping of mutual relationships & effects to contents of standard reports)',
          },
        },
        formOfUsePush: {
          title: 'Form of use among users: Push (reg reports) (in %)',
          tooltip: 'Which usage flow predominates among users (standard users)?',
        },
        formOfUseStandard: {
          title: 'Form of user among users: Navigate through the tool (standard reports) (in %)',
          tooltip: 'Which usage flow predominates among users (standard users)?',
        },
        formOfUseSelfService: {
          title: 'Form of user among users: Build your own reports (self-service) (in %)',
          tooltip: 'Which usage flow predominates among users (standard users)?',
        },
        reportShares: {
          title: 'Proportion of reports in paper form (in %)',
          tooltip: 'What % of users print out reports (PAPER)?',
        },
        degreeDigitization: {
          title: 'Degree of digitalization of reporting (in %)',
          tooltip: 'How digital would you rate the reporting?',
        },
        reportingProcess: {
          title: 'Reporting process',
          tooltip: 'How is the report portfolio created for board reporting?',
          options: {
            manual: 'Manual merging of different individual reports',
            automatedDifReports:
              'Automated creation in a reporting system based on different reports/data sources',
            automatedIntegratedDatamodell:
              'Automated creation in a reporting system based on an integrated data model',
          },
        },
        reportingClosing: {
          title: 'Closing (in workding days after Ultimo)',
          tooltip:
            'When do the following reporting milestones occur (in business days after Ultimo, e.g. +3 for three days after Ultimo)?',
        },
        reportingFlash: {
          title: 'Flash report in advance (in working days after Ultimo)',
          tooltip:
            'When do the following reporting milestones occur (in business days after Ultimo, e.g. +3 for three days after Ultimo)?',
        },
        reportingMonthlyUncommented: {
          title: 'Monthly report (uncommented, preliminary) (in working days after Ultimo)',
          tooltip:
            'When do the following reporting milestones occur (in business days after Ultimo, e.g. +3 for three days after Ultimo)?',
        },
        reportingMonthlyCommented: {
          title: 'Monthly report (commented, final) (in working days after Ultimo)',
          tooltip:
            'When do the following reporting milestones occur (in business days after Ultimo, e.g. +3 for three days after Ultimo)?',
        },
      },
      pic: {
        title: 'Project and Investment Controlling',
        tooltip: `Project and Investment Controlling supports in valuation, prioritization and selection, planning, execution, controlling, as well as in the completion of (investment) projects.
                  This includes controlling if objectives were met after projects are completed`,
        investmentLimit: {
          title: 'Investment volume limit (in €)',
          tooltip: 'At what investment volume is an approval required?',
        },
        durationRequest: {
          title: 'Duration of an investment request (in working days)',
          tooltip: 'How long does it take on average to process an investment request?',
        },
        statusProjectsSuccessful: {
          title: 'Share of successfully completed projects (in %)',
          tooltip:
            'Please provide information on the status of all projects from the last fiscal year',
        },
        statusProjectsAborted: {
          title: 'Share of projects that were stopped (in the sense of interrupted) (in %)',
          tooltip:
            'Please provide information on the status of all projects from the last fiscal year',
        },
        statusProjectsStopped: {
          title:
            'Share of aborted projects (projects that were terminated without acceptance of the project objective) (in %)',
          tooltip:
            'Please provide information on the status of all projects from the last fiscal year',
        },
        deviationProjectCosts: {
          title: 'Deviation in costs',
          tooltip:
            'Please estimate the average level of deviations of investment projects with regard to the dimensions of cost, time and performance?',
        },
        deviationProjectTime: {
          title: 'Deviations in time',
          tooltip:
            'Please estimate the average level of deviations of investment projects with regard to the dimensions of cost, time and performance?',
        },
        deviationProjectPerformance: {
          title: 'Deviations in performance',
          tooltip:
            'Please estimate the average level of deviations of investment projects with regard to the dimensions of cost, time and performance?',
        },
      },
      riskManagement: {
        title: 'Risk Management',
        tooltip: `Risk management includes the identification, recording, analysis, evaluation and control of risks as well as the derivation and pursuit of suitable risk prevention measures.
                  As a framework, general risk policy principles are specified and strategic approaches are applied.`,
        typeRiskManagement: {
          title: 'Type of risk management system',
          tooltip:
            'Does a formalized/institutionalized risk management (system) exist in your company?',
          options: {
            formalized: 'Formalized',
            institutionalized: 'Institutionalized',
          },
        },
        responsibilityRiskManagement: {
          title: 'Organizational anchoring of Risk management',
          tooltip: 'Who is responsible for risk management?',
          options: {
            controlling: 'Controlling',
            staffUnit: 'Risk Management',
            corporateManagement: 'Corporate Management',
            intern: 'Internal Audit/ Accounting/ Treasury',
            decentralizedEmployees: 'Decentralized employees in the various business units',
          },
        },
        frequenceRiskManagement: {
          title: 'Reporting frequency of Risk management',
          tooltip: 'How often are risks reported to the respective positions?',
          options: {
            adhoc: 'Occasional / ad hoc',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            quarterly: 'Quarterly',
            halfYearly: 'Semiannually',
            yearly: 'Annual',
            never: 'Never',
          },
        },
        controllingRelevance: {
          title: 'Controling relevance of Risk management',
          tooltip:
            'To what extent does your risk management as an active control instrument support the achievement of corporate goals?',
          options: {
            indepControlInstance: 'Independent control authority ',
            indepMediator: 'Independent intermediary',
            realBusinessPartner: 'Real business partner',
          },
        },
        measures: {
          title: 'Risk management measures',
          tooltip: 'Is the adequacy and effectiveness of risk management measures assessed? ',
          options: {
            rotational:
              'Assessed on a regular basis by the risk owners as part of the risk identification process.',
            regularCentralRiskManagementFunc:
              'Regularly questioned by the central risk management function among those responsible for measures',
            regulateRevisionFunc: 'Regularly reviewed by the audit function',
            noActiveMonitoring: 'No active monitoring of countermeasures',
          },
        },
        checkboxGoals: {
          title: 'Risk management objectives',
          tooltip:
            'What is the objective of risk management in your company? Tick "Yes" if the respective objective is pursued, otherwise "No". If you do not wish to make any entries, do not tick either "Yes" or "No".',
          options: {
            goalFulfilledRequirement:
              'Compliance with mandatory requirements in the area of risk reporting',
            goalImprovedControllingRisks: 'Improved management of key risks',
            goalCompanyControlling: 'Risk management as an active element of corporate taxation',
          },
        },
        checkboxIntegration: {
          title: 'Integration of Risk management',
          tooltip:
            'To which corporate control processes are there interfaces from risk management? Tick "Yes" if the respective objective is pursued, otherwise "No". If you do not wish to make any entries, do not tick either "Yes" or "No".',
          options: {
            integrationStrategicPlanning: 'Strategic Planning',
            integrationOperativePlanning: 'Medium-term planning/ Operational planning',
            integrationBudgeting: 'Budgeting',
            integrationStrategicDecisionSupport: 'Strategic decision support',
            integrationOperativeDecisionSupport: 'Operational decision support',
            integrationNoInterfaces: 'No interfaces',
          },
        },
        checkboxAnalysis: {
          title: 'Analysis of risk-bearing capacity',
          tooltip: `Is the company's risk-bearing capacity analysed? Tick "Yes" if the respective objective is pursued, otherwise "No". If you do not wish to make any entries, do not tick either "Yes" or "No".`,
          options: {
            analysisNoConcept: 'No risk-bearing capacity concept in place',
            analysisEquityCoverage: `Comparison of equity coverage and quantitative overall risk composition`,
            analysisLiquidityReserve: `Comparison of liquidity reserves and overall quantitative risk composition`,
            analysisOther: 'Impact on other factors (e.g. rating, credit obligation, etc.)',
          },
        },
        checkboxSoftwareSupport: {
          title: 'Software support in Risk management',
          tooltip:
            'Is your risk management supported by a software solution? Tick "Yes" if the respective objective is pursued, otherwise "No". If you do not wish to make any entries, do not tick either "Yes" or "No".',
          options: {
            softwareRiskRecordingRating: 'For risk recording and assessment (Workflow) ',
            softwareReporting: 'For reporting',
            softwareRiskDashboards: 'For addressee-oriented preparation in risk dashboards',
            softwareSimulationModeling: 'For simulation and modeling of scenarios',
            softwareNoSoftwareSupport: 'No software support in risk management',
          },
        },
        checkboxScenarioAnalysis: {
          title: 'Scenario analyses in Risk management',
          tooltip:
            'Do you use scenario analyses as part of risk management? Tick "Yes" if the respective objective is pursued, otherwise "No". If you do not wish to make any entries, do not tick either "Yes" or "No".',
          options: {
            scenarioCurrentRiskDev: `Scenario analysis of individual risks`,
            scenarioIndividualSingleRisks: 'Scenario analysis for the complete risk portfolio',
            scenarioTopRisks: 'Scenario analysis for the evaluation of strategic decision options',
            scenarioCompleteRiskPortfolio: 'Scenario analysis for top risks',
            scenarioStrategicDecisionOptions:
              'Scenario analysis for current risk developments (Brexit, COVID, embargoes, etc.)',
          },
        },
      },
      ctrlCorporate: {
        title: 'Corporate controlling',
        tooltip:
          'Corporate controlling includes the planning, control and management as well as the information support of single corporate corporate functions',
        leadershipClaim: {
          title: 'Leadership claim of the corporate headquarters',
          tooltip: `Please indicate the predominant management claim your corporate headquarters pursues towards the group units:
            Financial management requirement: The Group manages and evaluates its subsidiaries and investments primarily on the basis of profitability indicators. The return on capital employed is of high importance for the targets defined. Operational management is the sole responsibility of the business units
            Strategic management approach: Strategic decisions are made centrally, while operational management is the responsibility of the respective subsidiaries or business units. The Group's top management only intervenes in operations if targets are not met. Transparency at group level is ensured in order to intervene if necessary.
            Operational management requirement: The operating holding company or parent company group often operates directly on the market itself and manages its subsidiaries or the business units on the basis of detailed specifications for the operating business and demands a high degree of transparency; detailed information is required at group level.
            Consistent key figure logic: A consistent key figure logic is guaranteed across all hierarchy levels, i.e. the term and key figure definitions are unambiguous & uniform throughout.
            Key performance indicators relevant to management: Each unit in the Group defines the key performance indicators relevant to management in order to reflect its own business model as accurately as possible.
            Uniform definition of kpis: A uniform definition of key performance indicators exists at Group level. The units further refine the definition according to their own requirements.
            `,
          options: {
            financialLeadership: 'Financial management requirement',
            strategicLeadership: 'Strategic management approach',
            operationalLeadership: 'Operational management requirement',
            controllingRelevanceKeyFigure: 'Consistent key figure logic',
            keyFigureLogicAllHierarchyLevels: 'Key performance indicators relevant to management',
            keyFigureCompanyLevel: 'Uniform definition of kpis',
          },
        },
      },
      researchDevelopment: {
        title: 'R&D Controlling',
        tooltip: `The Research & Development controlling includes the relevant controlling processes focusing on R&D.
                  It includes target setting, planning and managing the research and development activities. `,
        percentageShare: {
          title: 'R&D as a percentage of annual sales (in %)',
          tooltip: 'Please provide information on the share of the net R&D budget in annual sales:',
        },
        resourcesEffort: {
          title: 'Resources required for R&D projects (in €)',
          tooltip: 'What is the average resource expenditure for an R&D project?',
        },
        compositionPortfolio: {
          title: 'Composition of the product portfolio',
          tooltip: `What was the composition of your R&D product portfolio last year? (Sum should add up to 100%)`,
        },
        compositionPortfolioBasicResearch: {
          title: 'Basic research projects (pre-market development) (in %)',
          tooltip: 'Basic research projects (pre-market development)',
        },
        compositionPortfolioNewProductDev: {
          title: 'New product development projects (in %)',
          tooltip: 'New product development projects',
        },
        compositionPortfolioFurtherDev: {
          title: 'Projects for the maintenance and further development of products (in %)',
          tooltip: 'Projects for the maintenance and further development of products',
        },
      },
      production: {
        title: 'Production controlling',
        tooltip: `Production controlling includes the planning, control and management as well as the information support of single production corporate functions.`,
        checkboxKeyData: {
          title: 'Key figures in production and production-related areas',
          tooltip:
            'Which key figures do you use to assess your production or in production-related areas? Tick "Yes" if the respective key figure is used, otherwise "No". If you do not wish to provide any information, do not tick either "Yes" or "No".',
          options: {
            keyDataDeliveryPunctuality: `Delivery punctuality (number of on-time deliveries/ total number of deliveries)`,
            keyDataIdleTimeMachine: 'Duration of fallow/empty time per machine',
            keyDataPlantUtilizationRate: `Degree of utilization of machines and workstations`,
            keyDataUtilizationRate:
              'Asset depreciation ratio (sum of depreciation on assets/acquisition costs)',
            keyDataManufacturingQuality:
              'Production quality (quantity returned/total quantity produced)',
            keyDataPostProcessingRate:
              'Rework rate (defective pieces/ sum of manufactured products)',
            keyDataScrapRate: 'Exclusion rate (reject rate/total production volume)',
            keyDataDamageFrequency: 'Damage frequency (number of complaints/ sales)',
            keyDataStockBearingManufactory: 'Inventory in the warehouse and production',
            keyDataSetupTimes: 'Set-up times and frequency',
            keyDataEmployment: 'Employment (actual employment/ planned employment)',
          },
        },
      },
      sales: {
        title: 'Retail controlling',
        tooltip: `Retail controlling includes the planning, control and management as well as the information support of single retail corporate functions`,
        percentageShare: {
          title: 'Sales costs as a percentage of annual revenue (in %)',
          tooltip: `Please indicate the share of sales costs in your company's sales?`,
        },
      },
      itControlling: {
        title: 'IT-Controlling',
        tooltip: 'IT Controlling supports the IT function',
        structureIt: {
          title: 'Organizational structure of the IT department',
          tooltip: 'Please indicate how your IT organization is structured?',
          options: {
            costCenter: 'Cost-Center',
            profitCenter: 'Profit-Center',
            serviceCenter: 'Service-Center',
            others: 'Other',
          },
        },
      },
      participation: {
        title: 'Subsidiary Controlling',
        tooltip: `Subsidiary Controlling is responsible for the coordination, support and management of entitites where the parent company is holding shares in order to reach the company's goals`,
      },
      ctrlBusinessConsultingManagement: {
        title: 'Business advice and guidance',
        tooltip: `As service providers with a regulatory function, controllers ensure that the main controlling processes are applied in the company and design them within the framework of target setting, planning and control. They support the management with appropriate instruments and information relevant to decision-making, show the economic effects of alternative courses of action and create cross-departmental transparency with regard to strategy, results, finances and processes. In this way, they stand by the management as a “sparring partner” or “commercial conscience”.`,
      },
      ctrlFurtherDevelopmentControlling: {
        title: 'Further Development of Controlling',
        tooltip: `The aim of this main process is to continuously develop the controlling processes, instruments and systems used in the company. In particular, it must be checked which processes need to be adjusted, which ones can possibly be omitted entirely and which fields must be developed in principle. Finally, the effectiveness and efficiency of controlling should be increased by designing new and further developing existing controlling processes, structures, instruments and systems.`,
      },
      statusProjectOptions: {
        bad: 'Worse than planned projects',
        standard: 'According to plan',
        good: 'Better than planned',
      },
      saveButtonText: 'Cache Benchmarking',
      saveSuccessMessage: { title: 'Changes succesfully saved', description: '' },
      changeInfoDialogText:
        'A change to the FTE and total costs sets all data in the individual processes and the corresponding KPIs to 0!',
      tooltip: `Holistic view of the finance`,
    },
    evaluation: {
      loading: 'Benchmarking data is being loaded. Please be patient.',
      noDataExists: 'No data were submitted.',
      legendText: {
        fte: 'FTE (normalized to 1000)',
        costs: 'Share of costs in total sales (in %)',
      },
      pageHeader: 'Benchmarking Controlling',
      evaluationYear: 'Evaluation of Controlling of the Year benchmarking',
    },
  },
  finance: {
    benchmarking: {
      loading: 'Benchmarking data is being loaded. Please be patient.',
      info: 'Benchmarking for Finance of the Year ',
      sliderOptions: {
        singleProcesses: 'Single Processes',
        totalProcess: 'Total Process',
      },
      totalProcess: {
        title: 'Total Process',
        tooltip: 'Overall view of controlling',
      },
      singleProcesses: {
        title: 'Single Processes',
        tooltip: 'Division of finance into the respective individual processes',
      },
      debtorsProcess: {
        title: 'Accounts Receivable',
        tooltip:
          'Accounts receivable includes the entire administration of receivables from deliveries and services that have arisen from a customer. This ranges from checking the conditions and approvals, to maintaining customer-related master data, to invoicing the service evaluated by sales and, if necessary, by other departments. As part of the monitoring of payments, Accounts Receivable manages the open items and provides regular reports on payments made and overdue.',
        debtorsDurationDays: {
          title: 'Duration of an accounts receivable process (in WD)',
          tooltip: 'How long does it take to complete an accounts receivable process?',
        },
        debtorsPercentageShare: {
          title: 'Percentage of transaction activities handled by the shared service center (in %)',
          tooltip:
            'What percentage of transaction activities is handled by shared service centers?',
        },
        debtorsPercentageShareCustomer: {
          title: 'Percentage of customers connected via EDI (in %)',
          tooltip: 'What is the percentage of customers connected through EDI?',
        },
        debtorsNumberBill: {
          title: 'Number of customer invoices per year',
          tooltip: 'Evaluation is normalized per FTE in the accounts receivable department.',
        },
        debtorsPercentageShareIncorrectBills: {
          title: 'Percentage of invoices with errors (in %)',
          tooltip: 'What is the percentage of invoices with errors?',
        },
        debtorsNumberBillPosition: {
          title: 'Number of invoice items',
          tooltip: 'Evaluation is normalized per FTE in accounts receivable accounting',
        },
        debtorsTimeSpanUntilBill: {
          title:
            'Number of days from the end of the service provision to the creation of the invoice',
          tooltip:
            'What is the time span from the end of the service provision to the creation of the invoice?',
        },
        debtorsPercentageShareElecBills: {
          title: 'Proportion of invoices sent electronically (in %)',
          tooltip: 'What is the percentage of invoices sent electronically?',
        },
        debtorsPercentageShareBillsReminder: {
          title: 'Proportion of invoices sent with subsequent dunning procedure (in %)',
          tooltip: 'What is the percentage of invoices sent with subsequent dunning procedure?',
        },
        debtorsIncomingPayments: {
          title: 'Number of incoming payments per year',
          tooltip: 'Evaluation is normalized per FTE in accounts receivable accounting',
        },
        debtorsTimeSpanUntilClosingBusinessCase: {
          title: 'Number of days between receipt of payment and closing of business case',
          tooltip:
            'What is the time span between receipt of payment and closing of the business case?',
        },
        debtorsIncorrectPaymentAssignment: {
          title: 'Proportion of incorrect electronic payment allocations (in %)',
          tooltip: 'What is the percentage of incorrect electronic payment allocations?',
        },
        debtorsReceivablesTurnoverTime: {
          title: 'Amount of average receivables turnover time (in WD)',
          tooltip: 'What is the average receivables turnaround time?',
        },
        debtorsOutsourcedReceivables: {
          title: 'Proportion of outsourced receivables (in %)',
          tooltip: 'What is the percentage of receivables that are outsourced to third parties?',
        },
      },
      creditorsProcess: {
        title: 'Accounts Payable',
        tooltip:
          'Accounts Payable deals with maintaining and managing the accounting-relevant attributes of the vendor master records, checking and archiving invoices, managing open items and initiating payments. The proper examination of the invoices and the subsequent release ensure that only those liabilities are settled that are justified and lawful.',
        creditorsDurationProcessingCreditors: {
          title: 'Number of days to complete an accounts payable process',
          tooltip: 'How long does it take to complete an accounts payable process?',
        },
        creditorsIncorrectOrders: {
          title: 'Proportion of incorrect purchase orders (in %)',
          tooltip: 'What is the percentage of purchase orders with errors?',
        },
        creditorsSuppliersEdi: {
          title: 'Proportion of EDI-connected suppliers (in %)',
          tooltip: 'What is the percentage of EDI-connected suppliers?',
        },
        creditorsNumberOrders: {
          title: 'Number of purchase orders per year',
          tooltip: 'Evaluation is normalized per FTE in the accounts payable department',
        },
        creditorsNumberOrderPositions: {
          title: 'Number of invoice items ',
          tooltip: 'Evaluation is normalized per FTE in the accounts payable department',
        },
        creditorsAutomatedOrderEntry: {
          title: 'Proportion of products with automatic order entry  (in %)',
          tooltip: 'What is the percentage of products with automatic order entry?',
        },
        creditorsElectrSentOrders: {
          title: 'Proportion of orders sent electronically (in %)',
          tooltip: 'What is the percentage of orders sent electronically?',
        },
        creditorsNumberBills: {
          title: 'Number of invoices per year',
          tooltip: 'Evaluation is normalized per FTE in the accounts payable department',
        },
        creditorsDifferenceBillsOrders: {
          title: 'Proportion of invoices/orders that do not match (in %)',
          tooltip: 'What is the percentage of invoices/purchase orders that do not match?',
        },
        creditorsBillWithoutOrder: {
          title: 'Proportion of invoices for which no purchase order exists (in %)',
          tooltip: 'What is the percentage of invoices for which there is no purchase order?',
        },
        creditorsIncorrectPaymentsCreditors: {
          title: 'Proportion of incorrect payments Creditors (in %)',
          tooltip: 'What is the percentage of vendor payments that are in error?',
        },
        creditorsTimeSpanBillincomingPayment: {
          title: 'Number of working days between receipt of invoice and payment',
          tooltip: 'What is the time lag between invoice receipt and payment?',
        },
      },
      finChargesTravel: {
        title: 'Payroll, Travel Expenses Accounting',
        tooltip:
          'Liabilities to internal employees and external companies and their employees are settled as part of the processing of the payroll and travel expense report, both by account assignment, simulation and posting of the payroll report and by checking the approved travel expense report including the associated travel receipts. In addition to checking for tax, arithmetic and factual correctness, the special features of exceeding the three-month period for the deduction of meal allowances must also be taken into account. In addition to initiating the actual payment of expenses and other components to the employee, account reconciliation in cooperation with the HR department represents another field of activity in the payroll and travel expense accounting process.',
        finChargesTravelPayroll: {
          title: 'Number of payrolls',
          tooltip:
            'Evaluation is normalized per FTE in the process "Remuneration and travel expenses".',
        },
        finChargesTravelEmployees: {
          title: 'Number of employees for whom pay slips are created ',
          tooltip:
            'Evaluation is normalized per FTE in the "Remuneration and Travel Expenses" process.',
        },
        finChargesTravelIncorrectPayrolls: {
          title: 'Number of remuneration statements for which errors were detected (in %)',
          tooltip: 'In how many payrolls were errors discovered?',
        },
        finChargesTravelTravelExpenseReport: {
          title: 'Number of travel expense reports',
          tooltip:
            'Evaluation is normalized per FTE in the "Remuneration and travel expense report" process.',
        },
        finChargesTravelProcessingDays: {
          title: 'Number of working days for processing a travel expense report',
          tooltip: 'How long does it take to process a travel cost invoice in business days?',
        },
      },
      assetMgtProcess: {
        title: 'Asset Accounting, Asset Management',
        tooltip:
          'Asset accounting documents the inventory and changes in value of tangible and financial assets as well as intangible assets. To determine the current asset inventory, asset accounting supports the inventory on the key dates (e.g. at the end of the year). In order to be able to show the actual balance sheet value on the key date, asset accounting records the balance sheet, tax and imputed depreciation and carries out the associated depreciation run as well as the calculation of the interest on the capital tied up in fixed assets. In addition, asset accounting is involved in the creation of investment and depreciation plans or scenarios for budget planning.',
        assetMgtAssetsMasterData: {
          title: 'Number of assets with master data',
          tooltip: 'Evaluation is normalized per FTE in asset accounting',
        },
        assetsMgtAssetsBookedProcessed: {
          title: 'Number of asset positions processed / booked per year',
          tooltip: 'Evaluation is normalized per FTE in asset accounting',
        },
      },
      consolidationProcess: {
        title: 'General Ledger, Consolidation',
        tooltip:
          'The process includes the document-based mapping of all business transactions in the general ledger (including reconciliation with the sub-ledgers or interfaces) using a well-maintained chart of accounts. It includes the preparation of financial statements (monthly, quarterly, half-yearly or annual financial statements) and thus all closing entries, in particular with regard to valuation issues (inventories, provisions, impairment test, etc.). Closing the general ledger creates the balance sheet and income statement. In addition, individual information for the preparation of the notes and - if necessary - the management report can be taken from this.',
        consolJournalEntries: {
          title: 'Number of automated journal entries (in %)',
          tooltip: 'How many journal entries happen automatically?',
        },
        consolDaysMonthlyClosing: {
          title: 'Number of working days until the monthly closing',
          tooltip: 'How much time is required for monthly closing?',
        },
        consolDaysMonthlyClosingReleaseExternReporting: {
          title: 'Number of working days from monthly closing to release of external reporting',
          tooltip:
            'How much time is needed from the end of the month to the release of the external reporting?',
        },
        consolDaysQuarterlyReport: {
          title: 'Number of working days until quarterly closing',
          tooltip: 'How much time is needed for quarterly closing?',
        },
        consolDaysQuarterlyReportReleaseExternReporting: {
          title: 'Number of working days from quarterly closing to release of external reporting',
          tooltip:
            'How much time elapses from the quarterly closing to the release of external reporting?',
        },
        consolDaysAnnualReport: {
          title: 'Number of working days until the end of the year',
          tooltip: 'How much time is needed for the annual closing?',
        },
        consolDaysAnnualReportReleaseExternReporting: {
          title: 'Number of working days from annual closing to release of external reporting',
          tooltip:
            'How much time is needed from the annual closing to the release of the external reporting?',
        },
        consolNumberConsolidatedLegalEntities: {
          title: 'Number of legal entities to be consolidated',
          tooltip: 'How many legal entities need to be consolidated?',
        },
        consolFteConsolidation: {
          title: 'FTE in consolidation',
          tooltip: 'How many employees handle the consolidation?',
        },
        consolCostConsolidation: {
          title: 'Cost of consolidation (in EUR)',
          tooltip: 'What are the annual costs for consolidation?',
        },
        consolCompletionDataReporting: {
          title: 'Completion of data reporting by (WD)',
          tooltip:
            'By what business day (WD) must the data reporting of your local companies be completed?',
        },
        consolAnnualCompletion: {
          title: 'Annual financial statements - completion on (WD)',
          tooltip: `By which working day (WD) must the group's annual financial statements be completed?`,
        },
        consolQuarterlyCompletion: {
          title: 'Quarterly financial statements - completion on (WD)',
          tooltip: `By which working day (WD) must the group's quarterly financial statements be completed?`,
        },
        consolMonthlyCompletion: {
          title: 'Month-end closing - completion on (WD)',
          tooltip: `By which working day (WD) must the group's monthly financial statements be completed?`,
        },
        consolExtraLoadManualActivity: {
          title: 'Additional workload due to manual activities ',
          tooltip: 'Additional workload due to manual activities ',
          options: {
            veryHigh: 'Very high',
            significant: 'Significant',
            noticeable: 'Noticeable',
            none: 'None at all',
          },
        },
        consolQualityDataReporting: {
          title: 'Quality of data reporting by local reporting units ',
          tooltip: 'Quality of data reporting by local reporting units ',
          options: {
            veryPoor: 'Very poor',
            unsatisfactory: 'Unsatisfactory',
            ok: 'OK',
            veryGood: 'Very good',
          },
        },
        consolQualityVotingProcess: {
          title: 'Quality of the IC reconciliation process',
          tooltip: 'Quality of the IC reconciliation process',
          options: {
            veryPoor: 'Very poor',
            unsatisfactory: 'Unsatisfactory',
            ok: 'OK',
            veryGood: 'Very good',
          },
        },
        consolIntegrationLegalManagement: {
          title: 'Integration of their legal and management views ',
          tooltip: 'Integration of their legal and management views ',
          options: {
            none: 'No integration',
            little: 'Little integration',
            dataModel: 'Integrated data model',
            fully: 'Fully integrated',
          },
        },
        consolGenerationConsolidatedNumbers: {
          title: 'Generation of consolidated figures',
          tooltip: 'Generation of consolidated figures',
          options: {
            one: 'In one place',
            two: 'In two place',
            more: 'In more than two places',
            noAnswer: 'I cannot answer',
          },
        },
        consolChallengeGroupAccounting: {
          title: 'Biggest challenge in Group Accounting',
          tooltip: 'Biggest challenge in Group Accounting',
          options: {
            IFRS: 'Implement IFRS standards',
            moreInfo: 'Provide more information',
            euTax: 'EU taxonomy',
            closingDates: 'Advanced closing dates',
            processAutomation: 'Process automation',
            newSkills: 'Build new skills',
          },
        },
      },
      finTaxProcess: {
        title: 'Tax',
        tooltip:
          'The main process includes the approach and valuation of the individual assets from a tax law perspective and, as the end result, the preparation of the tax balance sheet. The focus is on determining the overall tax success of the company in compliance with necessary/specific guidelines and standards/laws, such as e.g. the principle of authoritativeness. The tax accounting policy, deferred taxes, group tax rate or the international income accrual and tax risk management represent special aspects of the tax process.',
        finTaxReportingUnit: {
          title:
            'Number of reporting units in the group for which the tax department is responsible ',
          tooltip: 'Evaluation is normalized per FTE in Tax',
        },
        finTaxTaxDeclaration: {
          title: 'Number of working days for the preparation of a tax return',
          tooltip: 'How many working days are needed to prepare a tax return?',
        },
        finTaxTaxDeclarationBalance: {
          title: 'Number of working days for the preparation of a tax return',
          tooltip: 'How many working days are required to prepare an e-balance sheet?',
        },
      },
      finTreasuryProcess: {
        title: 'Treasury',
        tooltip: `The Treasury's area of responsibility includes operational processes such as the scheduling of all external and internal payments or offsetting between group companies and the management of cash pools. Depending on the risk profile, Treasury determines the hedging strategy and carries out the hedging transactions. When treasury carries out short- and medium-term financial planning and corporate financing, there are close links to corporate planning in terms of content. In addition, treasury often includes the administration of guarantees and working capital management.`,
        treasuryAvgExternPaymentTransactions: {
          title: 'Average number of external payment transactions per month',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryAvgExternManualPayments: {
          title: 'Average number of external manual payments per month',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryAvgInternPayments: {
          title: 'Average number of internal payments per month',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryEmployeesPaymentTransactions: {
          title: 'Number of employees for processing payment transactions ',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryFinanceTransactionsConcluded: {
          title: 'Number of new treasury financial transactions concluded per month',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryInventoryTreasuryFinanceTransactions: {
          title: 'Balance of treasury financial transactions (average)',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryAutomatedProcessingTreasuryTransactions: {
          title: 'Automated processing of treasury financial transactions (in %)',
          tooltip:
            'How many of the treasury financial transactions in the portfolio are processed fully automatically (payment, accounting (valuation, accrual, posting), EMIR reporting)? ',
        },
        treasuryEmployeesTreasuryFinanceTransactionsCompleted: {
          title: 'Number of employees for concluding treasury financial transactions',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryEmployeesProcessingTreasuryFinanceTransactions: {
          title: 'Number of employees for processing treasury financial transactions',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryEfficiency: {
          title: 'Efficiency of bank statement processing (in %)',
          tooltip:
            'How high is the rate of automatically cleared open items as a result of processing electronic account statements?',
        },
        treasuryLiquidityPlanningHorizonMonths: {
          title: 'Planning horizon Liquidity planning',
          tooltip: 'Over what period of time is the liquidity position planned company-wide?',
        },
        treasuryLiquidityPlanningFrequenceMonths: {
          title: 'Planning frequency Liquidity planning',
          tooltip: 'At what intervals is liquidity planning updated throughout the company?',
        },
        treasuryLiquidityPlanningDurationDays: {
          title: 'Planning duration Liquidity planning',
          tooltip: 'How many days are needed to create the company-wide liquidity planning?',
        },
        treasuryNumberEmployeesLiquidityPlanning: {
          title: 'Number of employees involved in Liquidity planning',
          tooltip: 'Evaluation is normalized per FTE in Treasury',
        },
        treasuryAccuracyLiquidityPlanning: {
          title: 'Accuracy of Liquidity planning (in %)',
          tooltip:
            'How high is the average deviation (planned/actual) in liquidity planning (actual vs. planned -3months)?',
        },
      },
      finFurtherDevelopmentFinance: {
        title: 'Further Development of Finance',
        tooltip:
          'The aim of this main process is to continuously develop the finance processes, instruments and systems established in the company, taking into account corporate policy and statutory regulatory requirements. Finally, the effectiveness and efficiency of finance should be increased by designing new and further developing existing finance processes, structures, instruments and systems. To ensure an optimally aligned finance organization, a high level of error-free process automation and standardization must be ensured on an ongoing basis.',
      },
      saveButtonText: 'Cache Benchmarking',
      saveSuccessMessage: { title: 'Changes saved successfully', description: '' },
      changeInfoDialogText:
        'A change to the FTE and the total costs sets all data in the individual processes and the coresponding KPIs to 0!',
    },
    evaluation: {
      loading: 'Benchmarking data is being loaded. Please be patient.',
      noDataExists: 'No data was submitted.',
      legendText: {
        fte: 'FTE (normalized to 1000)',
        costs: 'Share of costs in total revenue (in %)',
      },
      pageHeader: 'Benchmarking Finance',
      evaluationYear: 'Evaluation of the Finance Benchmarking of the Year',
    },
  },
};

export default translations;
