/*
 * Renders icons depending if a user is verified or not
 */
import React, { useState, useEffect } from 'react';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';

const IsOptionChecked = ({ checkedOption }) => {
  const [isOptionChecked, setIsOptionChecked] = useState(checkedOption);

  useEffect(() => {
    setIsOptionChecked(checkedOption);
  }, [checkedOption]);

  return isOptionChecked === false || isOptionChecked === null ? (
    <CloseIcon color="horvath.red" fontSize="1.25rem" />
  ) : (
    <CheckIcon color="green.400" fontSize="1.25rem" />
  );
};

export default IsOptionChecked;
