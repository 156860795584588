import * as React from 'react';
import {
  Box,
  Center,
  Container,
  HStack,
  Heading,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from '../inputs/Button';

/**
 * Card to display the different subscription types with corresponding prices and information.
 * @param {string} name The name of the subscription type
 * @param {string} price The price of the subscription type
 * @param {string[]} network The network benefits of the subscription type
 * @param {string[]} knowledge The knowledge benefits of the subscription type
 * @param {string[]} benchmarking The benchmarking benefits of the subscription type
 * @param {Function} buttonAction The action to perform when the button is clicked
 * @param {boolean} isActive Whether the card is the currently selected subscription type
 */
const PricingCard = (props) => {
  const { name, price, network, knowledge, benchmarking, buttonAction, isActive } = props;
  const { t } = useTranslation();
  return (
    <Box as="section" position="relative">
      <Container h="100%">
        <Box
          borderWidth="3px"
          boxShadow="md"
          px="2"
          py="2"
          width="full"
          h="100%"
          borderColor={isActive ? 'horvath.digital-turquoise' : 'white'}
        >
          <Stack textAlign="center" justify="space-between" h="100%">
            <Box>
              <Stack align="center">
                <Heading size="lg" fontWeight="semibold">
                  {name}
                </Heading>
                <Heading size="md">{price}</Heading>
              </Stack>
              <Heading size="sm" align="start" pt="2">
                {t('registration.subscriptionCards.networkSubTitle')}
              </Heading>
              <List spacing="1">
                {network.map((net) => (
                  <ListItem key={net} color="fg.muted">
                    <HStack spacing="4">
                      {net === '' ? (
                        <Icon as={FiCheck} color="transparent" boxSize="6" />
                      ) : (
                        <Icon as={FiCheck} color="horvath.blue-light" boxSize="6" />
                      )}
                      <Text align="start">{net}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Heading size="sm" align="start" pt="2">
                {t('registration.subscriptionCards.knowledgeSubTitle')}
              </Heading>
              <List spacing="1">
                {knowledge.map((kno) => (
                  <ListItem key={kno} color="fg.muted">
                    <HStack spacing="4">
                      {kno === '' ? (
                        <Icon as={FiCheck} color="transparent" boxSize="6" />
                      ) : (
                        <Icon as={FiCheck} color="horvath.blue-light" boxSize="6" />
                      )}
                      <Text align="start">{kno}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Heading size="sm" align="start" pt="2">
                {t('registration.subscriptionCards.benchmarkingSubTitle')}
              </Heading>
              <List spacing="1">
                {benchmarking.map((ben) => (
                  <ListItem key={ben} color="fg.muted">
                    <HStack spacing="4">
                      {ben === '' ? (
                        <Icon as={FiCheck} color="transparent" boxSize="6" />
                      ) : (
                        <Icon as={FiCheck} color="horvath.blue-light" boxSize="6" />
                      )}
                      <Text align="start">{ben}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Center>
              <Box>
                <Button
                  text={t('registration.subscriptionCards.chooseSubscription')}
                  action={buttonAction}
                  testId="Registrierung Button"
                />
              </Box>
            </Center>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default PricingCard;
