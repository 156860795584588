/*
 * Renders modal component to change the role and company from a user
 */
import {
  Button,
  IconButton,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  PopoverHeader,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { editCompany, fetchAllCompanies, fetchCompanyById } from '../slices/adminCompanySlice';
import { fetchIndustryLevels, selectAllIndustryLevels } from '../slices/industryLevelsSlice';
import Dialog from './Dialog';
import FormControl from './inputs/FormControl';
import FormProperty from './inputs/FormProperty';
import getCountrySelectOptions from '../translations/countries';

const AdminEditCompanyModal = ({ company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState('');
  const [shortName, setShortName] = useState('');
  const [industryLevel1, setIndustryLevel1] = useState('');
  const [industryLevel2, setIndustryLevel2] = useState('');
  const [industryLevel3, setIndustryLevel3] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [subscription, setSubscription] = useState('');
  const [showAdminWarning, setShowAdminWarning] = useState(false);

  const toast = useToast();

  // States for opening the user dialog to create a new company
  const { isOpen, onOpen, onClose } = useDisclosure();

  const industryLevels = useSelector(selectAllIndustryLevels);

  /**
   * TODO: different solution with parameter "company" (local)
   * Currently: fetching company by id
   */
  useEffect(() => {
    dispatch(fetchCompanyById(company.id)).then((res) => {
      setCompanyName(res.payload.name);
      setShortName(res.payload.shortName);
      setIndustryLevel1(res.payload.industryLevel1);
      setIndustryLevel2(res.payload.industryLevel2);
      setIndustryLevel3(res.payload.industryLevel3);
      setCountryCode(res.payload.countryCode);
      setSubscription(res.payload.subscription);
    });
  }, [company]);

  const subscriptionOptions = [
    { name: t('global.subscriptionOptions.free'), value: 'FREE' },
    { name: t('global.subscriptionOptions.smart'), value: 'SMART' },
    { name: t('global.subscriptionOptions.premium'), value: 'PREMIUM' },
  ];

  const countryCodeOptions = getCountrySelectOptions(t);

  /**
   * Callback function that dispatches an async thunk to retrieve industry level data
   */
  const getIndustryLevels = () => {
    // Only fetch industry levels if the status of the reducer is 'idle'

    dispatch(fetchIndustryLevels());
  };

  React.useEffect(() => getIndustryLevels(), []);

  /**
   * Gets the names of the industry level 1
   * @returns Array of the names of the industry level 1
   */
  const getIndustryLevel1NamesAndValues = () => {
    const namesAndValues = [];
    industryLevels.forEach((level) => {
      const element = {};
      element.name = level.level1[0].name;
      element.value = level.level1[0].value;
      namesAndValues.push(element);
    });
    return namesAndValues;
  };

  /**
   * Gets the names of the industry level 2 according to the selected industry level 1
   * @returns Array of the names of the industry level 2
   */
  const getIndustryLevel2NamesAndValues = () => {
    const namesAndValues = [];
    industryLevels
      .filter((level) => level.level1[0].value === industryLevel1)
      .forEach((level) => {
        level.level2.forEach((level2Item) => {
          const element = {};
          element.name = level2Item.name;
          element.value = level2Item.value;
          namesAndValues.push(element);
        });
      });
    return namesAndValues;
  };

  /**
   * Gets the names of the industry level 3 according to the selected industry level 1
   * @returns Array of the names of the industry level 3
   */
  const getIndustryLevel3NamesAndValues = () => {
    const namesAndValues = [];
    industryLevels
      .filter((level) => level.level1[0].value === industryLevel1)
      .forEach((level) => {
        level.level3.forEach((level3Item) => {
          const element = {};
          element.name = level3Item.name;
          element.value = level3Item.value;
          namesAndValues.push(element);
        });
      });
    return namesAndValues;
  };

  /**
   * Checks the necessary company inputs and returns true if everything check out
   * @returns true if the inputs are valid, false otherwise
   */
  const checkCompanyInputs = () => {
    if (
      companyName !== '' &&
      shortName !== '' &&
      industryLevel1 !== '-' &&
      industryLevel2 !== '-' &&
      industryLevel3 !== '-' &&
      countryCode !== '-' &&
      subscription !== '-'
    ) {
      return true;
    }
    return false;
  };

  /**
   * Updates the indsutry level 1 state and sets the states of
   * industry level 2 and insdustry level 3 to "-"
   * @param {*} value The new value of the state
   */
  const updateIndustryLevel1 = (value) => {
    setIndustryLevel1(value);
    setIndustryLevel2('-');
    setIndustryLevel3('-');
  };

  /**
   * Updates the indsutry level 2 state and sets the state of
   * insdustry level 3 to "-"
   * @param {*} value The new value of the state
   */
  const updateIndustryLevel2 = (value) => {
    setIndustryLevel2(value);
    setIndustryLevel3('-');
  };

  const closePopover = () => {
    onClose();
  };

  /**
   * Saves the changed company data
   */
  const saveChangeCompanyData = () => {
    const companyData = {
      id: company.id,
      name: companyName,
      shortName,
      industryLevel1,
      industryLevel2,
      industryLevel3,
      countryCode,
      subscription,
    };
    dispatch(editCompany(companyData))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchAllCompanies()).then(() => {
          closePopover();
        });
      })
      .then(() => {
        toast({
          title: t('adminEditCompanyModal.successSaveEditCompanyData.title'),
          description: t('adminEditCompanyModal.successSaveEditCompanyData.description'),
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: t('errors.adminEditCompanyModal.editCompanyFailed.title'),
          description: t('errors.adminEditCompanyModal.editCompanyFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Checks if subscription has changed in the modal
   */
  const checkSubscriptionAndSave = () => {
    if (subscription !== company.subscription) {
      setShowAdminWarning(true);
    } else {
      saveChangeCompanyData();
    }
  };

  /**
   * Displays the Popover Content when changing subscription in drowdown
   * @returns Displays Popover to the user
   */
  const userPopoverContent = () => {
    return (
      <>
        <PopoverHeader fontWeight="semibold">
          {t('adminEditCompanyModal.popover.header')}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Text color="horvath.red">{t('adminEditCompanyModal.popover.body')}</Text>
        </PopoverBody>
        <PopoverFooter>
          <Button
            colorScheme="blue"
            onClick={() => {
              saveChangeCompanyData();
              setShowAdminWarning(false);
            }}
          >
            {t('global.okButton')}
          </Button>
        </PopoverFooter>
      </>
    );
  };

  return (
    <>
      <IconButton
        color="horvath.black"
        icon={<FiEdit fontSize="1.25rem" />}
        variant="ghost"
        aria-label="Delete member"
        onClick={onOpen}
      />
      <Dialog
        title={t('adminEditCompanyModal.dialog.title')}
        secondaryActionName={t('adminEditCompanyModal.dialog.saveButton')}
        secondaryAction={checkSubscriptionAndSave}
        secondaryActionDisabled={!checkCompanyInputs()}
        isOpen={isOpen}
        onClose={closePopover}
        popoverSaveButtonOnClose={() => {
          setShowAdminWarning(false);
        }}
        popoverSaveButtonIsOpen={showAdminWarning}
        popoverSaveButtonCloseOnBlur
        popoverSaveButtonReturnFocusOnClose={false}
        popoverSaveButtonContent={userPopoverContent()}
      >
        <FormControl>
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelCompleteName')}
            formType="input"
            invalid={companyName === ''}
            value={companyName}
            onValueChange={setCompanyName}
            colorEvenItems
            testName="Vollständiger Name"
          />
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelShortName')}
            formType="input"
            invalid={shortName === ''}
            value={shortName}
            onValueChange={setShortName}
            colorEvenItems
            testName="Kurzname"
          />
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelCountry')}
            formType="selectNameValue"
            value={countryCode}
            onValueChange={setCountryCode}
            invalid={countryCode === '-'}
            colorEvenItems
            testName="Land"
            options={countryCodeOptions}
          />
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelIndustryLvl1')}
            formType="selectNameValue"
            value={industryLevel1}
            onValueChange={updateIndustryLevel1}
            invalid={industryLevel1 === '-'}
            colorEvenItems
            testName="Industrie Level 1"
            options={getIndustryLevel1NamesAndValues()}
          />
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelIndustryLvl2')}
            formType="selectNameValue"
            invalid={industryLevel2 === '-'}
            value={industryLevel2}
            onValueChange={updateIndustryLevel2}
            colorEvenItems
            testName="Industrie Level 2"
            options={getIndustryLevel2NamesAndValues()}
          />
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelIndustryLvl3')}
            formType="selectNameValue"
            invalid={industryLevel3 === '-'}
            value={industryLevel3}
            onValueChange={setIndustryLevel3}
            colorEvenItems
            testName="Industrie Level 3"
            options={getIndustryLevel3NamesAndValues()}
          />
          <FormProperty
            label={t('adminEditCompanyModal.dialog.labelSubscription')}
            formType="selectNameValue"
            invalid={subscription === '-'}
            value={subscription}
            onValueChange={setSubscription}
            colorEvenItems
            testName="Subscription"
            options={subscriptionOptions}
          />
        </FormControl>
      </Dialog>
    </>
  );
};

export default AdminEditCompanyModal;
