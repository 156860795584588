import * as React from 'react';
import { Circle, Icon } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';

const StepCircle = (props) => {
  const { isCompleted, isActive } = props;
  return (
    <Circle
      size="8"
      bg={isCompleted ? 'horvath.blue-dark' : 'inherit'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'horvath.digital-turquoise' : 'inherit'}
      {...props}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color="white" boxSize="5" />
      ) : (
        <Circle bg={isActive ? 'horvath.digital-turquoise' : 'border'} size="3" />
      )}
    </Circle>
  );
};

export default StepCircle;
