/*
 * Slice which provides reducer and states of the user state
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: {
    id: null,
    salutation: '',
    academicTitle: '',
    firstName: '',
    lastName: '',
    position: '',
    department: '',
    email: '',
    role: '',
  },
  status: 'idle',
  error: null,
};

/**
 * Fetches the user
 */
export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await api.get('/api/user/me', {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Edits the user
 */
export const editUser = createAsyncThunk('user/editUser', async (editedUser) => {
  const response = await api.put(`/api/user/${editedUser.id}`, editedUser, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Changes the password of the user
 */
export const changePasswordUser = createAsyncThunk('user/changePasswordUser', async (passwords) => {
  await api.put('/api/user/change-password', passwords, {
    withCredentials: true,
  });
});

/**
 * Resets the password of the user
 */
export const resetPasswordUser = createAsyncThunk(
  'user/resetPasswordUser',
  async (resetPasswordData) => {
    await api.post('/auth/user/reset-password', resetPasswordData, {
      withCredentials: true,
    });
  },
);

/**
 * Changes the subscription of the user
 */
export const changeSubscriptionUser = createAsyncThunk(
  'user/changeSubscriptionUser',
  async (subscriptionData) => {
    await api.post('/api/user/change-subscription', subscriptionData, {
      withCredentials: true,
    });
  },
);

/**
 * The slice of the user state
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Insert fetched user into data
        state.data = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Directly insert edited user object to data
        state.data = action.payload;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(changePasswordUser.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(changePasswordUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(resetPasswordUser.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(resetPasswordUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select a user
 */
export const selectUser = (state) => state.user.data;

/**
 * Select the id of a user
 */
export const selectUserId = (state) => state.user.data.id;

/**
 * Checks if the user is the admin
 */
export const isUserAdmin = (state) => state.user.data.role === 'ADMIN';

/**
 * Select the name of a user
 */
export const selectUserName = (state) => `${state.user.data.firstName} ${state.user.data.lastName}`;

/**
 * Select the status of the user state
 */
export const selectUserStatus = (state) => state.user.status;

/**
 * Select the error messages of the user state
 */
export const selectUserError = (state) => state.user.error;

export default userSlice.reducer;
