import { Box, Center, Grid, Stack, Text } from '@chakra-ui/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PricingCard from './cards/PricingCard';

/**
 * Component that displays the different subscription types with corresponding information.
 * @param {string} subscriptionType The currently selected subscription type
 * @param {Function} setSubscriptionType Function to set the subscription type
 * @param {React.Component} submitButton The button to submit the subscription change
 */
const UserSubscriptionSelection = ({ subscriptionType, setSubscriptionType, submitButton }) => {
  const { t } = useTranslation();
  return (
    <Stack w="100%" mt={8}>
      <Center>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          spacing={6}
          align="stretch"
        >
          <PricingCard
            name="CFO-Panel Free"
            price={t('registration.subscriptionCards.free')}
            network={[
              t('registration.subscriptionCards.cfoMeetings'),
              t('registration.subscriptionCards.invitation'),
              '',
            ]}
            knowledge={[
              t('registration.subscriptionCards.digitalMaturity'),
              `${t('registration.subscriptionCards.cfoMeetingData')}*`,
              '',
            ]}
            benchmarking={[t('registration.subscriptionCards.wholePanel'), '', '']}
            buttonAction={() => setSubscriptionType('FREE')}
            isActive={subscriptionType === 'FREE'}
          />
          <PricingCard
            name="CFO-Panel Smart"
            price="2.950€ p.a."
            network={[
              t('registration.subscriptionCards.cfoMeetings'),
              t('registration.subscriptionCards.invitation'),
              '',
            ]}
            knowledge={[
              t('registration.subscriptionCards.digitalMaturity'),
              t('registration.subscriptionCards.cfoMeetingData'),
              t('registration.subscriptionCards.cfoStudyResults'),
            ]}
            benchmarking={[
              t('registration.subscriptionCards.wholePanel'),
              t('registration.subscriptionCards.peerGroup'),
              '',
            ]}
            buttonAction={() =>
              subscriptionType === 'SMART'
                ? setSubscriptionType('FREE')
                : setSubscriptionType('SMART')
            }
            isActive={subscriptionType === 'SMART'}
          />
          <PricingCard
            name="CFO-Panel Premium"
            price="3.950€ p.a."
            network={[
              t('registration.subscriptionCards.cfoMeetings'),
              t('registration.subscriptionCards.invitation'),
              t('registration.subscriptionCards.aiDataConference'),
            ]}
            knowledge={[
              t('registration.subscriptionCards.digitalMaturity'),
              t('registration.subscriptionCards.cfoMeetingData'),
              t('registration.subscriptionCards.cfoStudyResults'),
            ]}
            benchmarking={[
              t('registration.subscriptionCards.wholePanel'),
              t('registration.subscriptionCards.peerGroup'),
              t('registration.subscriptionCards.workshop'),
            ]}
            buttonAction={() =>
              subscriptionType === 'PREMIUM'
                ? setSubscriptionType('FREE')
                : setSubscriptionType('PREMIUM')
            }
            isActive={subscriptionType === 'PREMIUM'}
          />
        </Grid>
      </Center>
      <Center>
        <Box w="70%">
          <Text align="start" color="horvath.grey-mid" sx={{ height: '25px' }}>
            {t('registration.subscriptionCards.participationText')}
          </Text>
          {submitButton && (
            <Center mt={2} mb={5}>
              {submitButton}
            </Center>
          )}

          <Text align="center" color="horvath.grey-mid" sx={{ height: '25px' }}>
            {subscriptionType === 'FREE' ? t('registration.subscriptionCards.orderInfoFree') : null}
            {subscriptionType === 'SMART' ? t('registration.subscriptionCards.orderInfoPay') : null}
            {subscriptionType === 'PREMIUM'
              ? t('registration.subscriptionCards.orderInfoPay')
              : null}
          </Text>
        </Box>
      </Center>
    </Stack>
  );
};

export default UserSubscriptionSelection;
