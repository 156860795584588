import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from './Dialog';
import UserSubscriptionSelection from './UserSubscriptionSelection';

/**
 * Shows a dialog to change ones subscription.
 * @param {boolean} open Whether the dialog is open
 * @param {Function} onClose Function to close the dialog
 * @param {Function} onSubmit Function to submit the subscription change
 * @param {string} subscriptionType The currently selected subscription type
 * @param {Function} setSubscriptionType Function to set the subscription type
 * @param {boolean} isLoading Whether the change of the subscription is started but not finished
 */
const ChangeSubscriptionDialog = ({
  open,
  onClose,
  onSubmit,
  subscriptionType,
  setSubscriptionType,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('user.changeSubscription.dialog.title')}
      secondaryActionName={t('user.changeSubscription.dialog.submit')}
      secondaryAction={() => onSubmit(subscriptionType)}
      isOpen={open}
      onClose={onClose}
      size="xxl"
      isPromise
      isPromiseResolved={!isLoading}
      secondaryActionDisabled={isLoading}
    >
      <UserSubscriptionSelection
        subscriptionType={subscriptionType}
        setSubscriptionType={setSubscriptionType}
      />
    </Dialog>
  );
};

export default ChangeSubscriptionDialog;
